@import './tools/custom';
@import './settings/variables';
@import './tools/mixins';

/* ==========================================================================
   Tools
    - Globally-available tools, public utilities
   ========================================================================== */
body {
  background: $white-primary;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  overflow: auto;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  padding-left: 0;

  li {
    list-style: none;
  }
}

.object-fit-contain {
  object-fit: contain;
}

.img-fit-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.w-fit {
  width: fit-content !important;
}

.h-1 {
  height: 1px;
}

/* Heading and sub heading styles here */
h3 {
  &.heading {
    @include font(24px, 30px, 600, $text-primary);
    letter-spacing: normal;
  }

  &.heading-white {
    @include font(24px, 36px, 400, $white-primary);
    letter-spacing: 0.3px;
  }
}

h4 {
  &.title {
    @include font(20px, normal, 600, $primary-color);
  }

  &.small-heading {
    @include font(16px, 22px, 700, $black-primary);
  }

  &.black-desc {
    @include font(13px, 18px, 700, $black-primary);

    &.light {
      font-weight: 400 !important;
    }
  }

  &.grey-darken-8 {
    @include font(14px, 20px, 700, $grey-darken-8);
  }

  &.heading {
    @include font(18px, 20px, 700, $text-primary);
    letter-spacing: 0.2px;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
  }

  &.heading-bold {
    @include font(18px, 20px, 600, $text-primary);
    letter-spacing: 0.2px;
    font-family: 'Comfortaa', sans-serif;
  }

  &.heading-empty {
    @include font(18px, 20px, 600, $text-primary);
    letter-spacing: 0.2px;
    font-family: 'Open Sans', sans-serif;
  }
}

h5 {
  &.heading {
    @include font(16px, 20px, 600, $text-primary);
    letter-spacing: 0.2px;
    font-family: 'Open Sans', sans-serif;
  }
}

h6 {
  &.heading {
    @include font(14px, 20px, 500, $text-primary);
    letter-spacing: 0.2px;
    font-family: 'Open Sans', sans-serif;
  }
}

p {
  &.subtitle {
    @include font(14px, 20px, normal, $text-grey-hint);
  }

  &.summary {
    @include font(13px, 16px, normal, $text-grey-hint);
    letter-spacing: 0.2px;
  }

  &.subtitle-1 {
    @include font(14px, 20px, 600, $text-grey-content);

    &.subtitle-blue {
      color: $secondary-color !important;
    }
  }

  &.reading-font {
    @include font(14px, 20px, 400, $text-grey-content);
  }

  &.reading-dark {
    @include font(14px, 25px, 400, $text-grey-secondary);
  }

  &.subtitle-normal {
    @include font(14px, 20px, 500, $text-grey-secondary);

    &.lh-179 {
      line-height: 1.79;
    }
  }

  &.subtitle-2 {
    @include font(14px, 21px, 600, $text-grey-secondary);
  }

  &.title {
    @include font(16px, 20px, 600, $text-grey-secondary);
  }

  &.title-primary {
    @include font(16px, 20px, 400, $text-primary);
  }

  &.title-secondary {
    @include font(14px, 18px, 600, $text-grey-secondary);
  }

  &.title-content {
    @include font(18px, 24px, 600, $text-grey-secondary);
  }

  &.summary {
    @include font(13px, 16px, normal, $text-grey-hint);
    letter-spacing: 0.2px;

    &.lh-138 {
      line-height: 1.38;
    }
  }

  &.subtitle-4 {
    @include font(11px, normal, normal, $grey-darken-6);
  }

  &.subtitle-3 {
    @include font(12px, 21px, normal, $text-grey-content);

    &.active {
      color: $red;
    }
  }

  &.subtitle-white {
    @include font(14px, 20px, 300, $white-color-76);

    &.delete {
      text-decoration: line-through;
    }
  }

  &.subtitle-hint {
    @include font(10px, normal, normal, $text-grey-content);
  }

  &.subtitle-grey {
    @include font(13px, 18px, 500, $text-tertiary);

    &-desc {
      @include font(12px, 18px, 500, $grey-lighten-7);
    }

    &-desc-600 {
      @include font(12px, 18px, 500, $grey-lighten-7);
    }
  }

  &.reading-primary {
    @include font(14px, 22px, 400, $text-grey-secondary);
  }

  &.hint-10 {
    @include font(10px, normal, 600, $text-grey-content);
  }
}

.nodata-title {
  @include font(20px, 28px, 700, $text-primary);
}

.nodata-summary {
  @include font(13px, 20px, 500, $text-hint);
}

.page-title {
  @include font(18px, 24px, 700, $text-primary);
}

.tab-inside-title {
  @include font(16px, 20px, 700, $text-primary);
}

.tab-inside-subtitle {
  @include font(14px, 20px, 700, $text-primary);
}

.tab-inside-sub-section-title {
  @include font(13px, 20px, 700, $text-primary);
}

.p-heading {
  @include font(16px, 22px, 700, $text-primary);
}

.p-title {
  @include font(13px, 18px, 600, $text-primary);
}

.p-mainTitle {
  @include font(14px, 20px, 600, $text-primary);
}

.p-info {
  @include font(14px, 20px, 400, $text-hint);
}

// font size styles starts Here
.f {
  &-10 {
    font-size: 10px !important;
  }

  &-11 {
    font-size: 11px !important;
  }

  &-12 {
    font-size: 12px !important;
  }

  &-13 {
    font-size: 13px !important;
  }

  &-14 {
    font-size: 14px !important;
  }

  &-15 {
    font-size: 15px !important;
  }

  &-16 {
    font-size: 16px !important;
  }

  &-18 {
    font-size: 18px !important;
  }

  &-20 {
    font-size: 20px !important;
  }

  &-28 {
    font-size: 28px !important;
  }
}

/* Letter Spacing Styles Starts Here */
.ls {
  &-none {
    letter-spacing: 0 !important;
  }

  &-02 {
    letter-spacing: 0.2px !important;
  }
}

// gap styles here
// column gap
.c-g {
  &-16 {
    column-gap: 16px;
  }
}

/* text color styles start here */
.txt {
  &-white {
    color: $white-primary;
  }

  &-primary {
    color: $primary-color!important;
  }

  &-grey-hint {
    color: $text-hint !important;
  }

  &-grey-teritary {
    color: $text-tertiary;
  }

  &-grey-darken {
    color: $text-secondary !important;
  }

  &-text-light {
    color: var(--text-light) !important;
  }

  &-dark-black {
    color: $black-primary !important;
  }

  &-black {
    color: $text-primary !important;
  }

  &-black-primary {
    color: $text-primary !important;
  }

  &-grey-secondary {
    color: $text-grey-content !important;
  }

  &-grey-content {
    color: $text-grey-content !important;
  }

  &-red {
    color: $red !important;
  }

  &-warn {
    color: $text-warn !important;
  }

  &-green {
    color: $green !important;
  }

  &-grey-secondary1 {
    color: $text-grey-secondary-1 !important;
  }

  &-grey-secondary7 {
    color: $grey-lighten-7 !important;
  }

  &-grey-secondary9 {
    color: $grey-lighten-9 !important;
  }

  &-grey-primary9 {
    color: $text-primary-2;
  }

  &-grey-primary3 {
    color: $text-primary-3;
  }

  &-grey-primary-4 {
    color: $text-primary-4;
  }

  &-dark-green {
    color: $dark-green;
  }

  &-grey-lighten12 {
    color: $grey-lighten-12;
  }

  &-grey-lighten13 {
    color: $grey-lighten-13;
  }

  &-blue-lighten6 {
    color: $background-primary;
  }
}

/* Cursor styles here */
.cursor {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

/* border-style styles here */
.border-right {
  border-right: 1px solid $grey-lighten-2-50;
}

.border-bottom {
  &-grey {
    border-bottom: 1px solid $border-grey;
  }

  &-grey-1 {
    border-bottom: 1px solid $stock-hint;
  }

  &-grey-2 {
    border-bottom: 1px solid $stock-hint;
  }
}

.border-full-grey {
  border: 1px solid $grey-lighten-2-50;
}

.border-grey-light {
  border: 1px solid var(--grey-lighten-5);
}

.border-top {
  &-grey-light {
    border-top: 1px solid var(--grey-lighten-5);
  }

  &-grey-light-1 {
    border-top: 1px solid $stock-hint;
  }
}

.border-bottom-grey-light {
  border-bottom: 1px solid var(--grey-lighten-5);
}

/* hr line styles here */
.hr-light {
  margin-right: -15px;
  margin-left: -15px;
  border-color: $black-color-06;
}

/* Common hidden and show styles here */
.hide {
  visibility: hidden;
}

.show {
  visibility: visible;
}

/* Material icons styles overwrite here */
label {
  @include font(14px, 22px, 400, $text-grey-secondary);
  display: block;
  margin: 0 0 8px 0;

  &.grey {
    @include font(14px, 22px, 100, $text-grey-line !important);
    display: block;
    margin: 0 0 8px 0;
  }

  &.normal {
    @include font(14px, 22px, 400, $text-primary);
    display: block;
    margin: 0 0 8px 0;
  }

  &.bold {
    @include font(14px, 22px, 600, $text-primary);
    display: block;
    margin: 0 0 8px 0;
  }

  &.hint {
    @include font(14px, 18px, 400, $text-grey-hint);
    display: block;
    margin: 0 0 $spacer8 0;
  }

  &.secondary-hint {
    @include font(12px, 15px, 400, $text-grey-hint);
    display: block;
    margin: 0 0 $spacer8 0;
  }
}

/* Font-weight here */
.bold {
  font-weight: 600;
  color: $text-primary;
}

.f-w {
  &-500 {
    font-weight: 500 !important;
  }

  &-600 {
    font-weight: 600 !important;
  }

  &-700 {
    font-weight: 700 !important;
  }

  &-800 {
    font-weight: 800 !important;
  }

  &-400 {
    font-weight: 400 !important;
  }
}

.text-wrapper {
  word-break: break-word;
}

// border-radius styles here
.border-radius-full {
  border-radius: 50%;
}

/* Input group style here */
.input-grouped {
  .mat-form-field {
    .mat-select {
      background-color: $grey-lighten-3;
      border-radius: 2px 0 0 2px;
    }

    .mat-form-field-flex {
      border-radius: 0 2px 2px 0;
    }
  }
}

/* Flex concept */
.d-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}


.d-flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.v-align {
  align-items: center;
}

.d-flex-align {
  display: flex;
  align-items: center;
}

.flex-one {
  flex: 1;
}

.flex-direction-percentage {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.flex-direction-pixel {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

// line height styles here
.l-h {
  &-14 {
    line-height: 14px;
  }

  &-16 {
    line-height: 16px;
  }

  &-18 {
    line-height: 18px;
  }

  &-20 {
    line-height: 20px;
  }

  &-26 {
    line-height: 26px;
  }

  &-28 {
    line-height: 28px;
  }
}

// overflow styles Here
.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible !important;
}

// border-radius Here
.border-radius-xlarge {
  border-radius: 8px;
}

// padding

.p {
  &-10 {
    padding: 12px;
  }

  &-12 {
    padding: 12px;
  }

  &-20 {
    padding: 20px;
  }

  &-24 {
    padding: 24px;
  }
}

.p-r-72 {
  padding-right: 72px;
}

.p-t {
  &-12 {
    padding-top: 12px;
  }

  &-20 {
    padding-top: 20px;
  }

  &-26 {
    padding-top: 26px;
  }
}

.py-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-7 {
  padding-top: 7px;
  padding-bottom: 7px;
}

.py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.p-y {
  &-12 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &-14 {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  &-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.p-x {
  &-12 {
    padding-left: 12px;
    padding-right: 12px;
  }

  &-16 {
    padding-left: 16px;
    padding-right: 16px;
  }

  &-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  &-24 {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.p-b {
  &-6 {
    padding-bottom: 6px;
  }

  &-24 {
    padding-bottom: 24px;
  }
}

.p-t {
  &-6 {
    padding-top: 6px;
  }

  &-24 {
    padding-top: 24px;
  }
}

.p-32 {
  padding: 32px;
}

.p-16 {
  padding: 16px;
}

.p-t-b {
  padding: $spacer24 0;
}

.p-t-b-1 {
  padding: $spacer20 0;
}

.p-l-r {
  padding: $spacer16 $spacer24;
}

.pb-12 {
  padding-bottom: $spacer12;
}

.pl-6 {
  padding-left: $spacer6;
}

.pl-0 {
  padding-left: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.m-t {
  &-2 {
    margin-top: 2px;
  }

  &-6 {
    margin-top: 6px;
  }

  &-12 {
    margin-top: 12px;
  }

  &-16 {
    margin-top: 16px;
  }

  &-20 {
    margin-top: 20px;
  }

  &-24 {
    margin-top: 24px;
  }

  &-32 {
    margin-top: 32px;
  }
}

.m-minus-6 {
  margin-left: -6px;
}

.mb {
  &-6 {
    margin-bottom: 6px;
  }

  &-8 {
    margin-bottom: 8px;
  }

  &-10 {
    margin-bottom: 10px;
  }

  &-12 {
    margin-bottom: 12px !important;
  }

  &-14 {
    margin-bottom: 14px;
  }

  &-16 {
    margin-bottom: 16px;
  }

  &-18 {
    margin-bottom: 18px;
  }

  &-20 {
    margin-bottom: 20px;
  }

  &-32 {
    margin-bottom: 32px;
  }

  &-60 {
    margin-bottom: 60px;
  }
}

.pr {
  &-12 {
    padding-right: 12px;
  }
}

.pointer {
  cursor: pointer;
}

.secondary-color {
  color: $text-grey-content;
}

//margin styles here
.m-t-b {
  margin: $spacer24 0;
}

.m-t-8 {
  margin-top: 8px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-12 {
  margin-top: 12px;
}

.m-r-2 {
  margin-right: 2px;
}

.m-l-r {
  margin: 0 $spacer24;
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px !important;
}

.ml-32 {
  margin-left: 32px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-26 {
  margin-left: 26px;
}

.mr {
  &-10 {
    margin-right: 10px;
  }

  &-12 {
    margin-right: 12px !important;
  }

  &-20 {
    margin-right: 20px !important;
  }

  &-24 {
    margin-right: 24px !important;
  }
}

.m-r-6 {
  margin-right: 6px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.m-b {
  &-2 {
    margin-bottom: 2px;
  }

  &-4 {
    margin-bottom: 4px;
  }

  &-6 {
    margin-bottom: 6px;
  }

  &-8 {
    margin-bottom: 8px;
  }

  &-10 {
    margin-bottom: 10px;
  }

  &-16 {
    margin-bottom: 18px;
  }

  &-18 {
    margin-bottom: 18px;
  }

  &-20 {
    margin-bottom: 20px;
  }

  &-24 {
    margin-bottom: 24px;
  }
}

//max-width styles here

.mw {
  &-90 {
    max-width: 90px !important;
  }

  &-100 {
    max-width: 100px !important;
  }

  &-120 {
    max-width: 120px !important;
  }

  &-150 {
    max-width: 150px !important;
  }

  &-160 {
    max-width: 160px !important;
  }

  &-200 {
    max-width: 200px !important;
  }

  &-240 {
    max-width: 240px !important;
  }

  &-280 {
    max-width: 280px !important;
  }

  &-296 {
    max-width: 296px !important;
  }

  &-300 {
    max-width: 300px !important;
  }

  &-400 {
    max-width: 400px !important;
  }

  &-430 {
    max-width: 400px !important;
  }

  &-500 {
    max-width: 500px !important;
  }

  &-588 {
    max-width: 588px !important;
  }

  &-824 {
    max-width: 824px !important;
  }
}

//width styles here
.w {
  &-60 {
    width: 60% !important;
  }

  &-72 {
    width: 72% !important;
  }

  &-75 {
    width: 75% !important;
  }

  &-100 {
    width: 100% !important;
  }

  &-80 {
    width: 80% !important;
  }

  &-56 {
    width: 56% !important;
  }

  &-50 {
    width: 50% !important;
  }

  &-33 {
    width: 33% !important;
  }

  &-26 {
    width: 26% !important;
  }

  &-28 {
    width: 28% !important;
  }

  &-32 {
    width: 32% !important;
  }

  &-20 {
    width: 20% !important;
  }

  &-21 {
    width: 21% !important;
  }

  &-16 {
    width: 16% !important;
  }

  &-14 {
    width: 14% !important;
  }

  &-12 {
    width: 12% !important;
  }

  &-8 {
    width: 8% !important;
  }

  &-9 {
    width: 9% !important;
  }

  &-10 {
    width: 10% !important;
  }
}

.w-100px {
  width: 100px !important;
}

.w-110px {
  width: 110px !important;
}

.w-80 {
  width: 80% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-50px {
  width: 50px !important;
}

.w-70px {
  width: 70px !important;
}

.w-20px {
  width: 20px !important;
}

.w-200px {
  width: 200px !important;
}

.w-300px {
  width: 300px !important;
}

.w-320px {
  width: 320px !important;
}

.w-120px {
  width: 120px !important;
}

.w-130px {
  width: 130px !important;
}

.w-150px {
  width: 150px !important;
}

.w-250px {
  width: 250px !important;
}

.w-420px {
  width: 420px !important;
}

.w-345px {
  width: 345px !important;
}

/* height style here */

.h-200 {
  height: 200px !important;
}

.h-215 {
  min-height: 215px !important;
  max-height: 215px !important;
}

.h-20px {
  height: 20px !important;
}

.h-60 {
  height: 60px !important;
}

.h-250 {
  min-height: 250px !important;
  max-height: 250px !important;
}

.h-90 {
  height: 90% !important;
}

.h-100 {
  height: 100% !important;
}

.h-100px {
  min-height: 100px !important;
  max-height: 100px !important;
}

.h-200px {
  min-height: 200px !important;
  max-height: 200px !important;
}

.h-360px {
  min-height: 360px !important;
  max-height: 360px !important;
}

.h-450px {
  min-height: 450px !important;
  max-height: 450px !important;
}

.h-500px {
  min-height: 500px !important;
}

.h-300 {
  min-height: 300px !important;
  max-height: 300px !important;
}

.h-180px {
  height: 180px;
}

.h-150px {
  height: 150px;
}

.h-320px {
  height: 320px;
}

.h-292px {
  height: 292px;
}

.h-360px {
  height: 360px;
}

.h-82 {
  min-height: 82px !important;
  max-height: 82px !important;
}

.h-290px {
  height: 290px !important;
}

.h-220px {
  height: 220px !important;
}

.h-264px {
  height: 264px !important;
}

.h-470px {
  height: 470px !important;
}

.h-430px {
  height: 430px !important;
}

.h-16 {
  height: 16px;
}

.h-24 {
  height: 24px !important;
}

.h-36 {
  height: 36px !important;
}

.h-40 {
  height: 40px !important;
}

.h-auto {
  height: auto !important;
}

.min-h-100 {
  min-height: 100%;
}

::-webkit-file-upload-button {
  cursor: pointer;
}

/* border style here */
.border-botton-none {
  border-bottom: none !important;
}

.border-white-6 {
  border: 6px solid white;
}

// text color
$textColors: 'black'$black-primary , 'granite-gray'$granite-gray, 'charcoal-grey'$text-primary-4, 'error-color-primary'$error-primary,
  'shuttle-grey'$text-tertiary, 'black-color-85'$black-color-85, 'primary-01'$text-primary, 'hint'$text-hint, 'warning-color'$warn-primary,
  'charcoal-secondary'$text-secondary, 'aluminium-grey'$text-light, 'secondary'$secondary-color, 'grey-lighten-7'$grey-lighten-7, 'primary-color'$primary-color, 'white' $white-primary;

@each $color in $textColors {
  .txt-#{nth($color, 1)} {
    color: nth($color, 2) !important;
  }
}

//Background color
$bgColors: 'fulvous-opacity-12'$fulvous-opacity-12, 'orange-primary-color'$primary-color, 'white-smoke'$background-light-blue, 'white-lilac'$white-lilac,
  'secondary' $secondary-color, 'light-grey' $stock-hint, 'white-01'$stock-hint, 'light-white'$bg-light-white;

@each $color in $bgColors {
  .bg-#{nth($color, 1)} {
    background-color: nth($color, 2) !important;
  }
}

/* Text-overflow styles here */
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Position sticky header */
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1111;
  background-color: $white-primary;
}

.sticky-header-top {
  position: sticky;
  top: -15px;
  z-index: 1111;
  background-color: $white-primary;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  font-weight: 700;
}

/* Mobile responsive style here */
.m-hide {
  display: none;
}

.m-show {
  display: block;
}

.approval {
  &.mat-column-companyName {
    width: 20% !important;
  }

  &.mat-column-partnerName {
    width: 65% !important;
  }

  &.mat-column-review {
    width: 15% !important;
  }
}

.contact {
  &.mat-column-name {
    width: 27% !important;
  }

  &.mat-column-email {
    width: 27% !important;
  }

  &.mat-column-contactStatus {
    width: 27% !important;
  }
}

.line-height {
  line-height: 1;
}

.half-width {
  width: 50%;
}

span {
  &.text-grey {
    color: $text-grey-content;
  }
}

span {
  &.search-icon {
    i {
      @include font(18px, 2, normal, $text-grey-content);
    }
  }
}

.back-button {
  i {
    @include font(16px, 21px, normal, $text-grey-hint);
  }
}

button {
  outline: none !important;
}

/*cursor disabled class */
.disabled-cursor {
  cursor: not-allowed !important;
}

.disabled-cursor-none {
  cursor: none !important;
}

/* sidenav style here */
.sidenav-module {
  height: 100vh;
  background: linear-gradient(to bottom, $primary-color, $secondary-color);
  padding: $spacer24 $spacer32;
  z-index: 0;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 480px;
    height: 280px;
    background: url('/../../../assets/images/sidenav-bg.png') no-repeat;
    background-size: contain;
    display: inline-block;
    z-index: 1;
  }
}

/* Search */
.search {
  min-width: 310px;

  .mat-form-field {
    border-radius: $border-radius-rounded !important;

    .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex {
      border-radius: $border-radius-half-rounded !important;

      .mat-mdc-input-element {
        padding: $spacer8 !important;
      }

      .mat-mdc-form-field-infix {
        padding-left: 0 !important;
      }

      .mat-mdc-form-field-label {
        line-height: normal !important;
        top: $spacer20 !important;
      }
    }
  }
}

.search-rectangular {
  .mat-mdc-form-field {
    .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex {
      border-radius: $spacer4 !important;
    }
  }
}

/* Table section style here */
.table-section {
  height: calc(100vh - 260px);
  overflow: auto;

  table {
    .mat-mdc-header-cell {
      :first-child {
        border-top-left-radius: 8px !important;
      }
    }

    tr {
      .checkbox {
        width: 60px !important;
        line-height: 1px !important;

        .mat-mdc-checkbox {
          label {
            margin-bottom: 0;
          }
        }
      }

      th {
        padding: 12px;
        white-space: nowrap;
        font-weight: 600;
        color: $text-grey-secondary;
        font-size: 13px;

        .filter-one {
          vertical-align: baseline !important;
        }
      }

      .mat-mdc-header-cell {
        color: $text-primary;
        border-bottom: 1px solid $grey-color-51;
      }

      td {
        padding: 12px;
      }

      .mat-mdc-cell {
        font-size: 13px;
        font-weight: 400;
        border-bottom: 1px solid $grey-color-51;
        text-align: left;
        color: $text-tertiary;
      }

      .element-name {
        font-weight: 600 !important;
        color: $text-primary;

        .orange {
          background: $orange-color-20;
          border-radius: $border-radius-circle;
          color: $orange-color;
          @include size(24px);
          display: inline-block;
          text-align: center;
          line-height: 24px;
        }

        .blue {
          background: $blue-20;
          border-radius: $border-radius-circle;
          color: $blue;
          @include size(24px);
          display: inline-block;
          text-align: center;
          line-height: 24px;
        }

        .dark-green {
          background: $green-20;
          border-radius: $border-radius-circle;
          color: $green;
          @include size(24px);
          display: inline-block;
          text-align: center;
          line-height: 24px;
        }

        .red {
          background: $red-20;
          border-radius: $border-radius-circle;
          color: $red;
          @include size(24px);
          display: inline-block;
          text-align: center;
          line-height: 24px;
        }
      }

      .initial {
        width: 44px !important;
      }

      .status {
        .agreed {
          @include font(13px, 20px, 500, $success-primary);
        }

        .not-agreed {
          @include font(13px, 20px, 500, $error-light);
        }

        .pending,
        .in-progress {
          @include font(14px, 20px, 600, $warn-primary);
        }

        .requested,
        .rejected {
          @include font(14px, 20px, 600, $error-primary);
        }

        .approved,
        .active,
        .paid {
          @include font(14px, 20px, 600, $success-primary);
        }
      }

      .delete {
        @include font(18px, 1, normal, $text-grey-content);
        cursor: pointer;
        margin-left: 24px;
      }

      .restore {
        i {
          @include size(28px);
          background: $background-light-blue;
          border-radius: $border-radius-rounded;
          @include font(16px, 28px, normal, $blue);
          text-align: center;
        }
      }

      .email-details {
        i {
          @include size(28px);
          background: $green-lighten2-40;
          border-radius: $border-radius-rounded;
          @include font(16px, 28px, normal, $green);
          text-align: center;
        }
      }

      .eye-details {
        i {
          @include size(28px);
          background: $primary-color-16;
          border-radius: $border-radius-rounded;
          @include font(16px, 28px, normal, $primary-color);
          text-align: center;
        }
      }

      .reschedule-info {
        i {
          @include size(28px);
          background: $orange-color-40;
          border-radius: $border-radius-rounded;
          @include font(16px, 28px, normal, $orange-color);
          text-align: center;
        }
      }

      &.active {
        background-color: $error-secondary-01 !important;
      }

      &.diff-del {
        background-color: $orange-lighten-01 !important;
      }

      &.diff-ins {
        background-color: $success-lighten !important;
      }

      .team-tag {
        .team-tag-chip-list {
          display: flex;
          flex-wrap: wrap;

          p {
            @include font(12px, 15px, 400, $black-color-85);
            padding: $spacer4 $spacer8;
            background-color: $black-color-10;
            border-radius: $border-radius-rounded;
            margin-right: $spacer6;
            margin-bottom: $spacer6;
            display: flex;
            align-items: center;
            white-space: pre-line;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 700px;
            word-break: break-word;
          }
        }
      }

      &:hover {
        &.mat-mdc-row {
          td {
            background-color: $background-primary;
          }
        }
      }
    }
  }

  .cursor {
    cursor: pointer;
  }

  .team-tag {
    .team-tag-chip-list {
      display: flex;
      flex-wrap: wrap;

      p {
        @include font(12px, 15px, 400, $black-color-85);
        padding: $spacer4 $spacer8;
        background-color: $black-color-10;
        border-radius: $border-radius-rounded;
        margin-right: $spacer6;
        margin-bottom: $spacer6;
        display: flex;
        align-items: center;
        white-space: pre-line;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 700px;
        word-break: break-word;
      }
    }
  }

  .team-survey-icons {
    i {
      color: $grey-lighten-2 !important;
      background-color: $grey-lighten-2-16 !important;
    }

    .team-survey-late {
      i.active {
        color: $dark-green !important;
        background-color: $dark-green-14 !important;
      }
    }

    .team-survey-log {
      i.active {
        color: $orange-color !important;
        background-color: $orange-color-14 !important;
      }
    }

    .team-survey-detractor {
      i.active {
        color: $red !important;
        background-color: $red-14 !important;
      }
    }
  }

  .team-member-details {
    background-color: $primary-color-03;
    border-radius: $border-radius-rounded;
    padding: $spacer6 $spacer8;

    p {
      @include font(12px, 15px, 600, $primary-color-70);
    }

    &:hover {
      background-color: $primary-color-10;
      transition: 0.3s;

      p {
        @include font(12px, 15px, 600, $primary-color-70);
      }
    }
  }

  .mat-mdc-row {
    &:hover {
      .team-member-details {
        visibility: visible !important;
        transition: 0.3s;
      }
    }
  }

  &.user-table-responsive {
    height: calc(100vh - 202px);

    &.table-select-item {
      height: calc(100vh - 256px);
    }

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 60px;
          }

          &:nth-child(2) {
            min-width: 60px;
          }

          &:nth-child(3) {
            min-width: 180px;
          }

          &:nth-child(4) {
            min-width: 200px;
          }

          &:nth-child(5) {
            min-width: 150px;
          }

          &:nth-child(6) {
            min-width: 80px;
          }

          &:nth-child(7) {
            min-width: 110px;
          }

          &:nth-child(8) {
            min-width: 100px;
          }

          &:nth-child(9) {
            min-width: 100px;
          }

          &:last-of-type {
            text-align: center;
          }
        }
      }

      tr {

        th,
        td {

          .more-actions,
          .view-action {
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {

          .more-actions,
          .view-action {
            display: inline-table !important;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table !important;
          background-color: $black-color-05;
        }
      }
    }

    table {
      tr {
        &.mat-mdc-row {
          td {
            &.mat-mdc-cell:last-of-type {
              .more-container {
                .more-btn {
                  display: none !important;
                  font-size: 13px;
                  color: $grey-lighten-7 !important;
                  width: 28px;
                  height: 28px;
                  border-radius: 50px;
                  line-height: 0;

                  &:hover {
                    background: $border-grey;
                  }
                }
              }
            }
          }
        }

        &:hover {
          &.mat-mdc-row {
            td {
              &.mat-mdc-cell:last-of-type {
                div {
                  div {
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.cf-client-table {
    height: calc(100vh - 202px);

    &.table-select-item {
      height: calc(100vh - 256px);
    }

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 60px;
          }

          &:nth-child(2) {
            min-width: 60px;
          }

          &:nth-child(3) {
            min-width: 180px;
          }

          &:last-of-type {
            text-align: center !important;
          }
        }
      }

      tr {

        th,
        td {
          &:last-of-type {
            text-align: center;
          }

          .more-actions,
          .view-action {
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {

          .more-actions,
          .view-action {
            display: inline-table !important;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table !important;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.client-project-request {
    height: calc(100vh - 202px);

    tr {

      th,
      td {
        &:nth-child(1) {
          @include minMaxWidth(60px);
          width: 1%;
        }

        &:nth-child(2) {
          @include minMaxWidth(100px);
        }

        &:nth-child(3),
        &:nth-child(4) {
          @include minMaxWidth(300px);
        }

        &:nth-child(5),
        &:nth-child(7) {
          @include minMaxWidth(120px);
        }

        &:nth-child(6) {
          @include minMaxWidth(200px);
        }

        &:last-child {
          width: 100px;
          padding: 9px 12px;
          text-align: center;
        }

        .more-actions {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {
        .more-actions {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }

    &.table-select-item {
      height: calc(100vh - 256px) !important;
    }
  }

  &.client-table-responsive {
    width: calc(100vw - 108px);

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 60px;
          }

          &:nth-child(2) {
            min-width: 60px;
          }

          &:nth-child(3) {
            min-width: 150px;
          }

          &:nth-child(4) {
            min-width: 150px;
          }

          &:nth-child(5) {
            min-width: 150px;
          }

          &:nth-child(6) {
            min-width: 300px;
          }

          &:nth-child(7) {
            min-width: 300px;
          }

          &:nth-child(8) {
            min-width: 100px;
          }

          &:nth-child(9) {
            min-width: 60px;
          }
        }
      }
    }
  }

  &.contact-table-responsive {
    height: calc(100vh - 202px);

    &.table-select-item {
      height: calc(100vh - 256px);
    }

    .loader {
      height: calc(100vh - 340px);
    }

    tr {

      td,
      th {
        &:nth-child(1) {
          min-width: 60px;
          max-width: 60px;
        }

        &:nth-child(2) {
          min-width: 60px;
          max-width: 60px;
        }

        &:nth-child(3) {
          min-width: 200px;
          max-width: 200px;
        }

        &:nth-child(4) {
          min-width: 200px;
          max-width: 200px;
        }

        &:nth-child(5) {
          min-width: 200px;
          max-width: 200px;
        }

        &:nth-child(6) {
          min-width: 150px;
          max-width: 150px;
        }

        &:nth-child(7) {
          min-width: 100px;
          max-width: 100px;
        }

        &:last-child {
          text-align: center !important;
          padding: 9px 12px;
          width: 100px;
        }

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }
  }

  &.profile-contact-table-responsive {
    height: calc(100vh - 317px);

    &.table-select-item {
      height: calc(100vh - 371px);
    }

    .loader {
      height: calc(100vh - 317px);
    }

    .mat-mdc-table {
      tr {

        td,
        th {
          &:nth-child(1) {
            min-width: 60px;
            max-width: 60px;
            width: 0;
          }

          &:nth-child(2) {
            min-width: 200px;
            max-width: 200px;
          }

          &:nth-child(3) {
            min-width: 200px;
            max-width: 200px;
          }

          &:nth-child(4) {
            min-width: 200px;
            max-width: 200px;
          }

          &:nth-child(5) {
            min-width: 200px;
            max-width: 200px;
          }

          &:nth-child(6) {
            min-width: 150px;
            max-width: 150px;
          }

          &:nth-child(7) {
            min-width: 100px;
            max-width: 100px;
          }

          .more-actions,
          .view-action {
            text-align: center !important;
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {

          .more-actions,
          .view-action {
            display: inline-table;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.inactive-firm-partner-table {
    height: calc(100vh - 317px);

    &.table-select-item {
      height: calc(100vh - 371px);
    }

    .loader {
      height: calc(100vh - 317px);
    }

    .mat-mdc-table {
      tr {

        td,
        th {
          &:nth-child(1) {
            min-width: 250px;
            max-width: 300px;
          }

          &:nth-child(2) {
            min-width: 150px;
            max-width: 250px;
          }

          &:nth-child(3) {
            min-width: 100px;
            max-width: 100px;
          }

          .more-actions,
          .view-action {
            text-align: center !important;
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {

          .more-actions,
          .view-action {
            display: inline-table;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.project-table-responsive {
    width: calc(100vw - 108px);

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 60px;
          }

          &:nth-child(2) {
            min-width: 80px;
          }

          &:nth-child(3) {
            min-width: 180px;
          }

          &:nth-child(4) {
            min-width: 180px;
          }

          &:nth-child(5) {
            min-width: 180px;
          }

          &:nth-child(6) {
            min-width: 150px;
          }

          &:nth-child(7) {
            min-width: 150px;
          }

          &:nth-child(8) {
            min-width: 150px;
          }

          &:nth-child(9) {
            min-width: 150px;
          }

          &:nth-child(10) {
            min-width: 150px;
          }

          &:nth-child(11) {
            min-width: 80px;
          }
        }
      }
    }
  }

  &.client-project-table-responsive {
    height: calc(100vh - 202px);

    .loader {
      height: calc(100vh - 202px);
    }

    &.table-select-item {
      height: calc(100vh - 256px);
    }

    table {
      table-layout: fixed;
    }

    tr {

      th,
      td {
        &:first-child {
          width: 90px;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(8) {
          min-width: 200px;
        }

        &:nth-child(5),
        &:nth-child(6) {
          width: 115px;
        }

        &:nth-child(7) {
          width: 130px;
        }

        &:nth-child(9) {
          width: 95px;
        }

        &:nth-child(8) {
          min-width: 150px;
        }

        &:last-child {
          padding: 9px 12px !important;
          width: 100px;
          text-align: center;
        }

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table !important;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table !important;
        background-color: $black-color-05;
      }
    }
  }

  &.client-rfi-table-responsive {
    height: calc(100vh - 202px);

    .loader {
      height: calc(100vh - 202px);
    }

    &.table-select-item {
      height: calc(100vh - 256px);
    }

    table {
      table-layout: fixed;
    }

    tr {

      th,
      td {
        &:first-child {
          width: 145px;
        }

        &:nth-child(2) {
          width: 190px;
        }
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(8) {
          width: 180px;
        }

        &:nth-child(5),
        &:nth-child(6) {
          width: 100px;
        }

        &:nth-child(7) {
          width: 130px;
        }

        &:nth-child(9) {
          width: 95px;
        }

        &:nth-child(8) {
          width: 140px;
        }

        &:last-child {
          padding: 9px 12px !important;
          width: 100px;
          text-align: center;
        }

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table !important;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table !important;
        background-color: $black-color-05;
      }
    }
  }

  &.profile-client-project-table-responsive {
    height: calc(100vh - 390px);

    &.table-select-item {
      height: calc(100vh - 291px);
    }

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 60px;
          }

          &:nth-child(2) {
            min-width: 80px;
          }

          &:nth-child(3) {
            min-width: 180px;
          }

          &:nth-child(4) {
            min-width: 200px;
          }

          &:nth-child(5) {
            min-width: 150px;
          }

          &:nth-child(6) {
            min-width: 150px;
          }

          &:nth-child(7) {
            min-width: 170px;
          }

          &:nth-child(8) {
            min-width: 150px;
          }

          &:nth-child(9) {
            max-width: 150px;
          }

          &:nth-child(10) {
            min-width: 120px;
          }

          &:last-of-type {
            text-align: center;
          }
        }
      }
    }

    tr {

      th,
      td {

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table !important;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table !important;
        background-color: $black-color-05;
      }
    }
  }

  &.clients-table-responsive {
    height: calc(100vh - 202px) !important;

    tr {

      td,
      th {
        &:nth-child(1) {
          min-width: 60px;
        }

        &:nth-child(2) {
          min-width: 180px;
        }

        &:nth-child(3) {
          min-width: 180px;
        }

        &:nth-child(4) {
          min-width: 180px;
        }

        // Disabled for subscribed users column
        /*&:nth-child(5) {
             min-width: 180px;
          }*/
        &:nth-child(5) {
          min-width: 150px;
        }

        &:nth-child(6) {
          min-width: 150px;
        }

        &:nth-child(7) {
          min-width: 150px;
        }

        &:nth-child(8) {
          min-width: 50px;
        }

        &:last-child {
          text-align: center;
        }

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table !important;
        background-color: $black-color-05;
      }
    }
  }

  &.marketplace-project-list-table-responsive {
    height: calc(100vh - 202px) !important;

    tr {

      td,
      th {

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table !important;
        background-color: $black-color-05;
      }
    }
  }

  &.legal-entity-table-responsive {
    width: calc(100vw - 108px);
    height: calc(100vh - 340px) !important;

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 250px;
          }

          &:nth-child(2) {
            min-width: 200px;
          }

          &:nth-child(3) {
            min-width: 230px;
          }

          &:nth-child(4) {
            min-width: 230px;
          }

          &:nth-child(5) {
            min-width: 150px;
          }

          &:nth-child(6) {
            min-width: 150px;
          }

          &:nth-child(7) {
            min-width: 150px;
          }

          &:nth-child(8) {
            min-width: 100px;
          }

          &:nth-child(9) {
            min-width: 100px;
          }

          &:nth-child(10) {
            min-width: 80px;
          }
        }
      }
    }
  }

  &.admin-firm-table-responsive {
    height: calc(100vh - 202px);

    tr {

      th,
      td {
        &:nth-child(1) {
          min-width: 100px;
        }

        &:nth-child(2) {
          min-width: 207px;
        }

        &:nth-child(3) {
          min-width: 170px;
        }

        &:nth-child(4) {
          min-width: 100px;
        }

        &:nth-child(5) {
          min-width: 150px;
        }

        &:nth-child(6) {
          min-width: 140px;
        }

        &:nth-child(7) {
          min-width: 110px;
        }

        &:nth-child(8) {
          min-width: 120px;
        }

        &:nth-child(9) {
          min-width: 120px;
        }

        &:nth-child(10) {
          min-width: 120px;
        }

        &:last-of-type {
          text-align: center;
        }

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }
  }


  &.firm-table-responsive {
    height: calc(100vh - 202px);

    tr {

      th,
      td {
        &:nth-child(1) {
          min-width: 100px;
        }

        &:nth-child(2) {
          min-width: 280px;
        }

        &:nth-child(3) {
          min-width: 220px;
        }

        &:nth-child(4) {
          min-width: 100px;
        }

        &:nth-child(5) {
          min-width: 150px;
        }

        &:nth-child(6) {
          min-width: 180px;
        }

        &:nth-child(7) {
          min-width: 130px;
        }

        &:nth-child(8) {
          min-width: 120px;
        }

        &:nth-child(9) {
          min-width: 120px;
        }

        &:nth-child(10) {
          min-width: 120px;
        }

        &:last-of-type {
          text-align: center;
        }

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }
  }

  &.country-table-responsive {
    width: calc(100vw - 400px);
    height: calc(100vh - 320px);

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 180px;
          }

          &:nth-child(2) {
            min-width: 100px;
          }

          &:nth-child(3) {
            min-width: 100px;
          }

          &:nth-child(4) {
            min-width: 100px;
          }

          &:nth-child(5) {
            min-width: 100px;
          }

          &:nth-child(6) {
            min-width: 180px;
          }

          &:nth-child(7) {
            min-width: 60px;
          }
        }
      }
    }

    table {
      tr {
        &.mat-mdc-row {
          td {
            &.mat-mdc-cell:last-of-type {
              div {
                div {
                  display: none !important;
                  font-size: 13px;
                  color: $grey-lighten-7 !important;
                  width: 28px;
                  height: 28px;
                  border-radius: 50px;
                  line-height: 0;

                  &:hover {
                    background: $border-grey;
                  }
                }
              }
            }
          }
        }

        &:hover {
          background-color: $white-lilac !important;

          &.mat-mdc-row {
            td {
              &.mat-mdc-cell:last-of-type {
                div {
                  div {
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.language-table-responsive {
    height: calc(100vh - 316px);

    tr {

      th,
      td {
        &:nth-child(1) {
          @include minMaxWidth(300px);
        }

        &:nth-child(2),
        &:nth-child(3) {
          @include minMaxWidth(200px);
        }

        &:last-child {
          text-align: center;
          width: 100px;
          padding: 9px 12px;
        }

        .more-actions {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {
        .more-actions {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }
  }

  &.file-format-table-responsive {
    height: calc(100vh - 357px);
    overflow: auto;

    &.table-select-item {
      height: calc(100vh - 411px);
    }

    tr {

      th,
      td {
        &:nth-child(1) {
          width: 100px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          @include minMaxWidth(400px);
        }

        &:last-child {
          text-align: center;
          width: 100px;
          padding: 9px 12px;
        }

        .more-actions {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {
        .more-actions {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }
  }

  &.srm-table-responsive {
    height: calc(100vh - 382px);
    overflow: auto;

    &.table-select-item {
      height: calc(100vh - 438px);
    }

    tr {

      th,
      td {
        &:nth-child(1) {
          width: 100px;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          @include minMaxWidth(120px);
        }

        &:last-child {
          text-align: center;
          width: 100px;
          padding: 9px 12px;
        }

        .more-actions {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {
        .more-actions {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }
  }

  &.questions-table-responsive {
    height: calc(100vh - 202px);

    tr {

      th,
      td {
        &:nth-child(1) {
          min-width: 50px;
        }

        &:nth-child(2) {
          min-width: 500px;
        }

        &:nth-child(2) {
          min-width: 400px;
        }

        &:nth-child(3) {
          min-width: 100px;
        }

        &:last-of-type {
          text-align: center;
        }

        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {
        .view-action {
          display: inline-table;
        }
      }
    }
  }

  &.localization-table-responsive {
    width: calc(100vw - 108px);

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 30px;
          }

          &:nth-child(2) {
            min-width: 200px;
          }

          &:nth-child(3) {
            min-width: 900px;
          }
        }
      }
    }
  }

  &.emails-table-responsive {
    height: calc(100vh - 202px);
    overflow: auto;

    tr {

      th,
      td {
        &:nth-child(1) {
          min-width: 200px;
        }

        &:nth-child(2) {
          min-width: 400px;
        }

        &:nth-child(3) {
          min-width: 200px;
        }

        &:nth-child(3) {
          min-width: 100px;
        }

        &:last-of-type {
          text-align: center;
        }

        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {
        .view-action {
          display: inline-table;
        }
      }
    }
  }

  &.industries-table-responsive,
  &.capabilities-table-responsive {
    height: calc(100vh - 357px);
    overflow: auto;

    &.with-pagination {
      height: calc(100vh - 405px);
    }

    tr {

      th,
      td {
        &:nth-child(1) {
          width: 25%;
          max-width: 25%;
        }

        &:last-child {
          width: 10%;
          max-width: 10%;
          text-align: center;
          padding: 9px 12px;
        }

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }
  }

  &.industries-child-table-responsive,
  &.capabilities-child-table-responsive {
    width: calc(100vw - 400px);
    height: calc(100vh - 380px);

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 100px;
          }

          &:nth-child(2) {
            min-width: 300px;
          }

          &:nth-child(3) {
            min-width: 100px !important;
          }
        }
      }
    }
  }

  &.regions-table-responsive {
    height: calc(100vh - 357px);
    overflow: auto;

    &.with-pagination {
      height: calc(100vh - 405px);
    }

    tr {

      th,
      td {
        &:nth-child(1) {
          width: 25%;
          max-width: 25%;
        }

        &:last-child {
          width: 10%;
          max-width: 10%;
          text-align: center;
          padding: 9px 12px;
        }

        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {
        .view-action {
          display: inline-table;
        }
      }
    }
  }

  &.client-profile-task {
    height: calc(100vh - 337px) !important;
    border: 1px solid $stock-hint;
    border-radius: 8px;

    table {
      th {
        border-right: none;
        border-radius: 0;
      }
    }

    tr {

      th,
      td {

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table !important;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table !important;
        background-color: $black-color-05;
      }
    }
  }

  &.action-table-responsive,
  .client-profile-task {
    height: calc(100vh - 202px);

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 100px;
          }

          &:nth-child(2) {
            min-width: 330px;
          }

          &:nth-child(3) {
            min-width: 120px;
          }

          &:nth-child(4) {
            min-width: 200px;
          }

          &:nth-child(5) {
            min-width: 150px;
          }

          &:nth-child(6) {
            min-width: 150px;
          }

          &:nth-child(7) {
            min-width: 200px;
          }

          &:nth-child(8) {
            min-width: 100px;
          }

          &:nth-child(9) {
            min-width: 80px;
          }

          &:last-of-type {
            text-align: center;
          }
        }
      }
    }

    tr {

      th,
      td {

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table !important;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table !important;
        background-color: $black-color-05;
      }
    }
  }

  &.firm-action-table-responsive {
    height: calc(100vh - 327px);

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 100px;
          }

          &:nth-child(2) {
            min-width: 330px;
          }

          &:nth-child(3) {
            min-width: 120px;
          }

          &:nth-child(4) {
            min-width: 200px;
          }

          &:nth-child(5) {
            min-width: 150px;
          }

          &:nth-child(6) {
            min-width: 150px;
          }

          &:nth-child(7) {
            min-width: 200px;
          }

          &:nth-child(8) {
            min-width: 100px;
          }

          &:nth-child(9) {
            min-width: 80px;
          }
        }
      }
    }

    tr {

      th,
      td {

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table !important;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table !important;
        background-color: $black-color-05;
      }
    }
  }

  &.organisational-unit-table-responsive {
    width: calc(100vw - 410px);

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 250px;
          }

          &:nth-child(2) {
            min-width: 180px;
          }

          &:nth-child(3) {
            min-width: 180px;
          }

          &:nth-child(4) {
            min-width: 200px;
          }

          &:nth-child(5) {
            min-width: 180px;
          }

          &:nth-child(6) {
            min-width: 180px;
          }

          &:nth-child(7) {
            min-width: 230px;
          }

          &:nth-child(8) {
            min-width: 80px;
          }
        }
      }
    }
  }

  &.rfp-project-team-table-responsive {
    overflow: auto;
    border-radius: 8px;
    width: 100%;

    .mat-mdc-table {
      tr {
        padding: 0px;

        .mat-mdc-row {
          height: 48px;

          &:hover {
            background-color: $white-lilac !important;
          }
        }

        &.mat-mdc-header-row {
          th {
            padding: 0px;

            &:first-of-type {
              padding-left: 12px;
            }

            &:last-of-type {
              width: 6%;
              text-align: center;
            }
          }
        }
      }

      td {
        padding: 0px;

        &.mat-mdc-cell {
          &:first-of-type {
            text-align: left !important;
            padding-left: 12px;
          }

          &:last-of-type {
            text-align: center;

            div {
              display: none !important;

              a {
                @include size(28px);
              }
            }
          }
        }
      }

      // Hover
      .mat-mdc-row {
        &:hover {
          background-color: $white-lilac !important;

          td.mat-mdc-cell {
            &:last-child {
              div {
                display: flex !important;
                justify-content: center;
                align-items: center;

                a {
                  text-decoration: none;

                  i {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px !important;
                    border-radius: 50%;
                    @include size(28px);

                    &:hover {
                      background: $border-grey;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.home-project-team-table-responsive {
    height: fit-content !important;
    width: 100%;

    .mat-mdc-table {
      border: unset !important;
      border-radius: unset !important;

      tr {
        padding: 0px;

        .mat-mdc-row {
          height: 48px;

          &:hover {
            background-color: $white-lilac !important;
          }
        }

        &.mat-mdc-header-row {
          th {
            padding: 0px;

            &:first-of-type {
              padding-left: 12px;
              width: 307px;
            }
            &:nth-child(2) {
              width: 200px;
            }
          }
        }
      }

      td.mat-mdc-cell:last-of-type {
        padding: unset;
      }

      tr.mat-mdc-row:last-of-type {
        td.mat-mdc-cell {
          border-bottom: none;
        }
      }

      td {
        padding: 0px;

        &.mat-mdc-cell {
          &:first-of-type {
            text-align: left !important;
            padding-left: 12px;
          }
        }
      }

    }
  }

  &.sow-team-table-responsive {
    height: 100%;

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            @include minMaxWidth(150px);
          }

          &:nth-child(2) {
            @include minMaxWidth(100px);
          }

          &:nth-child(3) {
            @include minMaxWidth(100px);
          }

          &:nth-child(4) {
            @include minMaxWidth(100px);
          }
        }
      }

      .mat-mdc-row {
        .mat-mdc-cell {
          &:nth-child(1) {
            text-align: start !important;
          }

          &:nth-child(2) {
            text-align: start !important;
          }
        }
      }
    }

    tr {

      th,
      td {

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table !important;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table !important;
        background-color: $black-color-05;
      }
    }
  }

  &.project-performance-team {
    height: 400px;

    .mat-mdc-table {
      tbody {
        tr {

          td,
          th {
            &:nth-child(1) {
              min-width: 200px;
            }

            &:nth-child(2) {
              min-width: 100px;
            }

            &:nth-child(3) {
              min-width: 100px;
            }

            &:nth-child(4) {
              min-width: 80px;
            }

            &:nth-child(5) {
              min-width: 100px;
            }

            &:nth-child(6) {
              min-width: 100px;
            }

            &:nth-child(7) {
              min-width: 100px;
            }

            &:nth-child(8) {
              min-width: 80px;
            }

            &:nth-child(9) {
              min-width: 80px;
            }

            &:nth-child(10) {
              min-width: 80px;
            }

            &:nth-child(11) {
              min-width: 80px;
            }

            .more-actions {
              color: $grey-lighten-7;
              display: none;

              &:hover {
                background-color: $black-color-05;
              }
            }
          }
        }

        &:hover {
          .more-actions {
            display: inline-table;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table;
          background-color: $black-color-05;
        }
      }

      .mat-mdc-row {
        &:hover {
          background-color: $white-lilac !important;
        }
      }
    }
  }

  &.rfp-export-history-table-responsive {
    .mat-mdc-table {
      tbody {
        tr {

          td,
          th {
            &:nth-child(1) {
              min-width: 300px;
            }

            &:nth-child(2) {
              min-width: 200px;
            }

            &:nth-child(3) {
              min-width: 200px;
            }

            &:nth-child(4) {
              min-width: 100px;
            }

            &:nth-child(5) {
              min-width: 100px;
            }

            &:nth-child(5) {
              min-width: 60px;
            }

            .more-actions,
            .view-action {
              color: $grey-lighten-7;
              display: none;

              &:hover {
                background-color: $black-color-05;
              }
            }
          }

          &:hover {

            .more-actions,
            .view-action {
              display: inline-table;
            }
          }

          .more-actions[aria-expanded='true'] {
            display: inline-table;
            background-color: $black-color-05;
          }
        }

      }

      .mat-mdc-row {
        &:hover {
          background-color: $white-lilac !important;
        }
      }
    }
  }

  &.signature-export-table {
    .mat-mdc-table {
      tbody {
        tr {

          td,
          th {
            &:nth-child(1) {
              min-width: 300px;
            }

            &:nth-child(2) {
              min-width: 200px;
            }

            &:nth-child(3) {
              min-width: 100px;
            }

            &:nth-child(4) {
              min-width: 100px;
            }

            &:nth-child(5) {
              min-width: 60px;
            }

            .more-actions,
            .view-action {
              color: $grey-lighten-7;
              display: none;

              &:hover {
                background-color: $black-color-05;
              }
            }
          }

          &:hover {

            .more-actions,
            .view-action {
              display: inline-table;
            }
          }

          .more-actions[aria-expanded='true'] {
            display: inline-table;
            background-color: $black-color-05;
          }
        }

      }

      .mat-mdc-row {
        &:hover {
          background-color: $white-lilac !important;
        }
      }
    }
  }

  &.rfp-demand-approver-tabel {
    height: auto;
    max-height: 300px;

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 250px;
          }

          &:nth-child(2) {
            min-width: 180px;
          }

          &:nth-child(3) {
            min-width: 180px;
          }

          &:nth-child(4) {
            min-width: 180px;
          }
        }
      }
    }
  }

  &.sow-document {
    height: calc(100vh - 370px);

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 250px;
            vertical-align: middle;
          }

          &:nth-child(2) {
            min-width: 200px;
            vertical-align: middle;
          }

          &:nth-child(3) {
            min-width: 150px;
            vertical-align: middle;
          }

          &:nth-child(4) {
            min-width: 150px;
            vertical-align: middle;
          }

          &:last-of-type {
            text-align: center;
          }
        }
      }

      .mat-mdc-cell {
        &:nth-child(1) {
          vertical-align: middle;
        }

        &:nth-child(2) {
          vertical-align: middle;
        }

        &:nth-child(3) {
          vertical-align: middle;
        }

        &:nth-child(4) {
          vertical-align: middle;
        }
      }

      tr {
        td {

          .more-actions,
          .view-action {
            display: none;
          }
        }

        &:hover {

          .more-actions,
          .view-action {
            display: inline-table !important;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table !important;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.sow-home-document {
    height: fit-content;

    .mat-mdc-table {
      border-radius: unset !important;
      border: unset !important;

      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 250px;
            vertical-align: middle;
          }

          &:nth-child(2) {
            min-width: 150px;
            vertical-align: middle;
          }

          &:nth-child(3) {
            min-width: 150px;
            vertical-align: middle;
          }

          &:nth-child(4) {
            min-width: 100px;
            vertical-align: middle;
          }

          &:last-of-type {
            text-align: center;
          }
        }
      }

      .mat-mdc-cell {
        &:nth-child(1) {
          vertical-align: middle;
        }

        &:nth-child(2) {
          vertical-align: middle;
        }

        &:nth-child(3) {
          vertical-align: middle;
        }

        &:nth-child(4) {
          vertical-align: middle;
        }
      }

      tr {
        td {

          .more-actions,
          .view-action {
            display: none;
          }
        }

        &:hover {

          .more-actions,
          .view-action {
            display: inline-table !important;
          }
        }

        &:last-of-type {
          td {
            &.mat-mdc-cell {
              border-bottom: none !important;
            }
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table !important;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.pm-objectives {
    height: fit-content;

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 200px;
            vertical-align: middle;
          }

          &:nth-child(2) {
            min-width: 200px;
            vertical-align: middle;
          }

          &:nth-child(3) {
            min-width: 150px;
            vertical-align: middle;
          }

          &:nth-child(4) {
            min-width: 80px;
            vertical-align: middle;
          }

          &:last-of-type {
            text-align: center;
          }
        }
      }

      .mat-mdc-cell {
        &:nth-child(1) {
          vertical-align: middle;
        }

        &:nth-child(2) {
          vertical-align: middle;
        }

        &:nth-child(3) {
          vertical-align: middle;
        }

        &:nth-child(4) {
          vertical-align: middle;
        }
      }

      tr {
        td {

          .more-actions,
          .view-action {
            display: none;
          }
        }

        &:hover {

          .more-actions,
          .view-action {
            display: inline-table !important;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table !important;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.sow-term-table-responsive {
    height: fit-content;

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 100px;
          }

          &:nth-child(2) {
            min-width: 300px;
          }

          &:nth-child(3) {
            min-width: 100px;
          }
        }
      }
    }
  }

  &.sow-project-governance-table {
    height: fit-content;

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 100px;
          }

          &:nth-child(2) {
            min-width: 200px;
          }

          &:nth-child(3) {
            min-width: 200px;
          }

          &:nth-child(4) {
            min-width: 80px;
          }
        }
      }
    }

    // Hover
    tr {

      th,
      td {

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table !important;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table !important;
        background-color: $black-color-05;
      }
    }
  }

  &.sow-management-milestone-table-responsive {
    height: fit-content;

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 100px;
          }

          &:nth-child(2) {
            min-width: 200px;
          }

          &:nth-child(3) {
            max-width: 100px;
          }

          &:nth-child(4) {
            max-width: 100px;
          }

          &:last-of-type {
            text-align: center;
          }
        }
      }

      td {
        &:last-of-type {
          div {
            display: none;

            a {
              @include size(28px);
            }
          }
        }
      }
    }

    // Hover
    .mat-mdc-row {
      &:hover {
        background-color: $white-lilac !important;

        td.mat-mdc-cell {
          &:last-child {
            div {
              display: flex !important;
              justify-content: center;
              align-items: center;

              a {
                text-decoration: none;

                i {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 20px !important;
                  border-radius: 50%;
                  @include size(28px);

                  &:hover {
                    background: $border-grey;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.project-management-home-docs-responsive {
    max-height: 452px;
    overflow: auto;
    height: auto;

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 150px !important;
            max-width: 150px !important;
          }

          &:nth-child(2) {
            min-width: 200px !important;
            max-width: 200px !important;
          }

          &:nth-child(3) {
            max-width: 120px !important;
            max-width: 120px !important;
          }

          &:last-of-type {
            text-align: center;
          }
        }
      }
    }

    table {
      thead {
        position: sticky !important;
        top: 0 !important;
      }

      tr {
        &:last-of-type {
          td {
            &.mat-mdc-cell {
              border-bottom: none !important;
            }
          }
        }

        th,
        td {

          &:last-of-type {
            text-align: center;
          }

          .more-actions,
          .view-action {
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {

          .more-actions,
          .view-action {
            display: inline-table !important;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table !important;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.project-management-home-table-responsive {
    max-height: 452px;
    overflow: auto;
    height: auto;

    table {
      thead {
        position: sticky !important;
        top: 0 !important;
      }

      tr {

        &:last-of-type {
          td {
            &.mat-mdc-cell {
              border-bottom: none !important;
            }
          }
        }

        th,
        td {

          .more-actions,
          .view-action {
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {

          .more-actions,
          .view-action {
            display: inline-table !important;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table !important;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.project-management-milestone-table-responsive {
    height: fit-content;
    width: calc(100vw - 400px);

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 100px;
          }

          &:nth-child(2) {
            min-width: 200px;
          }

          &:nth-child(3) {
            max-width: 100px;
          }

          &:nth-child(4) {
            max-width: 100px;
          }

          &:last-of-type {
            text-align: center;
          }
        }
      }

      td {
        &:last-of-type {
          div {
            display: none;

            a {
              @include size(28px);
            }
          }
        }
      }
    }

    // Hover
    .mat-mdc-row {
      &:hover {
        background-color: $white-lilac !important;

        td.mat-mdc-cell {
          &:last-child {
            div {
              display: flex !important;
              justify-content: center;
              align-items: center;

              a {
                text-decoration: none;

                i {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 20px !important;
                  border-radius: 50%;
                  @include size(28px);

                  &:hover {
                    background: $border-grey;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.pm-milestone-table-responsive {
    height: calc(100vh - 425px) !important;

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 100px;
          }

          &:nth-child(2) {
            min-width: 200px;
          }

          &:nth-child(3) {
            max-width: 100px;
          }

          &:nth-child(4) {
            max-width: 100px;
          }

          &:last-of-type {
            text-align: center;
          }
        }
      }

      tr {

        th,
        td {

          .more-actions,
          .view-action {
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {

          .more-actions,
          .view-action {
            display: inline-table !important;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table !important;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.changes-milestone-table-responsive {
    height: fit-content !important;
    margin-bottom: 16px;

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 100px;
          }

          &:nth-child(2) {
            min-width: 200px;
          }

          &:nth-child(3) {
            max-width: 112px;
          }

          &:nth-child(4) {
            max-width: 100px;
          }
        }
      }

      tr {

        th,
        td {

          .more-actions,
          .view-action {
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {

          .more-actions,
          .view-action {
            display: inline-table !important;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table !important;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.changes-invoice-table-responsive {
    height: fit-content !important;
    margin-bottom: 16px;

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 100px;
          }

          &:nth-child(2) {
            min-width: 200px;
          }

          &:nth-child(3) {
            max-width: 100px;
          }

          &:nth-child(4) {
            max-width: 100px;
          }
        }
      }

      tr {

        th,
        td {

          .more-actions,
          .view-action {
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {

          .more-actions,
          .view-action {
            display: inline-table !important;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table !important;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.sow-termAndCondition-Cost-table-responsive {
    height: fit-content;

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 200px;
          }

          &:nth-child(2) {
            min-width: 200px;
          }

          &:nth-child(3) {
            min-width: 80px;
          }
        }
      }
    }
  }

  &.sow-termAndCondition-milestone-table-responsive {
    height: fit-content;

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 200px;
          }

          &:nth-child(2) {
            min-width: 200px;
          }

          &:nth-child(3) {
            min-width: 200px;
          }

          &:nth-child(4) {
            min-width: 80px;
          }
        }
      }

      td {
        &:last-of-type {
          div {
            display: none;
          }
        }
      }
    }

    // Hover
    .mat-mdc-row {
      &:hover {
        td.mat-mdc-cell {
          &:last-child {
            div {
              display: flex !important;

              i {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px !important;
                border-radius: 50%;
                @include size(28px);
                margin-left: 0;

                &:first-child {
                  &:hover {
                    background: $border-grey;
                  }
                }

                &:last-child {
                  &:hover {
                    background: $border-grey;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.sow-termAndCondition-detail-fee-responsive {
    height: fit-content;

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 200px;
          }

          &:nth-child(2) {
            min-width: 200px;
          }

          &:nth-child(3) {
            min-width: 200px;
          }

          &:nth-child(4) {
            min-width: 200px;
          }

          &:nth-child(5) {
            min-width: 150px;
          }

          &:nth-child(6) {
            min-width: 150px;
          }

          &:nth-child(7) {
            min-width: 100px;
          }
        }
      }
    }
  }

  &.rfp-selection-firm-tabel {
    height: fit-content;

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 300px;
          }

          &:nth-child(2) {
            min-width: 400px;
          }

          &:nth-child(3) {
            min-width: 100px;
            text-align: center;
          }

          &:nth-child(4) {
            width: 30px;
            text-align: center;
          }

          &:nth-child(5) {
            width: 20px;
            text-align: center;
          }

          &:nth-child(6) {
            text-align: center;
          }
        }
      }

      td {
        &:last-of-type {
          .view-action {
            display: none;
          }
        }
      }

      .mat-mdc-cell {
        &:nth-child(3) {
          text-align: center;
        }

        &:nth-child(4) {
          text-align: center;
        }

        &:nth-child(5) {
          text-align: center;
        }

        &:nth-child(6) {
          text-align: center;
        }
      }
    }

    // Hover
    tr {

      th,
      td {

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table !important;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table !important;
        background-color: $black-color-05;
      }
    }
  }

  &.rfp-selection-firm-tabel-home {
    height: fit-content;

    .mat-mdc-table {
      border-collapse: collapse;

      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 300px;
          }

          &:nth-child(2) {
            min-width: 400px;
          }

          &:nth-child(3) {
            min-width: 100px;
            text-align: center;
          }

          &:nth-child(4) {
            width: 30px;
            text-align: center;
          }

          &:nth-child(5) {
            width: 20px;
            text-align: center;
          }

          &:nth-child(6) {
            text-align: center;
          }
        }
      }

      td {
        &:last-of-type {
          .view-action {
            display: none;
          }
        }
      }

      .mat-mdc-cell {
        &:nth-child(3) {
          text-align: center;
        }

        &:nth-child(4) {
          text-align: center;
        }

        &:nth-child(5) {
          text-align: center;
        }

        &:nth-child(6) {
          text-align: center;
        }
      }
    }

    // Hover
    tr {

      th,
      td {

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table !important;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table !important;
        background-color: $black-color-05;
      }
    }
  }

  &.rfp-invitation-firm-tabel {
    height: fit-content;
    margin-bottom: 16px;

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 154px;
          }

          &:nth-child(2) {
            min-width: 140px;
          }

          &:nth-child(3) {
            min-width: 110px;
            text-align: center;
          }

          &:nth-child(4) {
            min-width: 140px;
            text-align: center;
          }

          &:nth-child(5) {
            min-width: 130px;
            text-align: center;
          }

          &:nth-child(6) {
            min-width: 110px;
            text-align: center;
          }

          &:nth-child(7) {
            min-width: 80px;
            text-align: center;
          }

          &:nth-child(8) {
            min-width: 120px;
            text-align: center;
          }
        }
      }

      .mat-mdc-cell {

        &:nth-child(6) .mat-icon,
        &:nth-child(7) .mat-icon {
          margin: auto;
        }
      }


      .mat-mdc-cell {

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(8) {
          text-align: center;
        }
      }

      tr {

        th,
        td {
          &.mat-mdc-cell {
            &:first-of-type {
              max-width: 90px !important;
            }
          }

          .more-actions,
          .view-action {
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {

          .more-actions,
          .view-action {
            display: inline-table !important;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table !important;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.rfp-project-proposal-evaluator-responsive {
    overflow: auto;
    border-radius: 8px;
    width: 100%;
    height: calc(100vh - 408px);

    .mat-mdc-table {
      tr {
        .mat-mdc-row {
          height: 48px;

          &:hover {
            background-color: $white-lilac !important;
          }
        }

        &.mat-mdc-header-row {
          th {
            padding: 0px;

            &:first-of-type {
              padding-left: 12px;
            }
          }
        }

        th,
        td {

          .more-actions,
          .view-action {
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {

          .more-actions,
          .view-action {
            display: inline-table !important;
          }
        }
      }

      td {
        padding: 0px;

        &.mat-mdc-cell {
          &:first-of-type {
            text-align: left !important;
            padding-left: 12px;
          }

          &:last-of-type {
            text-align: center;
          }
        }
      }
    }
  }

  &.library {
    height: calc(100vh - 202px);
  }

  &.library,
  &.sourcing-invitation {
    .mat-mdc-table {
      tr {

        th,
        td {
          &:last-child {
            text-align: center;
            padding: 9px 12px;
          }
        }
      }
    }

    table {
      tr {
        td {
          .more-actions {
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        .open-new-view {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }

        &:hover {

          .more-actions,
          .open-new-view {
            display: inline-table;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.firm-user-table-active {
    .mat-mdc-table {
      tr {

        th,
        td {
          &:last-child {
            text-align: left !important;
          }
        }
      }
    }
  }

  &.sourcing-user-firm {
    table {
      tr {

        th,
        td {
          &:nth-child(1) {
            min-width: 400px !important;
          }

          &:nth-child(2) {
            min-width: 400px !important;
          }

          &:nth-child(3) {
            min-width: 300px !important;
          }
        }
      }
    }
  }

  &.user-invite-request-list {
    height: calc(100vh - 188px);

    table {
      tr {
        &:hover {
          td {
            background-color: $white-lilac !important;

            &:last-child {
              padding: 0 !important;
            }
          }
        }
      }
    }
  }

  &.provider-performance-list {
    height: calc(100vh - 202px);
    width: 100% !important;

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 250px;
          }

          &:nth-child(2) {
            min-width: 100px;
          }

          &:nth-child(3) {
            min-width: 100px;
          }

          &:nth-child(4) {
            min-width: 150px;
          }

          &:nth-child(5) {
            min-width: 150px;
          }

          &:nth-child(6) {
            min-width: 100px;
          }
        }
      }

      tr {

        th,
        td {

          .more-actions,
          .view-action {
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {

          .more-actions,
          .view-action {
            display: inline-table !important;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table !important;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.invited-project-table-responsive {
    width: calc(100vw - 108px);
    height: calc(100vh - 196px);

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 100px;
          }

          &:nth-child(2) {
            min-width: 300px;
          }

          &:nth-child(3) {
            min-width: 240px;
          }

          &:nth-child(4) {
            min-width: 240px;
          }

          &:nth-child(5) {
            min-width: 150px;
          }

          &:nth-child(6) {
            min-width: 150px;
          }

          &:nth-child(7) {
            min-width: 150px;
          }

          &:nth-child(8) {
            min-width: 150px;
          }

          &:nth-child(9) {
            min-width: 200px;
          }

          &:last-of-type {
            text-align: center !important;
          }
        }
      }
    }

    table {
      tr {
        &.mat-mdc-row {
          td {
            &.mat-mdc-cell:last-of-type {
              .more-actions {
                div {
                  display: none !important;
                  font-size: 13px;
                  color: $grey-lighten-7 !important;
                  width: 28px;
                  height: 28px;
                  border-radius: 50px;
                  line-height: 0;
                }
              }
            }
          }
        }

        &:hover {
          &.mat-mdc-row {
            td {
              &.mat-mdc-cell:last-of-type {
                .more-actions {
                  div {
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.client-profile-contract {
    height: calc(100vh - 320px);

    .mat-mdc-table {
      tr {

        th,
        td {
          .more-actions {
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {
          .more-actions {
            display: inline-table;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.client-profile-meetings {
    height: calc(100vh - 320px);

    .mat-mdc-table {
      tr {
        height: 57px !important;

        th,
        td {
          &:nth-child(1) {
            min-width: 100px;
            max-width: 100px;
          }

          &:nth-child(2) {
            min-width: 100px;
            max-width: 100px;
          }

          &:nth-child(3) {
            min-width: 100px;
            max-width: 100px;
          }

          &:last-child {
            text-align: center;
          }

          .view-action {
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {
          .view-action {
            display: inline-table;
          }
        }

        .view-action[aria-expanded='true'] {
          display: inline-table;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.client-profile-projects {
    height: calc(100vh - 329px);

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 100px;
          }

          &:nth-child(2) {
            min-width: 300px;
          }

          &:nth-child(3) {
            min-width: 250px;
          }

          &:nth-child(4) {
            min-width: 250px;
          }

          &:nth-child(5) {
            min-width: 150px;
          }

          &:nth-child(6) {
            min-width: 150px;
          }

          &:nth-child(7) {
            min-width: 200px;
          }
        }
      }
    }

    tr {

      th,
      td {
        &:last-of-type {
          text-align: center;
        }

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none !important;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table !important;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table !important;
        background-color: $black-color-05;
      }
    }
  }

  &.rfp-proposal {
    height: fit-content;
    max-height: calc(100vh - 504px);

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 300px;
          }

          &:nth-child(2) {
            min-width: 100px;
          }

          &:nth-child(3) {
            min-width: 100px;
          }

          &:nth-child(4) {
            min-width: 150px;
          }

          &:nth-child(5) {
            min-width: 150px;
          }
        }
      }
    }
  }

  &.currency-table-responsive {
    height: calc(100vh - 357px);
    overflow: auto;

    &.with-pagination {
      height: calc(100vh - 405px);
    }

    tr {

      th,
      td {
        &:nth-child(1) {
          @include minMaxWidth(40%);
        }

        &:nth-child(2),
        &:nth-child(3) {
          @include minMaxWidth(25%);
        }

        &:last-child {
          text-align: center;
          padding: 9px 12px;
          @include minMaxWidth(10%);
        }

        .action-delete {
          color: $error-primary;
          display: none;

          &:hover {
            background-color: $error-hint;
          }
        }
      }

      &:hover {
        .action-delete {
          display: inline-table !important;
        }
      }
    }
  }

  &.currency-exchange-table-responsive {
    height: calc(100vh - 357px);
    overflow: auto;

    &.with-pagination {
      height: calc(100vh - 405px);
    }

    &.table-select-item {
      height: calc(100vh - 460px) !important;
    }

    tr {

      th,
      td {

        &:nth-child(1),
        &:nth-child(2) {
          width: 20%;
          max-width: 30%;
        }

        &:nth-child(3) {
          width: 20%;
          max-width: 20%;
        }

        &:nth-child(4) {
          width: 20%;
          max-width: 20%;
        }

        &:nth-child(5) {
          text-align: center;
          padding: 9px 12px;
          width: 10%;
          max-width: 10%;
        }

        .more-actions {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }

        &:hover {
          .more-actions {
            display: inline-table !important;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table !important;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.currency-conversion,
  .archived-conversion {
    overflow: auto;
    height: calc(100vh - 405px);

    &.table-select-item {
      height: calc(100vh - 460px) !important;
    }

    .mat-mdc-table {
      tr {

        th,
        td {
          &:nth-child(1) {
            @include minMaxWidth(60px);
            width: 1% !important;
          }

          &:nth-child(2),
          &:nth-child(3) {
            @include minMaxWidth(150px);
          }

          &:nth-child(4) {
            @include minMaxWidth(300px);
          }

          &:nth-child(5) {
            @include minMaxWidth(120px);
          }

          &:last-child {
            width: 100px;
            padding: 9px 12px;
            text-align: center;
          }
        }
      }
    }

    &.onboaring-table {
      height: calc(100vh - 500px) !important;

      .mat-mdc-table {
        margin-bottom: 1rem;
        border-radius: 8px;
        border: 1px solid $stock-hint;

        .mat-mdc-header-row {
          .mat-mdc-header-cell {
            &:nth-child(1) {
              min-width: 200px;
              max-width: 200px;
            }

            &:nth-child(2) {
              min-width: 200px;
              max-width: 200px;
            }

            &:nth-child(3) {
              min-width: 200px;
              max-width: 200px;
            }

            &:nth-child(4) {
              min-width: 200px;
              max-width: 200px;
            }

            &:nth-child(5) {
              min-width: 200px;
              max-width: 200px;
              text-align: center;
            }
          }
        }
      }
    }

    &.onboardingDataQuestionnaire-table {
      height: calc(100vh - 440px) !important;
    }

    &.workspace-links-table-responsive {

      th.mat-mdc-header-cell:first-of-type,
      td.mat-mdc-cell:first-of-type,
      td.mat-mdc-footer-cell:first-of-type {
        padding-left: 24px !important;
      }

      width: calc(100vw - 132px);
      height: calc(100vh - 360px);

      .mat-mdc-table {
        .mat-mdc-header-row {
          .mat-mdc-header-cell {
            &:nth-child(1) {
              min-width: 150px;
            }

            &:nth-child(2) {
              min-width: 150px;
            }

            &:nth-child(3) {
              min-width: 150px;
            }

            &:nth-child(4) {
              min-width: 150px;
            }

            &:nth-child(5) {
              min-width: 50px;
              max-width: 50px;
            }
          }
        }

        td {
          width: 12%;

          &:last-of-type {
            div {
              display: none !important;
            }
          }
        }
      }

      // Hover
      .mat-mdc-row {
        &:hover {
          background-color: $white-lilac !important;

          td.mat-mdc-cell {
            &:last-child {
              div {
                display: flex !important;
                justify-content: center;
                align-items: center;

                i {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 20px !important;
                  border-radius: 50%;
                  @include size(28px);

                  &:first-child {
                    &:hover {
                      background: $border-grey;
                    }
                  }

                  &:last-child {
                    &:hover {
                      background: $border-grey;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.workspace-files-table-responsive {

      th.mat-mdc-header-cell:first-of-type,
      td.mat-mdc-cell:first-of-type,
      td.mat-mdc-footer-cell:first-of-type {
        padding-left: 24px !important;
      }

      width: calc(100vw - 132px);
      height: calc(100vh - 360px);

      .mat-mdc-table {
        .mat-mdc-header-row {
          .mat-mdc-header-cell {
            &:nth-child(1) {
              min-width: 250px;
            }

            &:nth-child(2) {
              min-width: 200px;
            }

            &:nth-child(3) {
              min-width: 80px;
            }

            &:nth-child(4) {
              max-width: 50px;
            }
          }
        }

        tr {
          &.mat-mdc-row {
            td {
              &.mat-mdc-cell:last-of-type {
                button {
                  display: none;
                  font-size: 13px;
                  color: $grey-lighten-7 !important;
                  width: 28px;
                  height: 28px;
                  border-radius: 50px;
                  line-height: 0;

                  &:hover {
                    background: $border-grey;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }
            }
          }

          &:hover {
            background-color: $white-lilac !important;

            &.mat-mdc-row {
              td {
                &.mat-mdc-cell:last-of-type {
                  button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.archived-conversion {
    overflow: auto;
    height: calc(100vh - 395px);

    .mat-mdc-table {
      tr {

        th,
        td {
          &:nth-child(1) {
            @include minMaxWidth(200px);
          }
        }
      }
    }
  }

  &.rfp-proposal-client {
    height: fit-content;
    max-height: calc(100vh - 534px);

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 200px;
          }

          &:nth-child(2) {
            min-width: 160px;
            max-width: 160px;
          }

          &:nth-child(3) {
            min-width: 100px;
          }

          &:nth-child(4) {
            min-width: 80px;
          }

          &:nth-child(5) {
            min-width: 80px;
          }

          &:nth-child(6) {
            min-width: 80px;
          }

          &:nth-child(7) {
            min-width: 80px;
          }

          &:nth-child(8) {
            min-width: 80px;
          }

          &:nth-child(9) {
            min-width: 80px;
          }
        }
      }

      .mat-mdc-cell {
        &:nth-child(1) {
          text-align: start;
          // border-bottom-color: transparent !important;
        }
      }
    }

    tr {
      td {
        .more-actions {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }

        &:hover {
          .more-actions {
            display: inline-table !important;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table !important;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.rfp-proposal-shortList {
    height: calc(100vh - 442px);

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 200px;
          }

          &:nth-child(2) {
            min-width: 100px;
          }

          &:nth-child(3) {
            min-width: 100px;
          }

          &:nth-child(4) {
            min-width: 100px;
          }

          &:nth-child(5) {
            min-width: 150px;
          }
        }
      }

      .mat-mdc-cell {
        &:nth-child(1) {
          text-align: start;
        }
      }
    }
  }

  &.workspace-meetings {
    height: calc(100vh - 380px);

    .loader {
      height: calc(100vh - 380px);
    }

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 60px;
            max-width: 60px;
          }

          &:nth-child(2) {
            min-width: 150px;
            max-width: 150px;
          }

          &:nth-child(3) {
            min-width: 200px;
            max-width: 200px;
          }

          &:nth-child(4) {
            min-width: 200px;
            max-width: 200px;
          }

          &:nth-child(5) {
            min-width: 150px;
            max-width: 150px;
          }

          &:nth-child(6) {
            min-width: 150px;
            max-width: 150px;
          }

          &:nth-child(7) {
            min-width: 60px;
            max-width: 60px;
          }
        }
      }
    }
  }

  &.meetings-table-responsive {
    height: calc(100vh - 317px);

    &.table-select-item {
      height: calc(100vh - 371px);
    }

    .loader {
      height: calc(100vh - 317px);
    }

    .mat-mdc-table {
      tr {
        height: 57px !important;

        th,
        td {
          &:nth-child(1) {
            min-width: 200px;
            max-width: 200px;
          }

          &:nth-child(2) {
            min-width: 100px;
            max-width: 100px;
          }

          &:nth-child(3) {
            min-width: 100px;
            max-width: 100px;
          }

          &:nth-child(4) {
            min-width: 100px;
            max-width: 100px;
          }

          &:nth-child(5) {
            min-width: 200px;
            max-width: 200px;
          }

          &:nth-child(6) {
            min-width: 150px;
            max-width: 150px;
          }

          &:nth-child(7) {
            min-width: 120px;
            max-width: 120px;
          }

          &:nth-child(8) {
            min-width: 120px;
            max-width: 120px;
          }

          &:nth-child(9) {
            min-width: 150px;
            max-width: 150px;
          }

          &:nth-child(10) {
            min-width: 80px;
            max-width: 80px;
          }

          &:last-child {
            text-align: center;
          }

          .more-actions,
          .view-action {
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {

          .more-actions,
          .view-action {
            display: inline-table;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.dashboard-meetings-table-responsive {
    height: calc(100vh - 222px);

    &.table-select-item {
      height: calc(100vh - 278px);
    }

    .loader {
      height: calc(100vh - 222px);
    }

    .mat-mdc-table {
      tr {
        height: 40px !important;

        th,
        td {
          &:nth-child(1) {
            min-width: 150px;
            max-width: 150px;
          }

          &:nth-child(2) {
            min-width: 100px;
            max-width: 100px;
          }

          &:nth-child(3) {
            min-width: 100px;
            max-width: 100px;
          }

          &:nth-child(4) {
            min-width: 100px;
            max-width: 100px;
          }

          &:nth-child(5) {
            min-width: 200px;
            max-width: 200px;
          }

          &:nth-child(6) {
            min-width: 200px;
            max-width: 200px;
          }

          &:nth-child(7) {
            min-width: 120px;
            max-width: 120px;
          }

          &:nth-child(8) {
            min-width: 120px;
            max-width: 120px;
          }

          &:nth-child(9) {
            min-width: 150px;
            max-width: 150px;
          }

          &:nth-child(10) {
            width: 80px;
          }

          &:last-child {
            text-align: center;
          }

          .more-actions,
          .view-action {
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {

          .more-actions,
          .view-action {
            display: inline-table;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.contracts-table-responsive {
    height: calc(100vh - 317px);
  }

  &.contracts-main-table {
    height: calc(100vh - 202px);
  }

  &.contracts-main-table,
  &.contracts-table-responsive {
    .mat-mdc-table {
      tr {

        th,
        td {
          .more-actions {
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {
          .more-actions {
            display: inline-table;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.nda-term-table-responsive {
    height: fit-content;

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 100px;
          }

          &:nth-child(2) {
            min-width: 300px;
          }

          &:nth-child(3) {
            min-width: 100px;
          }

          &:nth-child(4) {
            min-width: 100px;
          }
        }
      }
    }
  }

  &.admin-consulting-firms-table-responsive {
    width: calc(100vw - 108px);

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 60px;
            max-width: 60px;
          }

          &:nth-child(2) {
            min-width: 300px;
            max-width: 300px;
          }

          &:nth-child(3) {
            min-width: 200px;
            max-width: 200px;
          }

          &:nth-child(4) {
            min-width: 100px;
            max-width: 100px;
          }

          &:nth-child(5) {
            min-width: 100px;
            max-width: 100px;
          }

          &:nth-child(6) {
            min-width: 100px;
            max-width: 100px;
          }
        }
      }
    }
  }

  &.suggested-firms-table {
    height: calc(100vh - 202px);
  }

  &.administrative-invoices {
    height: calc(100vh - 202px);

    tr {

      td,
      th {
        &:nth-child(1) {
          @include minMaxWidth(50px);
        }

        &:nth-child(2) {
          @include minMaxWidth(200px);
        }

        &:nth-child(3) {
          @include minMaxWidth(200px);
        }

        &:nth-child(4) {
          @include minMaxWidth(200px);
        }

        &:nth-child(5) {
          @include minMaxWidth(150px);
        }

        &:nth-child(6) {
          @include minMaxWidth(150px);
        }

        &:nth-child(7) {
          @include minMaxWidth(150px);
        }

        &:nth-child(8) {
          @include minMaxWidth(200px);
        }

        &:nth-child(9) {
          @include minMaxWidth(170px);
        }

        &:nth-child(10) {
          @include minMaxWidth(150px);
        }

        &:nth-child(11) {
          @include minMaxWidth(100px);
          text-align: center;
        }

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }
  }

  &.project-management-invoices-milestones {
    width: calc(100vw - 400px);
    height: calc(100vh - 383px);

    .mat-mdc-table {
      margin-bottom: 1rem;

      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 50px;
            max-width: 50px;
          }

          &:nth-child(2) {
            min-width: 150px;
            max-width: 150px;
          }

          &:nth-child(3) {
            min-width: 150px;
            max-width: 150px;
          }

          &:nth-child(4) {
            min-width: 150px;
            max-width: 150px;
          }

          &:nth-child(5) {
            min-width: 120px;
            max-width: 120px;
          }

          &:nth-child(6) {
            min-width: 180px;
            max-width: 180px;
          }

          &:nth-child(7) {
            min-width: 180px;
            max-width: 180px;
          }

          &:nth-child(8) {
            min-width: 100px;
            max-width: 100px;
          }

          &:nth-child(9) {
            min-width: 180px;
            max-width: 180px;
          }

          &:nth-child(10) {
            min-width: 180px;
            max-width: 180px;
          }

          &:nth-child(11) {
            min-width: 130px;
            max-width: 130px;
          }

          &:last-child(12) {
            width: 100px;
          }
        }
      }

      td {
        &:last-of-type {
          div {
            display: none;

            a {
              @include size(28px);
            }
          }
        }
      }
    }

    // Hover
    .mat-mdc-row {
      &:hover {
        background-color: $white-lilac !important;

        td.mat-mdc-cell {
          &:last-child {
            div {
              display: flex !important;

              a {
                text-decoration: none;

                i {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 20px !important;
                  border-radius: 50%;
                  @include size(28px);

                  &:hover {
                    background: $border-grey;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.admin-project-management-invoices-milestones {
    height: calc(100vh - 202px);

    .mat-mdc-table {
      margin-bottom: 1rem;

      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 50px;
            max-width: 50px;
          }

          &:nth-child(2) {
            min-width: 150px;
            max-width: 150px;
          }

          &:nth-child(3) {
            min-width: 150px;
            max-width: 150px;
          }

          &:nth-child(4) {
            min-width: 150px;
            max-width: 150px;
          }

          &:nth-child(5) {
            min-width: 120px;
            max-width: 120px;
          }

          &:nth-child(6) {
            min-width: 180px;
            max-width: 180px;
          }

          &:nth-child(7) {
            min-width: 180px;
            max-width: 180px;
          }

          &:last-child(12) {
            width: 100px;
          }
        }
      }

      td {
        &:last-of-type {
          div {
            display: none;

            a {
              @include size(28px);
            }
          }
        }
      }
    }

    // Hover
    .mat-mdc-row {
      &:hover {
        background-color: $white-lilac !important;

        td.mat-mdc-cell {
          &:last-child {
            div {
              display: flex !important;

              a {
                text-decoration: none;

                i {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 20px !important;
                  border-radius: 50%;
                  @include size(28px);

                  &:hover {
                    background: $border-grey;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.currency-table {
    .mat-mdc-table {
      margin-bottom: 1rem;

      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 150px;
            max-width: 150px;
          }

          &:nth-child(2) {
            min-width: 200px;
            max-width: 200px;
          }

          &:nth-child(3) {
            min-width: 150px;
            max-width: 150px;
          }

          &:nth-child(4) {
            min-width: 250px;
            max-width: 250px;
          }
        }
      }

      td {
        &.mat-mdc-cell {
          &:last-of-type {
            text-align: right !important;
          }
        }
      }
    }
  }

  &.superadmin-notification-content-templates {
    height: calc(100vh - 202px);

    tr {

      th,
      td {
        &:nth-child(1) {
          @include minMaxWidth(400px);
        }

        &:nth-child(2) {
          @include minMaxWidth(300px);
        }

        &:nth-child(3) {
          @include minMaxWidth(300px);
        }

        &:last-child {
          width: 100px;
          text-align: center;
          padding: 9px 12px;
        }

        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {
        .view-action {
          display: inline-table;
        }
      }
    }
  }

  &.risk-table-responsive {
    height: calc(100vh - 316px);

    &.with-pagination {
      height: calc(100vh - 362px);
    }

    tr {

      th,
      td {
        &:nth-child(1) {
          @include minMaxWidth(500px);
        }

        &:nth-child(2),
        &:nth-child(3) {
          @include minMaxWidth(200px);
        }

        &:last-child {
          text-align: center;
          width: 100px;
          padding: 9px 12px;
        }

        .more-actions {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {
        .more-actions {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }
  }

  &.rfi-team-table {
    height: fit-content;
    max-height: calc(100vh - 479px);
    tr {

      th,
      td {
        &:nth-child(1) {
          @include minMaxWidth(150px);
        }

        &:nth-child(2){
          @include minMaxWidth(100px);
        }
        &:nth-child(3) {
          @include minMaxWidth(80px);
        }

        &:last-child {
          text-align: center;
          width: 6%;
          padding: 9px 12px;
        }

        .more-actions {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {
        .more-actions {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }

    &.read-only {
      tr {

        th,
        td {
          &:nth-child(1) {
            @include minMaxWidth(150px);
          }

          &:nth-child(2){
            @include minMaxWidth(100px);
          }
          &:nth-child(3) {
            @include minMaxWidth(80px);
          }
          &:last-child {
            text-align: unset !important;
            width: unset !important;
            padding: unset !important;
          }
        }
      }
    }
  }

  &.client-briefing-table-responsive {
    height: calc(100vh - 410px);

    tr {

      th,
      td {
        &:nth-child(1) {
          @include minMaxWidth(200px);
        }

        &:nth-child(2) {
          @include minMaxWidth(200px);
        }

        &:nth-child(6) {
          @include minMaxWidth(100px);
        }

        &:nth-child(9),
        &:nth-child(7) {
          @include minMaxWidth(150px);
        }

        &:nth-child(3) {
          @include minMaxWidth(120px);
        }

        &:nth-child(4),
        &:nth-child(5) {
          @include minMaxWidth(100px);
          overflow: visible;
          button {
            white-space: nowrap;
          }
        }

        &:nth-child(8) {
          @include minMaxWidth(180px);
        }

        .more-actions {
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {
        .more-actions {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }
  }

  &.home-briefing-table-responsive {
    height: fit-content;

    .mat-mdc-table {
      border-radius: unset !important;
      border: unset !important;
    }

    tr {

      th,
      td {
        &:nth-child(1) {
          @include minMaxWidth(100px);
        }

        &:nth-child(2) {
          @include minMaxWidth(100px);
        }

        &:nth-child(6) {
          @include minMaxWidth(100px);
        }

        &:nth-child(9),
        &:nth-child(7) {
          @include minMaxWidth(150px);
        }

        &:nth-child(3) {
          @include minMaxWidth(80px);
        }

        &:nth-child(4),
        &:nth-child(5) {
          @include minMaxWidth(80px);
        }

        &:nth-child(8) {
          @include minMaxWidth(180px);
        }

        .more-actions {
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:last-of-type {
        td {
          &.mat-mdc-cell {
            border-bottom: none !important;
          }
        }
      }

      &:hover {
        .more-actions {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }
  }

  &.firm-briefing-table-responsive {
    height: calc(100vh - 370px);

    tr {

      th,
      td {
        &:nth-child(1) {
          @include minMaxWidth(200px);
        }

        &:nth-child(2) {
          @include minMaxWidth(200px);
        }

        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(9) {
          @include minMaxWidth(150px);
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          @include minMaxWidth(200px);
        }

        &:nth-child(8) {
          @include minMaxWidth(150px);
        }

        .more-actions {
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {
        .more-actions {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }
  }

  &.client-risk-table {
    width: calc(100vw - 440px);
    height: calc(100vh - 390px);

    .mat-mdc-table {
      tr {

        th,
        td {
          &:nth-child(1) {
            min-width: 2ric;
            max-width: 200px;
          }

          &:nth-child(2) {
            min-width: 120px;
            max-width: 120px;
          }

          &:nth-child(3) {
            min-width: 80px;
            max-width: 80px;
          }

          &:nth-child(4) {
            min-width: 80px;
            max-width: 80px;
          }

          &:nth-child(5) {
            min-width: 80px;
            max-width: 80px;
          }

          &:nth-child(6) {
            min-width: 120px;
            max-width: 120px;
          }

          &:nth-child(7) {
            min-width: 110px;
            max-width: 110px;
          }

          &:nth-child(8) {
            min-width: 110px;
            max-width: 110px;
          }

          .more-actions,
          .view-actions {
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {

          .more-actions,
          .view-actions {
            display: inline-table;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.legal-entities-table-responsive {
    height: calc(100vh - 327px);

    &.table-select-item {
      height: calc(100vh - 347px);
    }

    .loader {
      height: calc(100vh - 327px);
    }

    tr {

      th,
      td {

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          // @include minMaxWidth(180px !important);
          min-width: 180px;
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9) {
          // @include minMaxWidth(150px !important);
          min-width: 150px;
        }

        &:last-child {
          @include minMaxWidth(120px !important);
          text-align: center;
        }

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }
  }

  &.invoicing-payment-table {
    height: auto;
  }

  &.legal-entity-table {
    height: calc(100vh - 205px) !important;

    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 150px;
            max-width: 150px;
          }

          &:nth-child(2) {
            min-width: 180px;
            max-width: 200px;
          }

          &:nth-child(3) {
            min-width: 180px;
            max-width: 200px;
          }

          &:nth-child(4) {
            min-width: 120px;
            max-width: 120px;
          }

          &:nth-child(5) {
            min-width: 100px;
            max-width: 100px;
          }

          &:nth-child(6) {
            min-width: 160px;
            max-width: 160px;
          }

          &:nth-child(7) {
            min-width: 120px;
            max-width: 120px;
          }

          &:nth-child(8) {
            min-width: 150px;
            max-width: 150px;
          }

          &:nth-child(9) {
            min-width: 120px;
            max-width: 120px;
          }

          &:nth-child(10) {
            min-width: 100px;
            max-width: 100px;
          }
        }
      }
    }

    table {
      tr {
        td {
          .more-actions {
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {
          .more-actions {
            display: inline-table;
          }
        }

        .more-actions[aria-expanded='true'] {
          display: inline-table;
          background-color: $black-color-05;
        }
      }
    }
  }

  &.provider-legal-entity-table {
    height: calc(100vh - 330px) !important;

    tr {

      th,
      td {

        &:nth-child(1)
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(7) {
          @include minMaxWidth(100px)
        }

        &:nth-child(2),
        &:nth-child(6) {
          @include minMaxWidth(180px);
        }
        &:nth-child(3) {
          @include minMaxWidth(185px);
        }
        &:last-child {
          width: 100px;
          text-align: center;
        }

        .more-actions {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {
        .more-actions {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }
  }

  &.performance-document {
    width: 100% !important;

    tr {

      th,
      td {
        &:nth-child(1) {
          min-width: 300px;
        }

        &:nth-child(2) {
          min-width: 150px;
        }

        &:nth-child(3) {
          min-width: 150px;
        }

        &:nth-child(4) {
          min-width: 150px;
        }

        &:nth-child(5) {
          min-width: 100px;
        }


        &:last-child {
          padding: 9px 12px !important;
          width: 100px;
          text-align: center;
        }

        .more-actions {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {
        .more-actions {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }
  }

  &.performance-home-tab-document {
    width: 100% !important;
    overflow: auto;
    height: fit-content;

    .mat-mdc-table {
      border-collapse: collapse;
    }


    tr {

      th,
      td {
        &:nth-child(1) {
          min-width: 100px;
        }

        &:nth-child(2) {
          min-width: 100px;
        }

        &:nth-child(3) {
          min-width: 100px;
        }

        &:nth-child(4) {
          min-width: 100px;
        }

        &:nth-child(5) {
          min-width: 80px;
        }
      }
    }
  }

  &.linked-firm-table {
    height: calc(100vh - 202px);

    tr {

      th,
      td {
        &:nth-child(1) {
          min-width: 180px;
        }

        &:nth-child(2) {
          min-width: 120px;
        }

        &:nth-child(3) {
          min-width: 140px;
        }

        &:nth-child(4) {
          min-width: 200px;
        }

        &:nth-child(5) {
          min-width: 210px;
        }

        &:nth-child(6) {
          width: 125px;
        }

        &:nth-child(7) {
          width: 110px;
        }

        &:nth-child(8) {
          width: 90px;
        }

        &:nth-child(9) {
          width: 95px;
        }

        &:nth-child(10) {
          width: 175px;
        }

        &:nth-child(11) {
          width: 185px;
        }

        &:last-child {
          padding: 9px 12px !important;
          width: 100px;
          text-align: center;
        }

        .more-actions {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {
        .more-actions {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }
  }
}

.table-select-item {
  height: calc(100vh - 262px);
}

.companies-list-table-container {
  &.sourcing-selection-table {
    .mat-mdc-table {
      border: 1px solid $blue-darken-1;

      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &:nth-child(1) {
            min-width: 60px;
            max-width: 60px;
          }

          &:nth-child(2) {
            min-width: 220px;
            max-width: 220px;
          }

          &:nth-child(3) {
            min-width: 190px;
            max-width: 190px;
          }

          &:nth-child(4) {
            min-width: 90px;
            max-width: 90px;
          }

          &:nth-child(5) {
            min-width: 120px;
            max-width: 120px;
          }

          &:nth-child(6) {
            min-width: 80px;
            max-width: 80px;
          }

          &:nth-child(7) {
            min-width: 80px;
            max-width: 80px;
          }
        }
      }
    }
  }

  .mat-mdc-table {
    border: 1px solid $blue-darken-1;

    .mat-mdc-header-row {
      border-top: 1px solid $blue-darken-1;
      border-bottom: 1px solid $blue-darken-1;
      position: sticky;
      top: -1px;
      z-index: 1;

      .mat-mdc-header-cell {
        white-space: nowrap;

        &:nth-child(1) {
          min-width: 60px;
          max-width: 60px;
        }

        &:nth-child(2) {
          min-width: 170px;
          max-width: 240px;
        }

        &:nth-child(3) {
          min-width: 220px;
          max-width: 220px;
        }

        &:nth-child(4) {
          min-width: 100px;
          max-width: 100px;
        }

        &:nth-child(5) {
          min-width: 120px;
          max-width: 120px;
        }
      }
    }
  }
}

.suggest-table-responsive {
  height: calc(100vh - 202px);

  table {
    tr {
      .mat-mdc-cell {
        min-width: 190px;
      }

      &:hover {
        &.mat-mdc-row {
          td {
            background-color: $background-primary !important;
          }
        }
      }
    }
  }
}

/* table select option style here */
.table-select {
  padding: $spacer12 $spacer24;
  background-color: $dark-green-12;
  border-radius: 4px 4px 0 0;

  p {
    @include font(14px, 1, 600, $dark-green);
  }

  span {
    display: flex;
    align-items: center;
    @include font(14px, 1px, normal, $dark-green);
    cursor: pointer;
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes shake {

  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shake {

  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

/* country code section style here */
.country-code {
  .mat-mdc-select {
    padding-left: $spacer8 !important;
  }
}

/* Search and clear section style here */
.search-clear {
  .mat-mdc-form-field-wrapper {
    .mat-mdc-form-field-flex {
      border-bottom: $border-1 !important;
      padding: $spacer8 0 !important;
      border: none;
    }

    .mat-mdc-form-field-icon-prefix{
      .search-icon {
        i {
          @include font(18px, 1, normal, $text-grey-content);
        }
      }
    }

    .mat-mdc-form-field-icon-suffix{
      .close-icon {
        @include font(11px, 1, 400, $text-grey-content);
        display: flex;
        align-items: center;

        i {
          font-size: 13px;
          background: $text-grey-content;
          border-radius: $border-radius-circle;
          color: $white-primary;
          margin-left: $spacer16;
          cursor: pointer;
        }
      }
    }
  }
}

.no-found {
  text-align: center;
  padding: $spacer8;
  margin-top: $spacer12;
  background: $grey-lighten-3;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: $primary-color;
}

// radio button style here

// .radio-button {
//   .mat-radio-group {
//     .mat-radio-button {
//       .mat-radio-label-content {
//         @include font(14px, 21px, 400, $text-grey-content);
//       }
//     }

//     .mat-radio-checked {
//       .mat-radio-label-content {
//         @include font(14px, 21px, 400, $primary-color);
//       }
//     }

//     .mat-radio-disabled,
//     .mat-radio-disabled .mat-radio-label {
//       cursor: not-allowed !important;
//     }
//   }
// }

.question-options {
  .mat-radio-label-content {
    padding-left: 14px !important;
    @include font(14px, 21px, 300, $text-primary);
  }

  .mat-checkbox-label {
    padding-left: 10px !important;
    @include font(14px, 21px, 400, $text-grey-secondary-1);
  }
}

.add-details {
  .mat-form-field-flex {
    &:after {
      display: none !important;
    }
  }

  .mat-form-field-suffix {
    cursor: no-drop;
  }
}

// Phone Number with prefix phone code
.phone {
  .mat-mdc-form-field-wrapper {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        .mat-mdc-input-element {
          line-height: 15px !important;
          box-sizing: border-box;
        }
      }
    }
  }
}

/* Table filter style here */

.filter {
  min-width: 148px !important;

  .mat-form-field {
    border-radius: 6px !important;

    .mat-mdc-form-field-wrapper {
      .mat-mdc-form-field-infix {
        .mat-mdc-select {
          border-radius: 6px !important;

          .mat-form-field-label {
            line-height: 38px !important;
          }
        }
      }
    }
  }
}

/* Input type number style here */

.input-type {

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none !important;
    appearance: none;
    margin: 0;
  }
}

.input {
  input[type='number'] {
    -moz-apearance: textfield;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
  /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}

/* mat assets style here */
.mat-optgroup {
  .mat-option {
    display: none;

    &.active {
      display: flex !important;
      height: 2.4em !important;

      &:last-child {
        margin-bottom: $spacer8;
      }
    }
  }
}

.mat-optgroup-label {
  margin-bottom: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;

  .arrow {
    line-height: 0 !important;

    i {
      font-size: 20px;
    }
  }
}

.mat-checkbox {
  .mat-checkbox-layout {
    margin-bottom: 0 !important;
  }
}

/* Tag clouds style here */
.account-content {
  position: relative;

  .tag-clouds {
    overflow: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    max-height: 120px;

    &.border-line {
      border: $border-1;
      border-radius: $global-border-radius;
    }

    .flag-tooltip {
      display: inline-flex;

      img {
        margin-right: $spacer6;
        cursor: pointer;
      }

      .tool-tip {
        background: $text-primary;
        border-radius: $global-border-radius;
        @include font(12px, 19px, 700, $white-primary);
        padding: $spacer8;
        display: none;
        position: absolute;
        width: 130px;
        height: auto;
        z-index: 1111;
        border-radius: 8px;
        text-align: center;
        left: 8px;
        top: -32px;

        &:before {
          content: '';
          @include size(14px);
          background: $text-primary;
          z-index: 111;
          position: absolute;
          bottom: 20px;
          bottom: -4px;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          left: 12px;
          top: 25px;
        }
      }

      &:hover {
        .tool-tip {
          display: block !important;
        }
      }
    }
  }

  .mat-mdc-chip-list-wrapper {
    padding: 4px !important;
  }
}

/* Input readonly section style here */
.read-only {
  .mat-form-field {
    .mat-form-field-flex {
      background: $grey-lighten-3;
      border: solid 1px $grey-hint-20;

      .mat-input-element {
        color: $text-grey-content !important;
      }
    }
  }
}

/* Scroll width resize style here */
// .scroll::-webkit-scrollbar {
//   @include sizing(8px, 6px);
// }

// .scroll::-webkit-scrollbar-track {
//   border-radius: 10px;
//   background: transparent;
// }

// .scroll::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   background: $black-color-20;
// }

// .scroll::-webkit-scrollbar-thumb:hover {
//   background: rgba(0, 0, 0, 0.4);
// }

// .scroll::-webkit-scrollbar-thumb:active {
//   background: $black-color-90;
// }

/* mat select customize style here */
// TODO: remove the class from all over application from mat-option and remove the class here
/* .customize-options {
  line-height: 2em !important;
  height: 2em !important;
  margin: 10px;
  font-size: 13px;

  &.mat-active {
    background: $primary-color-05 !important;
    color: $black-color-87;
    border-radius: $border-radius-rounded !important;
  }

  &:hover {
    background: $primary-color-05 !important;
    color: $primary-color;
    border-radius: $border-radius-rounded !important;
  }

  &.mat-selected:not(.mat-option-disabled) {
    color: $primary-color!important;
  }
} */

/* Customize the checkbox style here */
.mat-radio-label-content {
  font-weight: 300;
  color: $text-grey-content;
}

/* Add details mat select style here */
.add-details {
  mat-form-field.mat-mdc-form-field-type-mat-input .mat-mdc-form-field-icon-suffix {
    margin-right: 4px !important;
  }
}

// Skeleton Loading
.skeleton {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  &.center {
    justify-content: center;
  }

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }
}

.skeleton-loader {
  color: transparent !important;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: skeletonLoader;
  animation-timing-function: linear;
  background: $skeleton-loader;
  background-image: -webkit-linear-gradient(right, $skeleton-loader 0%, $grey-lighten-4 20%, $skeleton-loader 40%, $skeleton-loader 100%) !important;
  position: relative;
  background-size: 800px 104px;

  .mat-mdc-text-field-wrapper {
    visibility: hidden;
  }

  &.small {
    width: 20%;
  }

  &.medium {
    width: 30%;
  }

  &.large {
    width: 50%;
  }
}

@keyframes skeletonLoader {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.skeleton-load {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: skeletonLoader;
  background: $skeleton-loader !important;
  background: -webkit-linear-gradient(right, $skeleton-loader 0%, $grey-lighten-4 20%, $skeleton-loader 40%, $skeleton-loader 100%) !important;
  position: relative;
  background-size: 800px 104px;

  .mat-mdc-form-field-wrapper {
    visibility: hidden;
  }

  &.small {
    width: 20%;
  }

  &.medium {
    width: 30%;
  }

  &.large {
    width: 50%;
  }
}

@keyframes skeletonLoad {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.skeleton-title {
  color: transparent;
  width: 50%;
  height: 10px;
  border-radius: $global-border-radius-1;
  margin: $spacer5 0;

  &.w5 {
    width: 5%;
  }

  &.w10 {
    width: 10%;
  }

  &.w15 {
    width: 15%;
  }

  &.w20 {
    width: 20%;
  }

  &.w30 {
    width: 30%;
  }

  &.w50 {
    width: 50%;
  }

  &.icon-loader {
    width: 40px;
    height: 40px;
    border-radius: 60px;
  }

  &.icon-loader-large {
    width: 60px;
    height: 60px;
    border-radius: 100px;
  }

  &.h30 {
    height: 30%;
  }

  &.h50 {
    height: 50%;
  }
}

.skeleton-chart {
  width: 100%;
  height: 160px;
  border-radius: $global-border-radius-1;
  margin: $spacer5 0;
}

.skeleton-chart-large {
  width: 100%;
  height: 230px;
  border-radius: $global-border-radius-1;
  margin: $spacer5 0;
}

.skeleton-chart-small {
  width: 100%;
  height: 50px;
  border-radius: $global-border-radius-1;
  margin: $spacer5 0;
}

.dropdown {
  .truncate {
    max-width: 135px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }

  .logout {
    min-width: 134px !important;
  }
}

/* Notification icon */
.mat-badge-content {
  min-width: 26px !important;
  min-height: 26px !important;
  @include font(11px !important, 16px !important, 600 !important, $white-primary !important);
  border-radius: 100px !important;
  box-sizing: border-box !important;
  background: $error-primary !important;
  right: -7px !important;
  top: -6px !important;
  border: 1px solid $white-primary;
  margin: 0 0 0 -22px !important;
  width: fit-content !important;
  padding: 4px !important;
}

.task-count-badge-content {
  .mat-badge-content {
    top: -10px !important;
    right: 0 !important;
    margin: 0 0 0 -4px !important;
  }
}

/* Nofification icon */
.notification-icon {
  .mat-button:hover {
    background: transparent !important;

    .mat-button-focus-overlay {
      opacity: 0;
    }
  }

  .mat-button-ripple.mat-ripple {
    opacity: 0 !important;
  }
}

.mat-mdc-menu-content {
  padding: 0 !important;

  .notification-header-section {
    display: flex;
    justify-content: space-between;
    padding: $spacer16;
    border-bottom: 1px solid $grey-lighten-6;
  }

  .notification-heading {
    @include font(16px, 21px, 800, $text-primary);
    font-family: 'Plus Jakarta Sans', sans-serif !important;
  }

  .content-section {
    @include sizing(270px, 266px);
    overflow: auto;

    .empty-notification {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-top: -8px;
      text-align: center;

      .empty-notification-drop {
        .notification-image {
          // @include size(45px);
          width: 200px;
          height: auto;
          margin: 0 auto;

          img {
            width: 100%;
            margin-left: $spacer8;
          }
        }
      }
    }

    .notification-dropdown {
      height: auto !important;
      padding: 0 $spacer8 0;

      &:first-child {
        padding-top: $spacer8 !important;
      }

      &:last-child {
        padding-bottom: $spacer8 !important;
      }

      :hover {
        border-radius: 6px;
        // background: $background-primary;

        .read-status {
          .notification-read-status-button {
            border-radius: 50%;
            border: 1px solid $stock-tertiary !important;

            .size-read-icon {
              @include size(8px);
              @include border-radius(50%);
              background: $secondary-color;
            }
          }
        }
      }

      .details {
        display: flex !important;
        padding: $spacer8;

        .icon {
          line-height: 1;

          i {
            font-size: 20px;
            color: $orange-color;
            @include size(24px);
            border-radius: $border-radius-rounded;
            text-align: center;
            margin-right: 12px;
            background: $orange-color-15;
            display: flex;
            width: 40px;
            height: 40px;
            justify-content: center;
            align-items: center;
            gap: 10px;

            &.blue-color {
              @include font(20px, 24px, normal, $blue);
              background: $blue-15;
            }

            &.green-color {
              @include font(20px, 24px, normal, $green);
              background: $green-15;
            }
          }
        }

        .content {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          flex: 1;

          .name {
            .inner-name {
              @include font(14px, 20px, 700, $text-primary);
            }

            .request {
              @include font(14px, 19px, 500, $text-secondary);
              margin-bottom: $spacer4;
              width: 136px;
            }
          }

          .hours {
            @include font(12px, 15px, 500, $text-tertiary);
            margin-top: $spacer4;
          }
        }
      }

      .read-status {
        .notification-read-status-button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 6px;
          min-width: fit-content;
          gap: 10px;
          height: 20px;
          width: 20px;
          background: transparent;
          border-radius: 50%;
          border: none !important;
        }

        .size-read-icon {
          @include size(8px);
          @include border-radius(50%);
          background: $secondary-color;
          display: inline-block;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .loader {
    text-align: center;
    background: $white-primary;
    padding: 0 $spacer12 $spacer12 $spacer12;
    width: 220px;

    .skeleton-table {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .skeleton-loader {
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: skeletonLoader;
        animation-timing-function: linear;
        background: $skeleton-loader !important;
        background-image: -webkit-linear-gradient(right, $skeleton-loader 0%, $grey-lighten-4 20%, $skeleton-loader 40%, $skeleton-loader 100%);
        position: relative;
        background-size: 800px 104px;
        width: 100% !important;

        &.small {
          width: 20%;
        }

        &.medium {
          width: 30%;
        }

        &.large {
          width: 50%;
        }
      }

      @keyframes skeletonLoader {
        0% {
          background-position: -468px 0;
        }

        100% {
          background-position: 468px 0;
        }
      }
    }
  }

  .viewall-section {
    text-align: center;
    border-top: 1px solid $grey-lighten-2;

    .footer {
      display: inline-block;

      &:focus {
        outline: none !important;
      }
    }
  }
}

.notification-checkbox {
  .mat-checkbox-label {
    font-size: $font-size;
    color: $text-grey-hint;
    margin-left: $spacer4;
  }
}

.mat-checkbox-label {
  font-family: 'Open Sans', sans-serif;
}

/* Progress bar customize */
.progress-bar-section {
  .mat-progress-bar {
    border-radius: $global-border-radius-1;
    height: 8px;
  }
}

/* Survey page design css here */
.question-options {
  td {
    .mat-mdc-radio-button {
      .mdc-form-field {
        label {
          margin-bottom: 0 !important;
          width: 20px !important;
        }
      }
    }
  }
}

/* Material Slider group style here */
.question-options {
  .mat-mdc-slider-horizontal {
    width: 50% !important;

    .mat-slider-ticks {
      background-image: none !important;
      z-index: -1;
    }

    .mat-slider-wrapper {
      &:after {
        height: none !important;
        border-left-width: 0 !important;
      }
    }
  }

  .mat-slider-disabled .mat-slider-thumb {
    border-width: 2px !important;
    transform: scale(0.7) !important;
  }

  .mat-accent .mat-slider-thumb {
    right: -15px !important;
  }

  .visible {
    .mat-slider-thumb-label {
      display: block !important;
    }
  }
}

.mat-slider-thumb-label {
  transform: rotate(45deg) !important;
  border-radius: $border-radius-circle $border-radius-circle 0 !important;
}

.mat-slider-thumb {
  transform: scale(0) !important;
}

.mat-slider-thumb-label-text {
  opacity: 1 !important;
}

/* OTP section style here */
.otp-section {
  .mat-form-field {
    font-size: 18px !important;

    .mat-form-field-label {
      line-height: 37px !important;
      padding-left: 20px !important;
      font-weight: 600 !important;
    }

    .mat-form-field-flex {
      &:after {
        display: none;

        &:hover {
          display: none !important;
        }
      }
    }
  }

  .mat-form-field-type-mat-input .mat-input-element {
    color: $primary-color;
    font-size: 18px !important;
    text-align: center;
  }
}

/* Mat select box flat design style here */
.flag {
  width: 20px !important;
  height: 15px !important;
}

/* mat icon hover style here */
.mat-icon {
  &.notification-model {
    height: 37px !important;
    width: 37px !important;
    line-height: 37px !important;

    &:hover {
      background: $black-color-05;
      border-radius: $border-radius-rounded !important;
    }
  }

  &.delete {
    color: $error-primary !important;
  }
}

/* Table responsive design style here */

@media only screen and (min-width: 640px) and (max-width: 1024px) {
  .dashboard {
    .dashboard-content-section {
      width: calc(100vw - 60px) !important;
      overflow: auto;
    }
  }

}

@media only screen and (min-width: 640px) {
  .dashboard {
    .dashboard-content-section {
      overflow: auto;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 640px) {
  .dashboard {
    .dashboard-content-section {
      width: calc(100vw - 60px) !important;
      overflow: auto;
    }
  }

}

/* Survey question section style here */

.survey-notes-2 {
  .mat-radio-checked {
    .mat-radio-label-content {
      color: $primary-color;
    }
  }
}

.question-options {
  .mat-checkbox-checked {
    .mat-checkbox-label {
      color: $primary-color;
    }
  }
}

*:focus {
  outline: 0 !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $primary-color!important;
}

/* Table responsive design style here */

@media only screen and (min-width: 640px) and (max-width: 1024) {
  .table-section {
    &.mat-mdc-table {
      width: 1160px !important;
    }
  }
}

@media only screen and (min-width: 640px) and (max-width: 1024px) {
  .dashboard {
    .dashboard-content-section {
      width: calc(100vw - 60px) !important;
      overflow: auto;
    }
  }

}

@media only screen and (min-width: 320px) and (max-width: 640px) {
  .dashboard {
    .dashboard-content-section {
      width: calc(100vw - 60px) !important;
      overflow: auto;
    }
  }

}

.modified-date-picker {
  &.mat-mdc-form-field-disabled {
    input:hover {
      cursor: not-allowed;
    }

    .mat-mdc-form-field-flex {
      border: $border-1 !important;
      background-color: transparent !important;
    }
  }

  .mat-input-element {
    color: $primary-color;
  }
}

.rfp-milestones-datepicker {
  .mat-mdc-form-field-flex {
    height: 36px;
  }
}

/* dashboard quick filter styles here */
.dashboard-quick-filter {
  padding: $spacer8 $spacer12;
  background-color: $white-primary;
  position: sticky;
  top: -24px;
  z-index: 111;

  .quick-filter-list {
    display: flex;

    a {
      margin-right: $spacer8;
      padding: $spacer8;
      @include font(13px, 18px, 400, $text-hint);
      border-radius: 6px;
      cursor: pointer;

      &:hover {
        color: $primary-color;
        text-decoration: none;
      }

      &.active {
        background-color: $primary-hint-4;
        font-weight: 600;
        color: $primary-color;
      }
    }
  }

  .date-range {
    @include font(13px, 18px, 500, $text-secondary);
  }

  &.active {
    box-shadow: 0 2px 4px $black-color-05;
  }
}

/* dashboard board box styles here */
.dashboard-box {
  background-color: $white-primary;
  border-radius: 6px;
  padding: 20px;

  &.reports {
    align-items: center;
    padding: 12px !important;
  }
}

.main-dashboard,
.client-satisfication,
.performace-section {
  .dashboard-detail {
    table {
      th {
        padding: 12px;
        background-color: $background-primary;
      }

      tr {
        border-bottom: 1px solid $grey-lighten-2-50;

        &:last-child {
          border-bottom: none;
        }

        td {
          padding: $spacer12;
        }

        .numeric-data {
          @include font(14px, 18px, 400, $text-grey-content);
          margin-right: $spacer8;
        }

        .numeric-data-box {
          @include font(12px, 15px, 600, $text-grey-secondary-1);
          margin-right: $spacer12;
          background-color: $text-grey-secondary-1-08;
          border-radius: $spacer20;
          @include size(24px);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .user-data {
          @include font(14px, 18px, 400, $black-color-85);
        }

        .activity-value-primary {
          @include font(14px, 18px, 600, $primary-color);
          min-width: 100px;
        }

        .answer-data {
          @include font(14px, 22px, 400, $black-color-85);
        }

        .dashboard-status {
          i {
            @include icon(11px, $white-primary);
            padding: 3px;
            background-color: $peak-green-08 !important;
            color: $peak-green !important;
            border-radius: $border-radius-rounded;
          }

          .status-fail {
            background-color: $red-08 !important;
            color: $red !important;
            transform: rotate(180deg);
          }
        }

        .client-percentage {
          @include font(12px, 15px, 400, $peak-green);
          background-color: $peak-green-10;
          padding: $spacer4 $spacer8;
          border-radius: $border-radius-rounded;
        }
      }
    }
  }

  .dashboard-reports {
    position: relative;
    min-height: 77px;
    max-height: 77px;

    .dashboard-reports-icon {
      i {
        border-radius: 8px !important;
        padding: 12px !important;

        @include icon(20px, $blue);
        background-color: $blue-15;
        border-radius: $border-radius-rounded;
      }
    }

    .dashboard-reports-data {
      p:first-child {
        @include font(13px, normal, 700, $text-primary);
      }

      p:last-child {
        @include font(13px, normal, 500, $text-tertiary);
      }
    }
  }

  .chart-axis-details {
    p {
      @include font(12px, 15px, 400, $text-grey-content);

      span {
        @include font(12px, 15px, 500, $text-primary);
      }
    }
  }

  .consulting-chart {
    min-height: 300px;
  }

  .quick-data-section-loader {
    min-height: 77px;
  }

  .performance-client-priority {
    .client-priority-legends-list {
      .client-priority-legends {
        .legend-profile {
          @include size(12px);
          border-radius: $border-radius-rounded;
        }

        .blue-legend {
          background-color: $blue-20;
          border: 1px solid $blue;
        }

        .red-legend {
          background-color: $red-20;
          border: 1px solid $red;
        }

        .yellow-legend {
          background-color: $yellow-20;
          border: 1px solid $yellow;
        }

        .green-legend {
          background-color: $dark-green-20;
          border: 1px solid $dark-green;
        }

        p {
          @include font(14px, 20px, 400, $text-grey-content);
        }
      }
    }
  }

  .project-performance-client-priority {
    .project-performance-legends-list {
      .project-performance-legends {
        .legend-profile {
          @include size(12px);
          border-radius: $border-radius-rounded;
        }

        .blue-legend {
          background-color: $blue-20;
          border: 1px solid $blue;
        }

        .red-legend {
          background-color: $red-20;
          border: 1px solid $red;
        }

        .yellow-legend {
          background-color: $yellow-20;
          border: 1px solid $yellow;
        }

        .green-legend {
          background-color: $dark-green-20;
          border: 1px solid $dark-green;
        }

        p {
          @include font(12px, 18px, 400, $text-grey-content);
        }
      }
    }
  }

  .score-card-dimension {
    min-height: 280px;

    .score-card-dimension-value {
      top: 20px;
      right: 40px;

      .score-card-title {
        @include font(12px, 15px, 400, $text-grey-content);
      }

      i {
        @include icon(16px, $dark-green);
      }
    }
  }

  .dimension-section {
    .score-card-dimension-value {
      top: 20px;
      right: 40px;

      .score-card-title {
        @include font(12px, 15px, 400, $text-grey-content);
      }

      i {
        @include icon(16px, $dark-green);
      }
    }
  }

  .score-card-nps {
    .score-card-nps-value {
      @include font(14px, 18px, 600, $dark-green);
      background-color: $dark-green-12;
      @include size(30px);
      border-radius: $border-radius-rounded;
    }
  }

  .dashboard-drivers {
    min-height: 280px !important;
    max-height: 300px !important;
  }

  .strength-section {
    min-height: 165px;
    max-height: 165px;
    overflow: auto !important;
  }

  .no-data-found {
    margin-top: 130px;

    img {
      width: 350px;
      height: auto;
    }

    .no-data-title {
      @include font(18px, 23px, 800, $text-primary);
    }

    .no-data-label {
      @include font(14px, 18px, 500, $text-grey-content);
    }
  }

  .no-data-found-background {
    background-color: $white-primary;
    padding: 24px 0;
    min-height: calc(100vh - 260px);

    img {
      width: 250px;
      height: auto;
    }

    .no-data-title {
      @include font(18px, 23px, 800, $text-primary);
    }

    .no-data-label {
      @include font(14px, 18px, 500, $text-grey-content);
    }
  }

  .dasboard-header-menu {
    position: absolute;
    left: 30px;
    top: -20px;

    &::before {
      content: '';
      @include size(14px);
      z-index: 111;
      position: absolute;
      bottom: 20px;
      bottom: -4px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      left: -8px;
      top: 25px;
    }

    .mat-mdc-menu-content {
      position: relative;
    }
  }

  .dashboard-box {
    .dashboard-box-title-icon {
      @include size(24px);
      border-radius: $border-radius-rounded;
      background-color: $red-05;

      &.large-icon {
        @include size(42px);
        background-color: $primary-color-05;

        i,
        .mat-icon {
          @include iconSize(16px !important);
        }
      }

      &.orange-icon {

        i,
        .mat-icon {
          @include icon(12px, $orange-color);
        }

        background-color: $orange-color-05 !important;
      }

      &.green-icon {

        i,
        .mat-icon {
          @include icon(12px, $green);
        }

        background-color: $green-05 !important;
      }

      &.peak-green-icon {

        i,
        .mat-icon {
          @include icon(12px, $peak-green);
        }

        background-color: $peak-green-05 !important;
      }

      &.primary-icon {

        i,
        .mat-icon {
          @include icon(12px, $primary-color);
        }

        background-color: $primary-color-05 !important;
      }

      &.red-icon {

        i,
        .mat-icon {
          @include icon(12px, $red);
        }

        background-color: $red-05 !important;
      }

      &.dark-yellow-icon {

        i,
        .mat-icon {
          @include icon(12px, $dark-yellow);
        }

        background-color: $dark-yellow-05 !important;
      }

      &.dark-red-icon {

        i,
        .mat-icon {
          @include icon(12px, $dark-red);
        }

        background-color: $dark-red-05 !important;
      }

      &.royal-blue-icon {

        i,
        .mat-icon {
          @include icon(12px, $royal-blue);
        }

        background-color: $royal-blue-05 !important;
      }

      &.dark-pink-icon {

        i,
        .mat-icon {
          @include icon(12px, $dark-pink);
        }

        background-color: $dark-pink-05 !important;
      }

      &.dark-blue-icon {

        i,
        .mat-icon {
          @include icon(12px, $dark-blue);
        }

        background-color: $dark-blue-05 !important;
      }

      &.black-icon {

        i,
        .mat-icon {
          @include icon(12px, $text-primary);
        }

        background-color: $black-color-05 !important;
      }

      &.blue-icon {

        i,
        .mat-icon {
          @include icon(12px, $blue);
        }

        background-color: $blue-05 !important;
      }
    }

    .reports-icon {

      i,
      .mat-icon {
        border-radius: 8px;
        padding: 12px;

        &.red-icon {
          @include icon(20px, $error-primary !important);
          background-color: $red-15 !important;
        }

        &.green-icon {
          @include icon(20px, $green);
          background-color: $green-15;
        }

        &.orange-icon {
          @include icon(20px, $orange-color);
          background-color: $orange-color-15;
        }

        &.blue-icon {
          @include icon(20px, $secondary-color);
          background-color: $secondary-hint-5;
        }

        &.yellow-icon {
          @include icon(20px, $warn-primary);
          background-color: $warn-secondary;
        }
      }
    }

    .dashboard-reports-icon {

      i,
      .mat-icon {
        &.red-icon {
          @include icon(20px, $red !important);
          background-color: $red-15 !important;
        }

        &.green-icon {
          @include icon(20px, $green);
          background-color: $green-15;
        }

        &.orange-icon {
          @include icon(20px, $orange-color);
          background-color: $orange-color-15;
        }

        &.primary-icon {
          @include icon(20px, $primary-color);
          background-color: $primary-color-15;
        }

        &.peak-green-icon {
          @include icon(20px, $peak-green);
          background-color: $peak-green-15;
        }

        &.dark-pink-icon {
          @include icon(20px, $dark-pink);
          background-color: $dark-pink-15;
        }

        &.blue-icon {
          @include icon(20px, $secondary-color);
          background-color: $secondary-hint-5;
        }

        &.yellow-icon {
          @include icon(20px, $warn-primary);
          background-color: $warn-secondary;
        }

        &.dark-yellow-icon {
          @include icon(20px, $dark-yellow);
          background-color: $dark-yellow-15;
        }
      }
    }
  }

  .no-data-key-drivers {
    p {
      @include font(14px, 18px, 400, $text-grey-content);
    }
  }

  .consulting-panel-legends-list {
    .consulting-panel-legends {
      .legend-profile {
        @include sizing($spacer20, $spacer10);
        background-color: $primary-color;
      }

      p {
        @include font(12px, 18px, 400, $text-grey-content);
      }

      .darken-blue-legend {
        background-color: $darken-blue;
      }

      .dark-yellow-legend {
        background-color: $dark-yellow;
      }

      .dark-green-legend {
        background-color: $dark-green;
      }

      .orange-legend {
        background-color: $orange-color;
      }

      .green-legend {
        background-color: $green-dark;
      }

      .red-legend {
        background-color: $red;
      }

      .pink-legend-color {
        background-color: $pink-legend-color;
      }
    }
  }
}

.cq-admin-dashboard {
  .cq-admin-dashboard-details {
    table {
      tr {
        border-bottom: 1px solid $grey-lighten-2-50;

        td {
          padding: $spacer12 0;
        }
      }
    }

    .user-data-secondary {
      @include font(14px, 18px, 400, $black-color-45);
    }

    .activity-value {
      @include font(20px, 24px, 600, $text-primary);
    }

    .cq-admin-dashboard-box {
      padding: $spacer16 $spacer10;
      border: 1px solid $grey-lighten-2-50;
      border-radius: $global-border-radius-1;
    }
  }
}

.performace-section-data {
  padding: 16px 24px;
  height: calc(100vh - 66px);
  // overflow: auto;
}

.team-work-detail {
  .dashboard-header {
    position: sticky;
    top: 0;
    background-color: $background-light-blue;
    z-index: 111;
  }

  .conpulse-performance-sub-navigation {
    position: sticky;
    top: 85px;
    background-color: $background-light-blue;
    z-index: 11;
  }
}

.dashboard-project-menu {
  background-color: $grey-darken-4;
  position: absolute;
  left: 52px;
  top: -40px;

  .mat-mdc-menu-content {
    position: relative;
    background-color: $grey-darken-4;

    .mat-mdc-menu-item {
      .mat-mdc-menu-item-text {
        color: $white-color-60;
        font-family: 'Open Sans', sans-serif;
      }

      &:hover {
        background-color: $white-color-21 !important;
        .mat-mdc-menu-item-text {
          color: $white-primary !important;
        }
      }

      &.active {
        .mat-mdc-menu-item-text {
          color: $white-primary !important;
        }
      }
    }
  }
}

/* Dashboard empty data styles heres */
.dashboard-empty-data {
  i {
    @include icon(24px, $black-color-36);
    padding: $spacer24;
    background-color: $grey-lighten-3;
    border-radius: $border-radius-rounded;
  }

  mat-icon {
    display: inline-table;
    padding: $spacer24;
    color: $black-color-36;
    background-color: $grey-lighten-3;
    border-radius: $border-radius-rounded;
  }

  p {
    @include font(14px, 18px, 400, $text-grey-line-65);
  }

  &.row-empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// SOW TAB STYLES HERE
.sow-mat-tab {
  .mat-mdc-tab-header {
    border: 1px solid $grey-lighten-18;
    border-radius: 4px;
    max-width: 340px;

    .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {
        .mat-mdc-tab-labels {
          margin: 4px 8px;

          .mat-mdc-tab-label-active {
            background: $secondary-color;
            border-radius: 2px;
            @include font(14px, 14px, 400, $white-primary !important);
            opacity: 1 !important;
          }
        }
      }
    }

    .mat-ink-bar {
      background-color: inherit !important;
    }
  }
}

.sow-member-btn {
  .mat-mdc-tab-body {
    padding-top: 0 !important;
  }
}

.conpulse-performance-navigation {
  ul {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    li {
      text-decoration: none;

      a {
        @include font(12px, normal, 500, $text-light);
        padding: $spacer4 0;

        &.performance-sub-navigation {
          @include font(12px, normal, 600, $text-primary);
          display: flex;
          align-items: center;
          padding: 0 $spacer6;
          border-radius: $spacer4;
          cursor: pointer;

          i {
            @include icon(16px, $text-primary);
          }

          &:hover {
            background-color: $black-color-05;
          }
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.conpulse-performance-sub-navigation {
  ul {
    display: flex;
    margin-bottom: 0;
    @include font(12px, normal, 500, $text-grey-content);

    li {
      text-decoration: none;

      a {
        @include font(12px, normal, 500, $text-grey-content);

        &.performance-navigation {
          font-family: 'Signika', sans-serif;

          &:hover {
            color: $primary-color-70;
          }
        }

        &.performance-sub-navigation {
          @include font(12px, normal, 500, $blue);

          &:hover {
            color: $blue !important;
          }
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

/* Team work details styles here */

.team-work-detail {
  .team-member-profile {
    width: 240px;
    position: fixed;
  }

  .team-member-profile-details {
    margin-left: 254px;
    flex: 1;
  }

  .team-members-data {
    label {
      @include font(12px, 15px, 400, $black-color-40);
      margin: 0;
    }

    i {
      @include icon(14px, $black-color-30);
      margin-right: $spacer4;
    }

    p {
      @include font(14px, 18px, 600, $text-primary);
    }
  }

  .conpulse-performance-sub-navigation {
    ul {
      display: flex;

      li {
        text-decoration: none;

        a {
          @include font(14px, 18px, 400, $text-grey-content);

          &.performance-navigation {
            @include font(14px, 18px, 400, $text-grey-content);

            &:hover {
              @include font(14px, 18px, 400, $primary-color-70);
            }
          }

          &.performance-sub-navigation {
            @include font(14px, 18px, 600, $primary-color);

            &:hover {
              @include font(14px, 18px, 600, $primary-color);
            }
          }

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.team-member-loader {
  .team-work-detail {
    .team-member-profile {
      width: 200px;
    }

    .team-member-profile-details {
      margin-left: 216px;
      flex: 1;
    }

    .team-members-data {
      label {
        @include font(12px, 15px, 400, $text-grey-content);
        margin: 0;
      }

      p {
        @include font(14px, 18px, 600, $text-primary);
      }
    }
  }
}

.dashboard-section {
  position: sticky;
  top: 0;
  z-index: 100000;

  .question-options {
    table {
      border-collapse: separate;
      border-spacing: 0 $spacer12;

      thead {
        .question-options-header {
          width: 90px;
          @include font(13px !important, 17px !important, 600 !important, $text-grey-secondary-1 !important);

          &:last-child {
            width: 106px;
            margin-right: $spacer12;
          }
        }
      }

      tbody {
        .custom-radio {
          width: 100%;
          height: 10px;

          td {
            @include font(14px, 21px, 400, $text-grey-secondary);
            border-bottom: 1px solid $text-grey-hint-20;
            border-top: 1px solid $text-grey-hint-20;
            padding: $spacer8 $spacer16;

            &.active {
              .custom-radio-button {
                background-color: $dark-green-80 !important;
                border: 1px solid $dark-green;
              }
            }

            &:first-child {
              border-left: 1px solid $text-grey-hint-20;

              .custom-radio-button {
                border-left: 50%;
              }
            }

            &:nth-child(2) {
              .custom-radio-button {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
              }
            }

            &:last-child {
              border-right: 1px solid $text-grey-hint-20;

              .custom-radio-button {
                margin-right: 12px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
              }
            }

            .survey-percentange {
              @include size(40px);
              border-radius: $spacer4;
              background-color: $grey-lighten-3;
              border: 1px solid $black-color-06;
              margin: 0 auto;

              p {
                @include font(14px, 21px, 400, $text-primary);
              }
            }
          }

          .custom-radio-button {
            border: 1px solid $black-color-06;
            background-color: $grey-lighten-3;
            height: 10px;

            &.custom-radio-first-child {
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            }

            &.custom-radio-last-child {
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
            }
          }
        }

        .custom-radio-percentage {
          width: 100%;
          height: 10px;

          td {
            @include font(14px, 21px, 400, $text-grey-secondary);
            border-bottom: 1px solid $text-grey-hint-20;
            border-top: 1px solid $text-grey-hint-20;
            padding: $spacer16;

            &.active {
              .custom-radio-button {
                background-color: $dark-green-80 !important;
                border: 1px solid $dark-green;
              }
            }

            &:first-child {
              border-left: 1px solid $text-grey-hint-20;

              .custom-radio-button {
                border-left: 50%;
              }
            }

            &:nth-child(3) {
              .custom-radio-button {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
              }
            }

            &:last-child {
              border-right: 1px solid $text-grey-hint-20;

              .custom-radio-button {
                margin-right: 12px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
              }
            }

            .survey-percentange {
              @include size(40px);
              border-radius: $spacer4;
              background-color: $grey-lighten-3;
              border: 1px solid $black-color-06;
              margin: 0 auto;

              p {
                @include font(14px, 21px, 400, $text-primary);
              }
            }
          }

          .custom-radio-button {
            border: 1px solid $black-color-07;
            background-color: $grey-lighten-3;
            height: 10px;

            &.custom-radio-first-child {
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            }

            &.custom-radio-last-child {
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
            }
          }
        }
      }
    }
  }

  .carrer {
    .question-section {
      .question-options {
        .question-options-header {
          table tr th {
            width: 120px !important;
          }
        }
      }

      table {
        thead {
          .question-options-header {
            width: 100px !important;
          }
        }
      }
    }
  }
}

.survey-notes-3 {
  .question-section {
    .survey-answer {
      overflow: auto;
      display: inline-block;
      flex-wrap: wrap;

      p {
        @include font(12px, 15px, 400, $primary-color);
        padding: $spacer5 $spacer12;
        background-color: $primary-color-10;
        border-radius: $border-radius-rounded;
        margin-right: $spacer6;
        margin-bottom: $spacer6;
        display: flex;
        align-items: center;
        white-space: pre-line;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }
  }
}

/* date picker module style here */

.date-picker-module {
  .md-drppicker {
    th {
      color: $text-tertiary;
    }

    td.active {
      background-color: $primary-color!important;
      color: $white-primary !important;
    }

    td.active :hover {
      background-color: $primary-color;
    }

    .buttons {
      .buttons_input {
        button {
          box-shadow: none;
          margin: $spacer10;
          padding: $spacer8 16px !important;
          border-radius: 4px;

          &:first-child {
            background-color: $white-primary;
            margin-right: $spacer8;
            @include font(13px, 18px, 400, $text-grey-secondary-1);

            &:hover {
              background-color: $red-10;
              color: $red;
              transition: background-color 0.5s ease;
            }
          }

          &:last-child {
            @include font(13px, 18px, 400, $white-primary);
            background-color: $primary-color;
          }
        }
      }
    }
  }
}

.date-picker-data {
  .ranges {
    padding: $spacer10 $spacer8;

    ul {
      width: 120px !important;

      li {
        margin-bottom: 6px;

        button {
          border-radius: 4px;

          &.active {
            background-color: $primary-color!important;
          }
        }
      }
    }
  }
}

.survey-answer-result {
  .question-options {
    .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-input-element {
      resize: none !important;
      color: $text-grey-secondary-1;
    }
  }

  .answer-empty {
    margin-right: $spacer14;
  }
}

.member-profile-status {
  .member-profile-status-list {
    position: relative;

    .tool-tip {
      background: $text-primary;
      border-radius: $global-border-radius;
      @include font(12px, 19px, 700, $white-primary);
      padding: $spacer8;
      display: none;
      position: absolute;
      top: 45px;
      width: 130px;
      height: auto;
      z-index: 1111;
      left: -48px;
      border-radius: 8px;
      text-align: center;

      &:before {
        content: '';
        @include size(12px);
        background: $text-primary;
        z-index: 111;
        position: absolute;
        top: -3px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        left: 58px;
      }

      .status {
        @include font(10px, 15px, 400, $grey-lighten-1);
      }
    }

    &:hover {
      .tool-tip {
        display: block;
      }
    }
  }
}

.tooltip-section {
  display: flex;
  align-items: center;
}

/*Mat-Drawer Container Styles here*/

.mat-drawer-container {
  background-color: transparent !important;
}

.subscription-header {
  .performace-section-data {
    height: calc(100vh - 131px);
    overflow: auto;
  }
}

.tootip-client-satisfaction {
  position: relative;
  display: inline-block;

  .tool-tip {
    background: $text-primary;
    border-radius: $global-border-radius;
    @include font(12px, 19px, 700, $white-primary);
    padding: $spacer8;
    display: none;
    position: absolute;
    top: -7px;
    width: 150px;
    height: auto;
    z-index: 1111;
    left: -160px;
    border-radius: 8px;
    text-align: center;

    &:before {
      content: '';
      width: 14px;
      height: 14px;
      background: $text-primary;
      z-index: 111;
      position: absolute;
      bottom: 10px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      right: -4px;
    }

    .status {
      @include font(10px, 15px, 400, $grey-lighten-1);
    }
  }

  &:hover {

    .tool-tip,
    .tool-tip-email {
      display: block;
    }
  }
}

.latest-actions-table,
.latest-opportunities-table {
  thead tr th {
    font-size: 13px !important;
    font-weight: 600;

    &:nth-child(1) {
      min-width: 32px;
    }

    &:nth-child(2) {
      min-width: 200px;
    }

    &:nth-child(3) {
      min-width: 200px;
    }
  }

  tbody tr td {
    p {
      font-size: 13px !important;
    }

    &:nth-child(1) {
      p {
        font-weight: 600 !important;
      }

      min-width: 32px;
    }

    &:nth-child(2) {
      p {
        font-weight: 400 !important;
      }

      min-width: 200px;
    }

    &:nth-child(3) {
      min-width: 200px;
    }
  }
}

.latest-notifications-table {
  thead tr th {
    font-size: 13px !important;
    font-weight: 600;

    &:nth-child(1) {
      min-width: 300px;
    }

    &:nth-child(2) {
      min-width: 125px;
    }

    &:nth-child(3) {
      min-width: 100px;
      text-align: center;
    }
  }

  tbody tr {
    height: 54px;

    td {
      p {
        font-size: 13px !important;
      }

      &:nth-child(1) {
        p {
          font-weight: 600 !important;
        }

        min-width: 300px;
      }

      &:nth-child(2) {
        p {
          font-weight: 400 !important;
        }

        min-width: 125px;
      }

      &:nth-child(3) {
        min-width: 100px;
        text-align: center;

        .view-icon {
          color: $text-hint;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }
    }

    &:hover {
      .view-icon {
        display: inline-table !important;
      }
    }
  }
}

.latest-unread-count {
  width: 100%;
  thead tr th {
    font-size: 13px;
    font-weight: 600;

    &:nth-child(1) {
      min-width: 300px !important;
    }

    &:nth-child(2) {
      min-width: 300px !important;
    }

    &:nth-child(3) {
      min-width: 200px !important;
    }

    &:nth-child(4) {
      min-width: 200px !important;
    }

    &:nth-child(5) {
      min-width: 200px !important;
    }

    &:nth-child(6) {
      min-width: 300px !important;
    }

  }

  tbody tr td {
    p {
      font-size: 13px !important;
    }

    &:nth-child(1) {
      min-width: 400px !important;

      p {
        font-weight: 600 !important;
      }
    }

    &:nth-child(2) {
      min-width: 400px !important;

      p {
        font-weight: 600 !important;
      }
    }

    &:nth-child(3) {
      min-width: 200px !important;

      p {
        font-weight: 600 !important;
      }
    }

    &:nth-child(4) {
      min-width: 200px !important;

      p {
        font-weight: 600 !important;
      }
    }

    &:nth-child(5) {
      min-width: 200px !important;

      p {
        font-weight: 600 !important;
      }
    }

    &:nth-child(6) {
      min-width: 300px !important;

      p {
        font-weight: 600 !important;
      }
    }
  }

}

.latest-demands-table,
.latest-projects-table,
.latest-contracts-table {
  thead tr th {
    font-size: 13px;
    font-weight: 600;

    &:nth-child(1) {
      min-width: 200px;
    }

    &:nth-child(2) {
      min-width: 150px;
    }

    &:nth-child(3) {
      min-width: 175px;
    }
  }

  tbody tr td {
    p {
      font-size: 13px !important;
    }

    &:nth-child(1) {
      min-width: 200px;

      p {
        font-weight: 600 !important;
      }
    }

    &:nth-child(2) {
      min-width: 150px;

      p {
        color: $text-tertiary !important;
        font-weight: 400 !important;
      }
    }

    &:nth-child(3) {
      min-width: 175px;

      p {
        color: $text-tertiary !important;
        font-weight: 400 !important;
      }
    }
  }
}


.latest-demands {
  thead tr th {
    font-size: 13px;
    font-weight: 600;

    &:nth-child(1) {
      min-width: 300px;
    }

    &:nth-child(2) {
      min-width: 200px;
    }

    &:nth-child(3) {
      min-width: 175px;
    }
  }

  tbody tr td {
    p {
      font-size: 13px !important;
    }

    &:nth-child(1) {
      min-width: 200px;

      p {
        font-weight: 600 !important;
      }
    }

    &:nth-child(2) {
      min-width: 150px;

      p {
        color: $text-tertiary !important;
        font-weight: 400 !important;
      }
    }

    &:nth-child(3) {
      min-width: 175px;

      p {
        color: $text-tertiary !important;
        font-weight: 400 !important;
      }
    }
  }
}

.latest-overdue-actions-table {
  thead tr th {
    font-size: 13px;
    font-weight: 600;

    &:nth-child(1) {
      min-width: 200px;
    }

    &:nth-child(2) {
      min-width: 150px;
    }

    &:nth-child(3) {
      min-width: 100px;
    }
  }

  tbody tr td {
    p {
      font-size: 13px !important;
    }

    &:nth-child(1) {
      min-width: 250px;

      p {
        font-weight: 600 !important;
      }
    }

    &:nth-child(2) {
      min-width: 100px;

      p {
        color: $text-tertiary !important;
        font-weight: 400 !important;
      }
    }

    &:nth-child(3) {
      min-width: 150px;

      p {
        color: $text-tertiary !important;
        font-weight: 400 !important;
      }
    }
  }
}

.dashboard-latest-message {
  thead tr th {
    font-size: 13px;
    font-weight: 600;

    &:nth-child(1) {
      min-width: 170px;
    }

    &:nth-child(2) {
      min-width: 170px;
    }

    &:last-of-type {
      min-width: 210px;
    }
  }

  tbody tr td {
    p {
      font-size: 13px !important;
    }

    &:nth-child(1) {
      min-width: 170px;

      p {
        font-weight: 600 !important;
      }
    }

    &:nth-child(2) {
      min-width: 170px;

      p {
        color: $text-tertiary !important;
        font-weight: 400 !important;
      }
    }

    &:last-of-type {
      min-width: 210px;
    }
  }
}

.dashboard-latest-meeting {
  thead tr th {
    font-size: 13px;
    font-weight: 600;

    &:nth-child(1) {
      min-width: 120px;
    }

    &:nth-child(2) {
      min-width: 120px;
    }

    &:nth-child(3) {
      min-width: 100px;
    }

    &:last-of-type {
      min-width: 200px;
    }
  }

  tbody tr td {
    p {
      font-size: 13px !important;
    }

    &:nth-child(1) {
      min-width: 120px;

      p {
        font-weight: 600 !important;
      }
    }

    &:nth-child(2) {
      min-width: 120px;

      p {
        color: $text-tertiary !important;
        font-weight: 400 !important;
      }
    }

    &:nth-child(3) {
      min-width: 100px;

      p {
        color: $text-tertiary !important;
        font-weight: 400 !important;
      }
    }

    &:last-of-type {
      min-width: 200px;
    }
  }
}

.latest-surveys-table {
  thead tr th {
    font-weight: 600;
    font-size: 13px;

    &:nth-child(2) {
      max-width: 400px;
    }
  }

  tbody tr td {
    &:nth-child(1) {
      p {
        font-size: 13px !important;
        font-weight: 600 !important;
        line-height: 20px;
      }
    }

    &:nth-child(2) {
      max-width: 400px !important;

      p {
        text-overflow: ellipsis;
        font-size: 13px !important;
        font-weight: 600 !important;
        line-height: 20px;
      }
    }

    &:nth-child(4) {
      p {
        font-size: 13px !important;
        font-weight: 400 !important;
        line-height: 20px;
      }
    }

    &:nth-child(5) {
      p {
        font-size: 13px !important;
        font-weight: 400 !important;
        line-height: 20px;
      }
    }

    &:nth-child(6) {
      p {
        font-size: 13px !important;
        font-weight: 400 !important;
        line-height: 20px;
      }
    }
  }
}

.view-by-dimension-select {
  min-width: 150px;
}

.view-profile {
  height: calc(38vh - 72px) !important;
}

.border-radius {
  &-6 {
    border-radius: 6px;
  }

  &-8 {
    border-radius: 8px;
  }
}

.view-project-section-heading {
  font-size: 18px;
  font-weight: 700;
}

.view-project-section-content {
  height: calc(100vh - 192px);
  overflow-y: auto;
}

.display-grid {
  display: grid;
}

.dot {
  background-color: $text-grey-content;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  display: inline-block;
  top: 50%;
  margin-top: auto;
  margin-bottom: auto;
}

i.material-icons {
  font-family: 'Material Icons' !important;

  &.mat-20 {
    font-size: 20px;
    width: 20px;
    height: 20px;
  }

  &.material-icons-outlined {
    font-family: 'Material Symbols Outlined' !important;
  }
}

mat-icon {
  overflow: unset !important;
}

.text-diff {
  ins {
    color: $green !important;
    background-color: $green-05 !important;
    text-decoration: none !important;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $green !important;
    }
  }

  del {
    color: $red !important;
    background-color: $red-05 !important;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $red !important;
    }
  }
}

div.validation-chip {
  padding: 0px 12px;
  height: 36px;
  border-radius: 6px !important;

  span {
    font-size: 14px;
    line-height: 14px;
  }

  &.done {
    color: $green !important;
    background-color: $green-05 !important;
  }

  &.pending {
    color: $primary-color!important;
    background-color: $primary-color-20 !important;
  }
}

// sow tab group and project management

.sow-team-border {
  border-top: 1px solid var(--grey-lighten-8);
  margin-top: 12px;
}

/* Text Truncate Styles */

.secondline-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.threeline-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// toolbar position

.ql-toolbar {
  &.ql-snow {
    text-align: left;
  }
}

// Project Managements Change Table Styles Here
.project-management-changes {
  height: fit-content;
  margin-bottom: 36px;
}

.project-management-change {
  height: calc(100vh - 300px) !important;
}

.status-chip {
  padding: $spacer4 $spacer10;
  border-radius: 6px;
  text-align: center;

  &.status-info {
    color: $blue !important;
    background-color: $blue-05 !important;
  }

  &.status-success {
    color: $green !important;
    background-color: $green-15 !important;
  }

  &.status-warn {
    color: $primary-color!important;
    background-color: $primary-color-15 !important;
  }

  &.status-danger {
    color: $red !important;
    background-color: $red-15 !important;
  }

  &.status-open {
    color: $secondary-color !important;
    background-color: $background-color !important;
  }
}

// company overview  textarea
.rfp-quill-editor {
  .ql-container {
    .ql-editor {
      max-width: 686px;
    }
  }
}

.secondary-title {
  font-size: 13px;
  color: $grey-lighten-7;
}

//Common - Quill Styles
.ql-container.ql-snow.ql-disabled {
  background: $grey-darken-13;
  pointer-events: none;
}

// teams tab design
.team-table-container {
  margin-top: 24px;

  .child-container {
    border: 1px solid $grey-lighten-19;
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
  }

  h4 {
    font-size: 14px;
    font-weight: 700;
  }
}

.common-heading {
  font-size: 18px;
  font-weight: 700px;
}

// quill image styles here

.text-diff {
  p {
    img {
      max-width: 100%;
    }
  }
}

.f-16 {
  font-size: 16px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-24 {
  font-size: 24px !important;
}

.f-20 {
  font-size: 20px !important;
}

.background-grey {
  background-color: $grey-lighten-3 !important;
}

// comment badge
.comment-badge {
  width: 33px;
  height: 6px;
  color: var(--text-grey-content);
  font-size: 15px;
}

// Comment text and number of comments in span
.comment {
  height: 28px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  background: $white-lilac;
  margin-left: 10px;

  p {
    color: $text-tertiary;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  span {
    display: flex;
    width: 18px;
    height: 18px;
    padding: 3px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 300px;
    background: $primary-color;
    color: $white-primary;
    font-weight: 600;
  }
}

.badge-container {
  .mat-badge-medium.mat-badge-above .mat-badge-content {
    top: unset;
    right: unset;
  }
}

.youtube-dialog-box {
  .mat-mdc-dialog-container {
    background: transparent;
    box-shadow: none;
  }
}

.quill-text {
  p {
    img {
      max-width: 500px !important;
      // width: 100%;
      // height: 400px;
      object-fit: contain;
    }
  }
}

/* mat select customize style here */
.customize-company-options {
  line-height: 2em !important;
  margin: 10px;
  height: fit-content !important;
  font-size: 13px;

  &.mat-active,
  &:hover {
    background: $grey-lighten-5 !important;
    @include border-radius(4px);
  }
}

.round-dot {
  @include size(4px);
  @include border-radius(50%);
  background-color: $grey-lighten-7;
}

.border-bottom-grey {
  border-bottom: 1px solid $grey-lighten-6;
}

// .firm-content {
//   .mat-radio-button {
//     &.mat-primary {
//       &.mat-radio-checked {
//         .mat-radio-outer-circle {
//           border-color: $primary-color!important;
//         }

//         .mat-radio-label-content {
//           color: $primary-color;
//         }
//       }

//       .mat-radio-outer-circle {
//         border-color: $black-primary !important;
//       }

//       .mat-radio-label-content {
//         color: $black-primary ;
//       }

//       .mat-radio-inner-circle {
//         background-color: $primary-color;
//       }

//       .mat-ripple-element {
//         background-color: $primary-color;
//       }
//     }

//     &.mat-radio-disabled {
//       .mat-radio-label-content {
//         color: $black-primary ;
//       }

//       .mat-radio-outer-circle {
//         border-color: $black-primary !important;
//       }
//     }
//   }
// }

.asterix--after:after {
  content: '*';
  color: red;
}

.asterix--before:before {
  content: '*';
  color: red;
}

// table data design

// data design

.primary-data {
  font-size: 14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.secondary-data {
  color: $text-tertiary;
  font-size: 14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.text-right {
  text-align: right !important;
}

// msa quill editor

.msa-quill-height {
  quill-editor {
    .ql-container {
      .ql-editor {
        height: 250px;
        overflow: auto;
        color: $grey-lighten-7;
      }
    }
  }
}

.workflow-dialog-container {
  .mat-mdc-dialog-container {
    border-radius: 10px !important;
  }
}

.table-container {
  table {
    .mat-mdc-header-row {
      height: 54px;
    }

    th {
      &.mat-mdc-header-cell:first-of-type {
        padding-left: 16px;
      }

      background-color: $background-primary !important;
      //border-right: 1px solid $white-02;
      color: $text-primary !important;
      font-size: 13px !important;
      font-weight: 600 !important;
      text-transform: uppercase;
    }

    td {
      &.mat-mdc-cell:first-of-type {
        padding-left: 16px;
      }

      height: 48px !important;

      &.mat-mdc-cell {
        border-bottom-color: $stock-hint !important;
      }
    }

    .add-button {
      display: flex;
      font-size: 13px;
      color: $secondary-color;
      align-items: center;
      font-weight: 600;

      i {
        margin-right: 5px;
      }
    }

    .text-weight-600 {
      font-weight: 600;
    }

    .option-button {
      font-size: 13px;
      color: $grey-lighten-7;
      font-weight: 600;
    }
  }
}

// primary button

.primary-btn {
  display: flex;
  height: 40px;
  padding: 7px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 5px !important;
  background: $primary-color !important;
  font-size: 13px;
  font-weight: 600;
  color: $white-primary !important;

  &:hover {
    background-color: $primary-color !important;
    color: $white-primary !important;
  }
}

// sourcing selection table

.selection-Table {
  .table-select-item {
    .mat-mdc-table {
      tr {
        .mat-mdc-row {
          height: 48px;
        }
      }

      td {
        &.mat-mdc-cell {
          &:first-of-type {
            text-align: left !important;
            padding-left: 12px;
          }

          &:last-child {
            i {
              display: none;
              justify-content: center;
              margin: 0 !important;
            }
          }

          &:last-of-type {
            text-align: center;
          }
        }
      }

      .mat-mdc-row {
        &:hover {
          background-color: $white-lilac !important;

          td.mat-mdc-cell {
            &:last-child {
              i {
                font-size: 20px;
                display: flex !important;
                color: $error-primary;
              }
            }
          }
        }
      }
    }
  }
}

.rfp-invitation-firm-tabel {
  .mat-mdc-table {
    tr {
      td {
        &.mat-mdc-cell {
          border-bottom-color: transparent !important;
        }
      }
    }
  }
}

.workspace-common-table {
  .mat-mdc-table {
    border: 1px solid $stock-hint !important;
    overflow: hidden !important;
    border-radius: 10px !important;
    border-spacing: 0;
    border-collapse: separate;

    td {
      &:last-of-type {
        width: 12%;
      }
    }
  }

  // Hover
  table {
    tr {
      td {

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none !important;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table !important;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table !important;
        background-color: $black-color-05;
      }
    }
  }
}

// Legal Entries
.panel-legal-entities {
  height: calc(100vh - 202px);

  .mat-mdc-table {
    .mat-mdc-header-row {
      .mat-mdc-header-cell {
        &:last-of-type {
          text-align: center;
        }
      }
    }

    td {
      &:last-of-type {
        width: 100px;

        div {
          display: none !important;

          a {
            @include size(28px);
          }
        }
      }
    }

    // Hover
    .mat-mdc-row {
      &:hover {
        background-color: $white-lilac !important;

        td.mat-mdc-cell {
          &:last-child {
            div {
              display: flex !important;
              justify-content: flex-start;
              align-items: center;

              i {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px !important;
                border-radius: 50%;
                @include size(28px);

                &:hover {
                  background: $border-grey;
                }
              }
            }
          }
        }
      }
    }
  }
}

.settings-content-section {
  .admin-setup-setting {
    margin: 0 !important;
    padding: 0 20px 40px;
  }

  .account-setup {
    margin: 0 !important;
  }

  .account-step-2 {
    width: 100%;

    .table-section {
      &.country-table-responsive {
        width: 100% !important;
        height: auto;
        max-height: calc(100vh - 450px);
      }

      button {
        margin-right: 0 !important;
      }
    }

    .setting-table-content {
      position: sticky;
      top: 0;
      padding: 20px 0 16px;
      z-index: 110;
      background: $white-primary;
      margin-bottom: 0 !important;

      button {
        margin-right: 0 !important;
      }
    }
  }

  .mat-mdc-tab-group {
    .mat-mdc-tab-label-container {
      padding: 0 10px;

      .mat-mdc-tab-list {
        .mat-mdc-tab-labels {
          .mat-mdc-tab-label {
            padding: 11px 8px !important;
            width: fit-content;
            max-width: fit-content !important;
            min-width: fit-content !important;
          }
        }
      }
    }
  }

  .mat-tab-link-container {
    padding: 0 10px;
  }
}

.template-editor-heading {
  h5 {
    color: $text-light;
    font-size: 15px;
    font-weight: 400;
  }
}

.file-upload-size {
  img {
    @include size(32px);
  }
}

.layout-quill-container {
  max-width: 800px;
}

table {
  th {
    .sort {
      img {
        display: none;
      }
    }

    &:hover {
      .sort {
        img {
          display: block;
        }
      }
    }
  }
}

.project-meeting-tab {
  .table-section.workspace-meetings {
    height: calc(100vh - 480px) !important;
  }
}

.workspace-tasks-tab {
  .workspace-common-table {
    padding: 20px !important;
  }
}

.key-document-tab {
  .table-section {
    height: calc(100vh - 424px) !important;
  }

  .table-with-white-bg {
    max-height: calc(100vh - 530px) !important;
    height: auto !important;
  }
}

.performace-section .no-data-found-background {
  min-height: calc(100vh - 430px) !important;
}

.workspace-common-table {
  &.workspace-links-table-responsive {
    height: calc(100vh - 448px) !important;
  }
}

.content-section.workspace-tasks-tab {
  .table-section {
    height: calc(100vh - 430px) !important;
  }
}

// Todo Added This Css Because used css is not working

.bottom-stick {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  background: $white-primary;
}

.billing-table-responsive {
  height: calc(100vh - 344px);

  &.nodata-table {
    height: calc(100vh - 296px);
  }

  tr {

    td,
    th {
      &:nth-child(1) {
        min-width: 120px;
      }

      &:nth-child(2) {
        min-width: 150px;
      }

      &:nth-child(3) {
        min-width: 150px;
      }

      &:nth-child(4) {
        min-width: 150px;
      }

      &:nth-child(5) {
        min-width: 120px;
      }

      &:nth-child(6) {
        min-width: 100px;
      }

      &:nth-child(7) {
        min-width: 100px;
      }

      &:nth-child(8) {
        min-width: 100px;
      }

      &:nth-child(9) {
        min-width: 100px;
      }

      &:nth-child(10) {
        min-width: 100px;
        max-width: 100px;
      }

      &:nth-child(11) {
        min-width: 50px;
        max-width: 50px;
      }

      &:nth-child(12) {
        min-width: 50px;
        max-width: 50px;
      }

      &:last-of-type {
        text-align: center;
      }

      .more-actions,
      .view-action {
        color: $grey-lighten-7;
        display: none;

        &:hover {
          background-color: $black-color-05;
        }
      }
    }

    &:hover {

      .more-actions,
      .view-action {
        display: inline-table;
      }
    }

    .more-actions[aria-expanded='true'] {
      display: inline-table;
      background-color: $black-color-05;
    }
  }
}

.entity-billing-table-responsive {
  height: calc(100vh - 325px);

  .mat-mdc-table {
    tr {

      td,
      th {
        &:nth-child(1) {
          min-width: 120px;
        }

        &:nth-child(2) {
          min-width: 150px;
        }

        &:nth-child(3) {
          min-width: 150px;
        }

        &:nth-child(4) {
          min-width: 150px;
        }

        &:nth-child(5) {
          min-width: 150px;
        }

        &:nth-child(6) {
          min-width: 150px;
        }

        &:nth-child(7) {
          min-width: 150px;
        }

        &:nth-child(8) {
          min-width: 50px;
          max-width: 50px;
        }

        &:nth-child(9) {
          min-width: 50px;
          max-width: 50px;
        }

        &:nth-child(10) {
          min-width: 50px;
          max-width: 50px;
        }

        .more-actions,
        .view-actions {
          color: $grey-lighten-7 !important;
          display: none;
        }
      }

      &:hover {

        .more-actions,
        .view-actions {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table !important;
        background-color: $black-color-05;
      }
    }
  }
}

.admin-entity-billing-table-responsive {
  height: calc(100vh - 421px);

  tr {

    td,
    th {
      &:nth-child(1) {
        min-width: 120px;
      }

      &:nth-child(2) {
        min-width: 150px;
      }

      &:nth-child(3) {
        min-width: 150px;
      }

      &:nth-child(4) {
        min-width: 150px;
      }

      &:nth-child(5) {
        min-width: 150px;
      }

      &:nth-child(6) {
        min-width: 150px;
      }

      &:nth-child(7) {
        min-width: 150px;
      }

      &:nth-child(8) {
        min-width: 100px;
        max-width: 100px;
      }

      &:nth-child(9) {
        min-width: 100px;
        max-width: 100px;
      }

      &:nth-child(10) {
        min-width: 50px;
        max-width: 50px;
      }

      .more-actions,
      .view-actions,
      .view-action {
        color: $grey-lighten-7;
        display: none;
      }
    }

    &:hover {

      .more-actions,
      .view-actions,
      .view-action {
        display: inline-table;
      }
    }

    .more-actions[aria-expanded='true'] {
      display: inline-table !important;
      background-color: $black-color-05;
    }
  }
}

.no-image-profile {
  border-radius: $border-radius-circle;
  display: inline-block;
  text-align: center;

  &.small {
    @include size(20px);
    line-height: 20px;
  }

  &.orange {
    background: $orange-color-20;
    color: $orange-color;
  }
}

.client-list {
  .legal-entities {
    height: calc(100vh - 202px);

    table {
      tr {

        td,
        th {
          &:last-child {
            padding: 9px 12px;
            width: 140px;
            text-align: center;
          }

          .view-action {
            color: $grey-lighten-7;
            display: none;

            &:hover {
              background-color: $black-color-05;
            }
          }
        }

        &:hover {
          td {
            .view-action {
              display: inline-table;
            }
          }
        }
      }
    }
  }
}

//Todo: CSS
.reject-icon {
  .material-icons {
    width: 28px;
    height: 28px;
    background-color: $error-hint !important;
    border-radius: 50px;
    @include font(16px, 28px, normal, $error-primary);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    .material-icons {
      background-color: $error-tertiary !important;
    }
  }
}

//Todo: CSS
.approve-icon {
  .material-icons {
    width: 28px;
    height: 28px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @include font(16px, 28px, normal, $success-primary);
    background-color: $success-tertiary !important;
    border-radius: 50px;
  }

  &:hover {
    .material-icons {
      background-color: $success-secondary !important;
    }
  }
}

.status {
  &.agreed {
    @include font(13px, 20px, 500, $success-primary);
  }

  &.not-agreed {
    @include font(13px, 20px, 500, $error-light);
  }

  &.pending,
  &.in-progress {
    @include font(14px, 20px, 600, $warn-primary);
  }

  &.requested,
  &.rejected,
  &.due {
    @include font(14px, 20px, 600, $error-primary);
  }

  &.approved,
  &.active,
  &.paid {
    @include font(14px, 20px, 600, $success-primary);
  }
}

.term-diff-main {
  .term-input {
    width: 50% !important;
  }
}

.no-data-comments {
  height: calc(100vh - 140px);
}

.mat-drawer {
  &.comment-drawer {
    overflow: unset !important;

    .mat-drawer-inner-container {
      overflow: unset !important;
    }
  }
}

@media only screen and (min-width: 1600px) {
  .table-section {
    &.sourcing-user-firm {
      table {
        tr {

          th,
          td {
            &:nth-child(1) {
              min-width: 600px !important;
            }

            &:nth-child(2) {
              min-width: 500px !important;
            }

            &:nth-child(3) {
              min-width: 500px !important;
            }
          }
        }
      }
    }
  }
}

.decoration-none {
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

::ng-deep .quill-text {
  ul {
    list-style-type: none;
    padding-left: revert !important;

    li {
      &::before {
        content: '\2022';
        margin-left: -18px;
      }
    }
  }

  li {
    &::before {
      display: inline-block;
      white-space: nowrap;
      width: 1.2em;
    }
  }
}

.text-underline:hover {
  text-decoration: underline;
}

.read-only-container {
  padding: 4px 10px;
  border-radius: 30px;
  border: 1px solid $stock-hint;
  background: $background-primary;
  display: flex;
  align-items: center;
  justify-content: center;
}

.read-only-text {
  font-weight: 700;
  font-style: normal;
  font-size: 13px;
  color: $text-secondary
}

caption {
  caption-side: top !important;
  display: table-caption;
  text-align: center;
}

.locked-container {
  padding: 6px 8px;
  border-radius: 30px;
  background: $background-dark;
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    font-weight: 600;
    font-size: 13px;
    color: $text-secondary
  }
}


.rfi-step-tab-container {
  height: calc(100vh - 310px);
  overflow: auto;
  padding: 20px;
}

mat-icon.menu-delete-icon-btn {
  color: $error-primary !important;
}

.file-actions {
  display: flex;
  align-items: center;
}
