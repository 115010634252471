@import '../includes';

/* Material Menu style overwrite here */
.mat-mdc-menu-panel {
  border-radius: 6px !important;
  border: 1px solid $bg-light-white;
  background: $white-primary;
  box-shadow: 10px 0px 30px 0px $black-color-05, 1px 1px 5px 0px $black-color-05 !important;
  min-width: 140px !important;
  min-height: unset !important;

  .mat-mdc-menu-content {
    padding: 4px !important;

    .mat-mdc-menu-item {
      --mat-menu-item-spacing: 8px;
      border-radius: 4px;
      padding: 8px 6px;
      height: 40px;
      display: flex;
      align-items: center;
      min-height: 40px;

      .mat-mdc-menu-item-text {
        @include font(14px, 20px, 500, $text-tertiary);
        display: flex;
        align-items: center;
        font-family: 'Open Sans', sans-serif;

        .material-icons {
          margin-right: 8px;
        }
      }

      .mat-icon {
        color: $text-hint;
        font-size: 20px;
        width: 20px;
        height: 20px;
        // margin-right: 8px;
      }

      &.delete-action,
      &.stop-action,
      &.menu-delete-icon-btn {
        color: $error-primary;
        .mat-icon, .mat-mdc-menu-item-text {
          color: inherit;
          .material-icons {
            color: inherit;
          }
        }
      }

      &:hover {
        background-color: $bg-light-white;
      }
    }

    &:focus {
      outline: none !important;
    }
  }

  &.popover {
    min-width: unset !important;
    margin-top: 0.5rem;
    overflow: visible;

    &::after {
      width: 0;
      height: 0;
      content: '';
      top: -0.5rem;
      left: 0.75rem;
      position: absolute;
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      border-bottom: 0.5rem solid;
      border-color: transparent transparent rgba(0, 0, 0, 0.12);
    }

    .mat-mdc-menu-content {
      padding: 0 !important;
      border: 0;

      .mat-mdc-card {
        padding: 1rem;

        .mat-mdc-card-content {
          padding: 0;
          @include font(14px, 20px, 400, $text-primary);
        }
      }
    }
  }
}

// Notification Mat Menu Styles Here
.notification-conatiner {
  width: 480px !important;
  max-width: 480px !important;
  padding: 0 !important;

  .mat-mdc-menu-content {
    .content-section {
      width: 100% !important;
      height: 366px !important;
    }
    .viewall-section {
      height: 50px;
      .footer {
        @include font(13px, normal, 700, $secondary-color);
      }
    }
  }

  .request {
    width: 100% !important;
  }
}

.emoji-menu {
  max-width: fit-content !important;
  padding: 0;
}

.mat-mdc-menu-panel {
  &.chip-more-vert {
    padding: 6px;
    border-radius: 4px;
    box-shadow: 0px 4px 12px 0px $black-opacity-10;
    .mat-mdc-menu-item {
      border-radius: 4px;
      &:hover {
        background-color: $grey-lighten-5;
      }
    }
  }
}
