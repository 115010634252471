/* ==============================
   CHECKOUT SPECIFIC COLORS
---------------------------------*/
:root {
  /* ==============================
     OLD COLORS
     TODO:- Check the usage and remove
  ---------------------------------*/
  --secondary-color-04: rgba(103, 0, 51, 0.04);
  --background-color: #f9f2f5;
  --background-light-blue: #f2f6f9;
  --bg-light-white: #F7FAFC;
  --bg-reddish-orange: #fff1e6;
  --text-grey-primary-1: #03011e;
  --text-grey-primary-2: #434850;
  --text-grey-secondary: rgb(66, 66, 66);
  --text-grey-secondary-50: rgba(66, 66, 66, 0.5);
  --text-grey-secondary-1: rgb(74, 74, 74);
  --text-grey-secondary-1-08: rgba(74, 74, 74, 0.08);
  --text-grey-hint: rgb(155, 155, 155);
  --text-grey-hint-20: rgba(155, 155, 155, 0.2);
  --text-grey-content: rgb(127, 127, 127);
  --text-grey-content-10: rgba(127, 127, 127, 0.1);
  --text-grey-line: rgb(187, 186, 186);
  --text-grey-line-65: rgba(187, 186, 186, 0.65);
  --text-grey-progress: #838383;
  --grey-darken-1: #333333;
  --grey-darken-2: #666666;
  --grey-darken-4: #18162d;
  --grey-darken-5: #22293d;
  --grey-darken-7: #e1e1e163;
  --grey-darken-8: #06172c;
  --grey-lighten-2: #e2e2e2;
  --grey-lighten-4: #e9ebee;
  --grey-lighten-8: #e5e5e5;
  --grey-lighten-11: #d9d9d9;
  --grey-lighten-12: #b9babd;
  --grey-lighten-13: #5b5f66;
  --grey-lighten-2-60: rgba(226, 226, 226, 0.6);
  --grey-lighten-2-50: rgba(226, 226, 226, 0.5);
  --grey-lighten-2-16: rgba(226, 226, 226, 0.16);
  --grey-lighten-2-05: rgba(226, 226, 226, 0.05);
  --grey-lighten-6-01: rgba(215, 215, 215, 0.01);
  --skeleton-loader: #f6f7f9;
  --dividerGrey: rgba(0, 0, 0, 0.3);
  --black-opacity-10: rgba(0, 0, 0, 0.1);
  --dividerPurple: rgba(100, 28, 255, 0.19);
  --red: rgb(224, 0, 52);
  --red-40: rgba(224, 0, 52, 0.4);
  --red-20: rgba(224, 0, 52, 0.2);
  --red-16: rgba(224, 0, 52, 0.16);
  --red-15: rgba(224, 0, 52, 0.15);
  --red-14: rgba(224, 0, 52, 0.14);
  --red-10: rgba(224, 0, 52, 0.1);
  --red-08: rgba(224, 0, 52, 0.08);
  --red-05: rgba(224, 0, 52, 0.05);
  --red-lighten1: rgb(255, 166, 160);
  --red-lighten2: rgb(255, 240, 240);
  --red-lighten3: rgba(224, 0, 52, 0.08);
  --red-lighten5: rgba(224, 0, 52, 0.2);
  --red-lighten6: rgba(255,119,0,0.2);
  --green-lighten-bg: rgba(224, 245, 236, 1);
  --green: rgb(0, 173, 98);
  --green-50: rgba(0, 173, 98, 0.5);
  --green-40: rgba(0, 173, 98, 0.4);
  --green-20: rgba(0, 175, 98, 0.2);
  --green-16: rgba(0, 173, 98, 0.16);
  --green-15: rgba(0, 173, 98, 0.15);
  --green-10: rgba(0, 173, 98, 0.1);
  --green-05: rgba(0, 173, 98, 0.05);
  --green-04: rgba(0, 175, 98, 0.04);
  --green-03: rgba(0, 175, 98, 1);
  --green-lighten1: #7ed321;
  --green-lighten4:rgba(0, 175, 99, 0.2);
  --green-lighten2: rgb(188, 238, 185);
  --green-lighten2-40: rgba(188, 238, 185, 0.4);
  --green-lighten3: rgba(0, 175, 99, 0.15);
  --green-dark: rgba(0, 175, 99, 100);
  --blue: rgb(10, 161, 251);
  --blue-40: rgba(10, 161, 251, 0.4);
  --blue-20: rgba(10, 161, 251, 0.2);
  --blue-16: rgba(10, 161, 251, 0.16);
  --blue-15: rgba(10, 161, 251, 0.15);
  --blue-05: rgba(10, 161, 251, 0.05);
  --blue-12: rgba(10, 161, 251, 0.12);
  --blue-01: rgba(10, 161, 251, 1);
  --blue-lighten1: #9cddfa;
  --blue-lighten2: #e3f2fd;
  --blue-lighten3: rgba(255, 119, 0, 0.15);
  --blue-bg-light: #F0FAFB;
  --warn-color: rgb(252, 87, 94);
  --warn-color-50: rgba(252, 87, 94, 0.5);
  --warning-color-dark: #fc854a;
  --pink-color: #fd73a1;
  --yellow: rgb(255, 194, 51);
  --yellow-20: rgba(255, 194, 51, 0.2);
  --pink-legend-color: rgba(224, 0, 52, 0.2);
  --dark-green: rgb(0, 171, 187);
  --dark-green-80: rgb(0, 171, 187, 0.8);
  --dark-green-20: rgb(0, 171, 187, 0.2);
  --dark-green-16: rgb(0, 171, 187, 0.16);
  --dark-green-14: rgb(0, 171, 187, 0.14);
  --dark-green-12: rgb(0, 171, 187, 0.12);
  --dark-green-10: rgb(0, 171, 187, 0.1);
  --dark-green-lighten: rgba(0, 171, 187, 0.06);
  --orange-color: rgb(255, 119, 0);
  --orange-color-40: rgba(255, 119, 0, 0.4);
  --orange-color-20: rgba(255, 119, 0, 0.2);
  --orange-color-16: rgba(255, 119, 0, 0.16);
  --orange-color-15: rgba(255, 119, 0, 0.15);
  --orange-color-14: rgba(255, 119, 0, 0.14);
  --orange-color-10: rgba(255, 119, 0, 0.1);
  --orange-color-05: rgba(255, 119, 0, 0.05);
  --light-green: #4cd964;
  --light-green-12: rgba(0, 175, 99, 0.12);
  --peak-green: rgb(0, 127, 139);
  --peak-green-15: rgba(0, 127, 139, 0.15);
  --peak-green-10: rgba(0, 127, 139, 0.1);
  --peak-green-08: rgba(0, 127, 139, 0.08);
  --peak-green-05: rgba(0, 127, 139, 0.05);
  --dark-pink: rgb(189, 16, 224);
  --dark-pink-15: rgba(189, 16, 224, 0.15);
  --dark-pink-05: rgba(189, 16, 224, 0.05);
  --dark-yellow: rgb(255, 188, 0);
  --dark-yellow-15: rgba(255, 188, 0, 0.15);
  --dark-yellow-05: rgba(255, 188, 0, 0.05);
  --dark-red: rgb(208, 2, 27);
  --dark-red-05: rgba(208, 2, 27, 0.05);
  --royal-blue: rgb(0, 19, 255);
  --royal-blue-05: rgba(0, 19, 255, 0.05);
  --dark-blue: rgb(0, 82, 130);
  --dark-blue-05: rgba(0, 82, 130, 0.05);
  --darken-blue: #0066a1;
  --border-grey: #e3e3e3;
  --light-grey-colors: #aaa;
  /*===============================
    Social Colors
  ----------------------------------*/
  --fb-color: #3b5998;
  --tw-color: #55acee;
  --gplus-color: #dd4b39;
  --yt-color: #cd201f;
  --li-color: #0082ca;
  --pin-color: #c61118;
  --ins-color: #3f729b;
  --git-color: #4183c4;
  --comm-color: #30cfc0;
  --vk-color: #4c75a3;
  --drib-color: #c32361;
  --error-red: rgba(224, 0, 52, 0.12);
  --note-bg-yellow: #fffae5;
  --matrix-chart-orange: #ff9233;
  --matrix-chart-red: #fa003a;
  --matrix-chart-red-shad: #ff551ff0;
  --matrix-chart-green: #007242;
  --matrix-chart-green-shad: #838e17d6;
  /* ==============================
    NEW DESIGN v2 COLORS
    Primary
  ---------------------------------*/
  --primary-color: #FF7700;
  --primary-color-03: #FF770008;
  --primary-color-05: #FF77000D;
  --primary-color-06: #FF77000F;
  --primary-color-10: #FF77001A;
  --primary-color-12: #FF77001F;
  --primary-color-15: #FF770026;
  --primary-color-16: #FF770029;
  --primary-color-20: #FF770033;
  --primary-color-40: #FF770066;
  --primary-color-70: #FF7700B3;
  --primary-light: #FF9233;
  --primary-hint-3: #FFF1E6;
  --primary-hint-4: #fff7f0;
  /* ==============================
    NEW DESIGN v2 COLORS
    Secondary
  ---------------------------------*/
  --secondary-color: #00ABBB;
  --secondary-color-1: #00abbb1a;
  --secondary-dark-color: #008996;
  --secondary-darker-color: #006770;
  --secondary-light-color: #2EBBC9;
  --secondary-hint-2: #CDF5F8;
  --secondary-hint-3: #ECF9FA;
  --secondary-hint-4: #F8FCFD;
  --secondary-hint-5: #f0fafb;
  /* ==============================
    NEW DESIGN v2 COLORS
    Text colors
  ---------------------------------*/
  --text-primary: #0C121D;
  --text-secondary: #3D414A;
  --text-tertiary: #61656C;
  --text-warn: #E88B00;
  --text-light: #AAACB0;
  --text-hint: #85888E;
  --text-success: #00AF62;
  --text-error: #E00034;
  --text-grey-primary-3: #11161f;
  --text-grey-primary-4: #424242;
  /* ==============================
    NEW DESIGN v2 COLORS
    Background colors
  ---------------------------------*/
  --background-darker: #D5DDE2;
  --background-dark: #E6EBEE;
  --background-primary: #F2F7FA;
  /* ==============================
    NEW DESIGN v2 COLORS
    Stock colors
  ---------------------------------*/
  --stock-primary: #C2C4C6;
  --stock-secondary: #CED0D2;
  --stock-tertiary: #DBDBDD;
  --stock-hint: #E7E7E8;
  /* ==============================
    NEW DESIGN v2 COLORS
    Error colors
  ---------------------------------*/
  --error-primary: #E00034;
  --error-secondary: #FF003B;
  --error-tertiary: #FBE0E7;
  --error-dark: #C7002E;
  --error-hint: #FDF0F3;
  --error-primary-01: #E000341F;
  --error-light: #FA003A;
  --error-secondary-01: #ff1a0030;
  /* ==============================
    NEW DESIGN v2 COLORS
    Success colors
  ---------------------------------*/
  --success-primary: #00AF62;
  --success-secondary: #E0F5EC;
  --success-tertiary: #F0FAF6;
  --success-dark: #008F52;
  --success-primary-1: #00c851;
  --success-dark-1: #00af621f;
  --success-dark-2: #00af621a;
  --success-lighten: #8bf18ba6;
  /* ==============================
    NEW DESIGN v2 COLORS
    Warn colors
  ---------------------------------*/
  --warn-primary: #E88B00;
  --warn-secondary: #FFFAE5;
  --warn-tertiary: #BD7100;
  --warning-color-lighten: #ffcc00;
  --warning-color-lighten-1: #ffcc0033;
  --warn-secondary-2: #fff2d9;
  /* ==============================
    NEW DESIGN v2 COLORS
    Other colors
  ---------------------------------*/
  --white-primary: #FFFFFF;
  --white-color-76: rgba(255, 255, 255, 0.76);
  --white-color-73: rgba(255, 255, 255, 0.73);
  --white-color-60: rgba(255, 255, 255, 0.6);
  --white-color-56: rgba(255, 255, 255, 0.56);
  --white-color-21: rgba(255, 255, 255, 0.21);
  --white-color-20: rgba(255, 255, 255, 0.2);
  --white-color-17: rgba(255, 255, 255, 0.17);
  --white-lilac: #f6f6f6;
  --white-lavender: #e8e8e9;
  --white-hint-1: #D3D6DB;
  --black-primary: #000000;
  --black-color-90: rgba(0, 0, 0, 0.9);
  --black-color-87: rgba(0, 0, 0, 0.87);
  --black-color-85: rgba(0, 0, 0, 0.85);
  --black-color-80: rgba(0, 0, 0, 0.8);
  --black-color-45: rgba(0, 0, 0, 0.45);
  --black-color-40: rgba(0, 0, 0, 0.4);
  --black-color-36: rgba(0, 0, 0, 0.36);
  --black-color-30: rgba(0, 0, 0, 0.3);
  --black-color-24: rgba(0, 0, 0, 0.24);
  --black-color-21: rgba(0, 0, 0, 0.21);
  --black-color-20: rgba(0, 0, 0, 0.2);
  --black-color-16: rgba(0, 0, 0, 0.16);
  --black-color-14: rgba(0, 0, 0, 0.14);
  --black-color-15: rgba(0, 0, 0, 0.15);
  --black-color-12: rgba(0, 0, 0, 0.12);
  --black-color-10: rgba(0, 0, 0, 0.1);
  --black-color-60: rgba(0, 0, 0, 0.6);
  --black-color-65: rgba(0, 0, 0, 0.65);
  --black-color-07: rgba(0, 0, 0, 0.07);
  --black-color-06: rgba(0, 0, 0, 0.06);
  --black-color-05: rgba(0, 0, 0, 0.05);
  --black-color-03: rgba(0, 0, 0, 0.03);
  --black-color-54: rgba(0, 0, 0, 0.54);
  --grey-color-51: rgba(225, 225, 225, 0.51);
  --grey-lighten-1: #cccccc;
  --grey-lighten-3: #f7f7f7;
  --grey-lighten-5: #f0f0f0;
  --grey-lighten-7: #7f7f7f;
  --grey-lighten-6: #d7d7d7;
  --grey-lighten-9: #8a8d92;
  --grey-lighten-14: #f9f9f9;
  --grey-lighten-15: #fafafa;
  --grey-lighten-16: #cdd1d5;
  --grey-lighten-17: #f8f8f8;
  --grey-lighten-18: #d8d8d8;
  --grey-lighten-19: #eeeff0;
  --grey-darken-3: #999999;
  --grey-darken-6: #e1e1e1;
  --grey-darken-9: #a1a3a7;
  --grey-darken-10: #595959;
  --grey-darken-11: #72767c;
  --grey-darken-12: #cac9c9bf;
  --grey-darken-13: #cccccc4d;
  --grey-hint-20: #9b9b9b4d;
  --grey-hint-10: #9b9b9b1a;
  --blue-lighten5: #007883;
  --blue-lighten-10: #00abbb0f;
  --blue-color-fb: #0aa1fb;
  --blue-darken-1: #00457f1a;
  --blue-darken-2: #0990e0;
  --blue-darken-3: #11161fb3;
  --blue-darken-4: #11161fb3;
  --blue-lighten-2: #e0f5f7;
  --dark-green-63: #00af63;
  --dark-green-631f: #00af631f;
  --green-darken-1: #25b09b;
  --green-lighten-bg: #eaf8f3;
  --red-lighten-bg: #fbf0f3;
  --yellow-lighten-1: #ebd997;
  --orange-lighten-01: #cf2c170d;
  --bg-note-color: #FEFBE7;
  --notice-bg-color: #ffcc001a;
  --notice-color: #f6be2c33;
  --note-border-color: #fceec1;
  --note-bg-color-1: #fefae6;
  --lavender-tag: #8D73FF;
  --lavender-tag-bg: #A894FF1F;
}

.client-app {
  --primary-color: #FF7700;
  --primary-color-03: #FF770008;
  --primary-color-05: #FF77000D;
  --primary-color-06: #FF77000F;
  --primary-color-10: #FF77001A;
  --primary-color-12: #FF77001F;
  --primary-color-15: #FF770026;
  --primary-color-16: #FF770029;
  --primary-color-20: #FF770033;
  --primary-color-40: #FF770066;
  --primary-color-70: #FF7700B3;
  --primary-light: #FF9233;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.mat-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.mat-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Material Button style overwrite here */
button.mdc-button,
a.mdc-button {
  color: var(--secondary-color);
  transition: all 0.3s;
  border-radius: 6px;
  font-weight: 600;
  letter-spacing: 0.28px;
  outline: none;
  border: none;
  line-height: 20px;
  padding: 10px;
  font-family: "Open Sans", sans-serif;
}
button.mdc-button.full-width,
a.mdc-button.full-width {
  width: 100%;
}
button.mdc-button.mat-mdc-button-base,
a.mdc-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  height: 40px;
}
button.mdc-button.medium,
a.mdc-button.medium {
  height: 36px;
  padding: 8px 10px;
}
button.mdc-button.small,
a.mdc-button.small {
  height: 32px;
  padding: 6px 10px;
  font-size: 0.875em;
}
button.mdc-button.mat-primary,
a.mdc-button.mat-primary {
  color: var(--primary-color);
}
button.mdc-button.mat-primary:hover,
a.mdc-button.mat-primary:hover {
  background-color: var(--primary-hint-4);
}
button.mdc-button.mat-secondary,
a.mdc-button.mat-secondary {
  color: var(--secondary-color);
}
button.mdc-button.mat-secondary:hover,
a.mdc-button.mat-secondary:hover {
  background-color: var(--secondary-hint-3);
}
button.mdc-button.mat-calendar-period-button,
a.mdc-button.mat-calendar-period-button {
  color: var(--text-primary);
}
button.mdc-button[disabled],
a.mdc-button[disabled] {
  color: var(--stock-primary) !important;
  background-color: transparent !important;
  cursor: not-allowed;
  pointer-events: unset;
}
button.mdc-button > .mat-icon,
a.mdc-button > .mat-icon {
  margin-left: 0;
}
button.mdc-button .mdc-button__label,
a.mdc-button .mdc-button__label {
  white-space: nowrap;
}

button.mat-mdc-unelevated-button,
a.mat-mdc-unelevated-button {
  background-color: var(--white-primary);
  border: none !important;
  color: var(--white-primary);
  transition: all 0.3s;
  border-radius: 6px;
  padding: 10px;
  font-weight: 600;
  letter-spacing: 0.28px;
  outline: none;
  border: none;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
  height: 40px;
}
button.mat-mdc-unelevated-button.full-width,
a.mat-mdc-unelevated-button.full-width {
  width: 100%;
}
button.mat-mdc-unelevated-button.mat-mdc-button-base,
a.mat-mdc-unelevated-button.mat-mdc-button-base {
  height: 40px;
}
button.mat-mdc-unelevated-button.medium,
a.mat-mdc-unelevated-button.medium {
  height: 36px;
  padding: 8px 10px;
}
button.mat-mdc-unelevated-button.small,
a.mat-mdc-unelevated-button.small {
  height: 32px;
  padding: 6px 10px;
  font-size: 0.875em;
  font-weight: 400;
}
button.mat-mdc-unelevated-button.extra-small,
a.mat-mdc-unelevated-button.extra-small {
  height: 28px;
  padding: 4px 10px;
}
button.mat-mdc-unelevated-button.mat-primary,
a.mat-mdc-unelevated-button.mat-primary {
  color: var(--white-primary);
  background: var(--primary-color);
}
button.mat-mdc-unelevated-button.mat-primary:hover,
a.mat-mdc-unelevated-button.mat-primary:hover {
  background-color: var(--primary-light);
}
button.mat-mdc-unelevated-button.mat-secondary,
a.mat-mdc-unelevated-button.mat-secondary {
  color: var(--white-primary);
  background-color: var(--secondary-color);
}
button.mat-mdc-unelevated-button.mat-secondary:hover,
a.mat-mdc-unelevated-button.mat-secondary:hover {
  background-color: var(--secondary-light-color);
}
button.mat-mdc-unelevated-button.mat-accent,
a.mat-mdc-unelevated-button.mat-accent {
  background-color: var(--text-primary);
}
button.mat-mdc-unelevated-button.mat-accent:hover,
a.mat-mdc-unelevated-button.mat-accent:hover {
  background-color: var(--text-tertiary);
}
button.mat-mdc-unelevated-button.mat-warn,
a.mat-mdc-unelevated-button.mat-warn {
  background-color: var(--error-primary);
}
button.mat-mdc-unelevated-button.mat-warn:hover,
a.mat-mdc-unelevated-button.mat-warn:hover {
  background-color: var(--error-light);
}
button.mat-mdc-unelevated-button[disabled],
a.mat-mdc-unelevated-button[disabled] {
  background-color: var(--stock-primary) !important;
  color: var(--white-primary) !important;
  cursor: not-allowed;
  pointer-events: unset;
}
button.mat-mdc-unelevated-button > .mat-icon,
a.mat-mdc-unelevated-button > .mat-icon {
  margin-left: 0;
}
button.mat-mdc-unelevated-button .mat-mdc-button-touch-target,
a.mat-mdc-unelevated-button .mat-mdc-button-touch-target {
  height: 40px;
}

button.mat-mdc-outlined-button,
a.mat-mdc-outlined-button {
  border: 1px solid var(--text-primary);
  color: var(--text-primary);
  transition: all 0.3s;
  border-radius: 6px;
  padding: 9px 10px;
  font-weight: 600;
  letter-spacing: 0.28px;
  outline: none;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
}
button.mat-mdc-outlined-button.full-width,
a.mat-mdc-outlined-button.full-width {
  width: 100%;
}
button.mat-mdc-outlined-button.mat-mdc-button-base,
a.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 40px;
}
button.mat-mdc-outlined-button.medium,
a.mat-mdc-outlined-button.medium {
  height: 36px;
  padding: 7px 10px;
}
button.mat-mdc-outlined-button.small,
a.mat-mdc-outlined-button.small {
  height: 32px;
  padding: 5px 10px;
  font-size: 0.875em;
  font-weight: 400;
}
button.mat-mdc-outlined-button.mat-primary,
a.mat-mdc-outlined-button.mat-primary {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}
button.mat-mdc-outlined-button.mat-primary:hover,
a.mat-mdc-outlined-button.mat-primary:hover {
  background-color: var(--primary-hint-4);
}
button.mat-mdc-outlined-button.mat-secondary,
a.mat-mdc-outlined-button.mat-secondary {
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
}
button.mat-mdc-outlined-button.mat-secondary:hover,
a.mat-mdc-outlined-button.mat-secondary:hover {
  background-color: var(--secondary-hint-5);
}
button.mat-mdc-outlined-button.mat-accent,
a.mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: transparent;
  --mdc-outlined-button-label-text-color: var(--secondary-color);
  --mat-outlined-button-state-layer-color: var(--secondary-color);
  border: 1px solid var(--text-primary);
  color: var(--text-primary);
}
button.mat-mdc-outlined-button.mat-accent:hover,
a.mat-mdc-outlined-button.mat-accent:hover {
  background-color: var(--stock-hint);
}
button.mat-mdc-outlined-button.mat-warn,
a.mat-mdc-outlined-button.mat-warn {
  border: 1px solid var(--error-primary);
  color: var(--error-primary);
}
button.mat-mdc-outlined-button.mat-warn:hover,
a.mat-mdc-outlined-button.mat-warn:hover {
  background-color: var(--error-hint);
}
button.mat-mdc-outlined-button[disabled],
a.mat-mdc-outlined-button[disabled] {
  background-color: var(--stock-primary) !important;
  border-color: var(--stock-primary) !important;
  color: var(--white-primary) !important;
  cursor: not-allowed;
  pointer-events: unset;
}
button.mat-mdc-outlined-button > .mat-icon,
a.mat-mdc-outlined-button > .mat-icon {
  margin-left: 0;
}

button.icon-button .mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

button.mat-mdc-icon-button {
  --mat-icon-button-hover-state-layer-opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  --mat-paginator-enabled-icon-color: black;
  --mat-paginator-disabled-icon-color: var(--text-light);
}
button.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: 40px;
  height: 40px;
  padding: 0px !important;
}
button.mat-mdc-icon-button.mat-suffix-button {
  --mdc-icon-button-state-layer-size: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
}
button.mat-mdc-icon-button.mat-suffix-button .mat-mdc-button-touch-target {
  height: 1.5rem;
  width: 1.5rem;
}
button.mat-mdc-icon-button[disabled] {
  cursor: not-allowed;
  pointer-events: unset;
}

button.mat-mdc-outlined-button.mat-accent .mat-button-focus-overlay {
  background-color: var(--text-secondary);
}

.button-section .text-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.delete-modal .text-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pdf-to-container .mat-mdc-icon-button,
.pdf-container .mat-mdc-icon-button {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pdf-to-container .mat-mdc-icon-button:hover,
.pdf-container .mat-mdc-icon-button:hover {
  background-color: var(--background-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}
.pdf-to-container .mat-mdc-icon-button .mat-button-wrapper,
.pdf-container .mat-mdc-icon-button .mat-button-wrapper {
  width: 20px;
  height: 20px;
  display: flex;
}
.pdf-to-container .mat-mdc-icon-button .mat-icon,
.pdf-container .mat-mdc-icon-button .mat-icon {
  color: var(--text-light);
}

.action-icon.mat-mdc-icon-button {
  display: flex;
  align-items: center;
}
.action-icon.mat-mdc-icon-button .mat-button-wrapper {
  display: block;
  margin: auto;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.mat-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.mat-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Material Checkbox style overwrite here */
.mat-mdc-checkbox {
  --mdc-checkbox-unselected-icon-color: var(--text-light);
  --mdc-checkbox-selected-focus-icon-color: var(--secondary-color);
  --mdc-checkbox-selected-hover-icon-color: var(--secondary-color);
  --mdc-checkbox-selected-icon-color: var(--secondary-color);
  --mdc-checkbox-selected-pressed-icon-color: var(--secondary-color);
  --mdc-checkbox-selected-focus-state-layer-color: var(--secondary-color);
  --mdc-checkbox-selected-hover-state-layer-color: black;
  --mdc-checkbox-selected-pressed-state-layer-color: black;
  --mdc-checkbox-disabled-selected-icon-color: var(--secondary-color);
  --mat-checkbox-disabled-label-color: var(--text-secondary);
  --mat-checkbox-label-text-color: var(--text-secondary);
  --mdc-checkbox-unselected-focus-icon-color: var(--text-light);
  --mdc-checkbox-unselected-hover-icon-color: var(--text-light);
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mdc-checkbox--disabled {
  pointer-events: unset;
}
.mat-mdc-checkbox.mdc-checkbox--disabled .mdc-checkbox__ripple {
  visibility: hidden;
}
.mat-mdc-checkbox .mdc-form-field .mdc-checkbox {
  padding: 0;
  margin: auto;
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
  flex: 0 0 1rem;
}
.mat-mdc-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__native-control {
  top: -0.75rem;
  left: -0.75rem;
}
.mat-mdc-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__native-control:focus ~ .mdc-checkbox__ripple {
  opacity: 0;
}
.mat-mdc-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__ripple,
.mat-mdc-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-ripple {
  height: 2.5rem;
  width: 2.5rem;
  top: -0.75rem;
  left: -0.75rem;
}
.mat-mdc-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
  width: 1rem;
  height: 1rem;
  top: 0px;
  left: 0px;
  border-width: 2px;
}
.mat-mdc-checkbox .mdc-form-field .mdc-checkbox.select-all .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mat-mdc-checkbox .mdc-form-field .mdc-checkbox.select-all .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  background-color: var(--white-primary);
  border-color: var(--white-primary);
}
.mat-mdc-checkbox .mdc-form-field .mdc-checkbox.select-all .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  width: 100%;
}
.mat-mdc-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mat-mdc-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  border-width: 2px;
}
.mat-mdc-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background .mdc-checkbox__mixedmark,
.mat-mdc-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  width: calc(100% - 2px);
}
.mat-mdc-checkbox .mdc-form-field label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--text-secondary);
  margin: 0;
  padding-left: 0;
  white-space: normal;
}
.mat-mdc-checkbox .mat-checkbox-layout {
  margin: 0;
}
.mat-mdc-checkbox.mat-secondary.mat-checkbox-checked .mat-checkbox-background, .mat-mdc-checkbox.mat-secondary.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: var(--secondary-color);
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--secondary-color) !important;
}

.question-options .mat-mdc-checkbox-checked .mdc-form-field label {
  color: var(--primary-color);
}

/* for tags*/
.tag {
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: var(--text-secondary);
  padding: 4px 8px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  min-width: 93px;
  display: inline-block;
  text-align: center;
  letter-spacing: normal;
  white-space: nowrap;
}
.tag.tag-void, .tag.tag-pipeline-project, .tag.tag-on-going, .tag.tag-inprogress, .tag.tag-pending, .tag.tag-survey-sent {
  background-color: var(--warn-secondary);
  color: var(--warn-primary);
}
.tag.tag-won, .tag.tag-accepted, .tag.tag-closed, .tag.tag-paid, .tag.tag-pipeline-closed, .tag.tag-executed, .tag.tag-active, .tag.tag-approved, .tag.tag-completed, .tag.tag-pitch-invited, .tag.tag-pipeline-completed, .tag.tag-survey-completed {
  background-color: var(--success-tertiary);
  color: var(--success-primary);
}
.tag.tag-boarding-on-going, .tag.tag-open, .tag.tag-start, .tag.tag-pipeline-demand, .tag.tag-started, .tag.tag-sow-invited, .tag.tag-invited, .tag.tag-not-yet-sent, .tag.tag-draft {
  background-color: var(--secondary-hint-3);
  color: var(--secondary-color);
}
.tag.tag-uncollectible, .tag.tag-change-request, .tag.tag-inactive, .tag.tag-due, .tag.tag-requested, .tag.tag-rejected, .tag.tag-pipeline-rejected, .tag.tag-update-required, .tag.tag-changes-submitted, .tag.tag-stopped, .tag.tag-off-boarding {
  background-color: var(--error-hint);
  color: var(--error-primary);
}
.tag.tag-pipeline-idea {
  color: var(--lavender-tag);
  background: var(--lavender-tag-bg);
}

/* for breadcrumbs*/
.breadcrumbs li {
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  color: var(--text-hint);
}
.breadcrumbs li:not(:last-child)::after {
  content: "/";
  padding: 0 6px;
}
.breadcrumbs li .route-link:hover {
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  color: var(--blue-color-fb);
  text-decoration: underline;
}
.breadcrumbs li.more-actions {
  display: flex;
  align-items: center;
  padding: 4px 6px;
}
.breadcrumbs li.more-actions:hover, .breadcrumbs li.more-actions[aria-expanded=true] {
  border-radius: 3px;
  background-color: var(--black-color-05);
}
.breadcrumbs li:last-child {
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  color: var(--text-primary);
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.mat-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.mat-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Material Chip Lists style overwrite here */
.mat-mdc-chip-listbox .mdc-evolution-chip-set__chips .mat-mdc-chip {
  background-color: var(--white-lilac);
  border-radius: 6px;
  border: none;
  height: auto;
  padding: 7px 0;
  margin: 4px;
  line-height: 18px;
}
.mat-mdc-chip-listbox .mdc-evolution-chip-set__chips .mat-mdc-chip .mdc-evolution-chip__cell .mdc-evolution-chip__action {
  width: 100%;
}
.mat-mdc-chip-listbox .mdc-evolution-chip-set__chips .mat-mdc-chip .mdc-evolution-chip__cell .mdc-evolution-chip__action .mdc-evolution-chip__text-label {
  width: inherit;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  color: var(--text-secondary);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mat-mdc-chip-listbox .mdc-evolution-chip-set__chips .mat-mdc-chip .mdc-evolution-chip__cell .mdc-evolution-chip__action .mdc-evolution-chip__text-label mat-icon {
  width: 18px;
  height: 18px;
  font-size: 18px;
}
.mat-mdc-chip-listbox .mdc-evolution-chip-set__chips .mat-mdc-chip .mat-mdc-chip-remove {
  width: 18px !important;
  height: 18px !important;
  color: var(--text-hint);
  margin-left: 6px;
  opacity: 1;
}
.mat-mdc-chip-listbox .mdc-evolution-chip-set__chips .mat-mdc-chip .mat-mdc-chip-remove:hover {
  opacity: 1 !important;
}
.mat-mdc-chip-listbox .mdc-evolution-chip-set__chips .mat-mdc-chip .chip-children {
  font-weight: 600;
  display: flex;
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: 4px;
  color: var(--text-tertiary);
  background: var(--border-grey);
  border-radius: 30px;
  justify-content: center;
  align-items: center;
}
.mat-mdc-chip-listbox .mdc-evolution-chip-set__chips .mat-mdc-chip.statusBeyond {
  color: var(--red);
  border-color: var(--red);
}
.mat-mdc-chip-listbox .mdc-evolution-chip-set__chips .mat-mdc-chip.statusBeyond .mdc-evolution-chip__cell .mdc-evolution-chip__action .mdc-evolution-chip__text-label {
  color: inherit;
}
.mat-mdc-chip-listbox .mdc-evolution-chip-set__chips .mat-mdc-chip.mat-mdc-chip-with-trailing-icon {
  padding-left: 12px;
  padding-right: 12px;
}
.mat-mdc-chip-listbox .mdc-evolution-chip-set__chips .mat-mdc-chip.mat-mdc-chip-with-trailing-icon .mdc-evolution-chip__cell--primary .mdc-evolution-chip__action {
  padding-left: 0;
  padding-right: 0;
}
.mat-mdc-chip-listbox .mdc-evolution-chip-set__chips .mat-mdc-chip.mat-mdc-chip-with-trailing-icon .mdc-evolution-chip__cell--trailing .mat-mdc-chip-trailing-icon {
  padding-right: 0;
}
.mat-mdc-chip-listbox .mdc-evolution-chip-set__chips .mat-mdc-chip::after {
  background: var(--background-dark);
  border: none;
  transition: none;
}
.mat-mdc-chip-listbox .mdc-evolution-chip-set__chips .mat-mdc-chip:hover::after {
  opacity: 1;
  z-index: -1;
}

.mdc-evolution-chip-set__chips input.mat-input-element {
  background: none;
  border: 1px solid var(--primary-color-40) !important;
  color: var(--primary-color);
  white-space: normal;
  word-break: break-word;
  line-height: 1.3;
  outline: none !important;
  height: unset !important;
}

.table-chip-list .mdc-evolution-chip-set__chips {
  flex-wrap: wrap !important;
}
.table-chip-list .mdc-evolution-chip-set__chips .mat-mdc-chip {
  white-space: nowrap !important;
  word-break: keep-all !important;
}

.single-select-chip .mdc-evolution-chip-set__chips {
  height: fit-content;
  overflow: auto;
}

.mat-chip-organize .mdc-evolution-chip-set__chips .mat-standard-chip:hover {
  background: none;
}
.mat-chip-organize .mat-mdc-chip.mat-mdc-standard-chip .mat-mdc-chip-remove {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.mat-chip-organize .mat-mdc-chip.mat-mdc-standard-chip .mat-mdc-chip-remove:hover {
  background: var(--primary-color-12);
}

.select-filter-chip {
  align-items: center;
}
.select-filter-chip .mdc-evolution-chip-set__chips {
  margin: 0 !important;
}

.view-details-card .mdc-evolution-chip-set__chips {
  margin: 0 !important;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
mat-datepicker-content {
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 0, 0, 0.0392156863);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--text-grey-hint);
  --mat-datepicker-toggle-active-state-icon-color: var(--text-grey-hint);
}

/* Material Date Picker style overwrite here */
.mat-form-field-suffix .mat-datepicker-toggle .mat-button-focus-overlay, .mat-form-field-suffix .mat-datepicker-toggle .mat-button-ripple {
  margin-left: 0;
}

.mat-datepicker-toggle-active {
  color: var(--text-grey-secondary);
}

.mat-calendar-body-selected {
  background-color: var(--text-grey-hint);
  color: var(--white-primary);
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.mat-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.mat-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Material Dialog Modal style overwrite here */
.mat-mdc-dialog-container .mat-mdc-dialog-surface {
  --mdc-dialog-container-shape: 10px;
  --mdc-dialog-supporting-text-color: #000000de;
  padding: 24px;
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  color: var(--text-primary);
  margin: 0;
  padding: 0;
  font-family: "Plus Jakarta Sans", sans-serif !important;
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-title.mdc-dialog__title::before {
  height: unset;
  display: none;
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-title,
.mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-content,
.mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-actions {
  letter-spacing: 0;
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-content {
  margin: 0 -24px;
  padding: 0 24px;
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface .close-icon {
  color: var(--text-primary);
  cursor: pointer;
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface .dialog-info {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: var(--text-tertiary);
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-actions {
  min-height: unset;
  margin-bottom: 0;
  padding: 24px 0 0 0;
}
.mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-actions .mat-mdc-button-base + .mat-mdc-button-base {
  margin-left: 12px;
}

.support-contract-dialog .mat-mdc-dialog-container .mat-dialog-content {
  max-height: calc(100vh - 100px);
}

.view-action-dialog .mat-mdc-dialog-content {
  overflow: auto;
}

.change-admin-role .mat-mdc-dialog-content {
  overflow: unset;
}

.calendar-model .icon-container {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: var(--primary-hint-4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendar-model .icon-container .warn-icon {
  color: var(--primary-color) !important;
  font-size: 26px;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.mat-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.mat-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Mat form field style overwrite here */
mat-form-field.mat-mdc-form-field {
  --mdc-typography-caption-font-size: 13px;
  --mdc-typography-caption-line-height: 18px;
  --mdc-typography-caption-font-weight: 500;
  --mdc-filled-text-field-disabled-input-text-color: var(--text-primary);
  font-size: 14px;
  margin-bottom: 16px;
  position: relative;
  font-family: "Open Sans", sans-serif;
}
mat-form-field.mat-mdc-form-field.full-width {
  width: 100%;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper {
  padding: 0;
  border-radius: 6px;
  border: 1px solid var(--stock-tertiary);
  background-color: var(--white-primary);
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  width: 100%;
  min-height: unset;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: var(--text-primary);
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix .mat-mdc-input-element {
  padding: 9px 10px;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mdc-text-field__input {
  caret-color: black;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mat-mdc-form-field-focus-overlay {
  background-color: var(--white-primary) !important;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before, mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before, mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after, mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--disabled .mdc-line-ripple::before, mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--disabled .mdc-line-ripple::after, mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--filled .mdc-line-ripple::after {
  border: none;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--disabled {
  background-color: var(--background-primary) !important;
  border-color: var(--background-primary) !important;
  pointer-events: unset;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--disabled .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-input-element {
  cursor: not-allowed;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--focused {
  background-color: var(--white-primary) !important;
  border-color: var(--secondary-color) !important;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--invalid {
  background-color: var(--error-hint) !important;
  border: 1px solid var(--error-primary) !important;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--error-primary);
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-focus-overlay {
  background-color: var(--white-primary) !important;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex textarea {
  box-shadow: none;
  min-height: 100px;
  max-height: 200px;
  resize: none;
  box-sizing: border-box;
  margin: 0;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix .mat-icon,
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-icon {
  display: flex;
  align-items: center;
  color: var(--text-hint);
  font-size: 20px;
  width: 20px;
  height: 20px;
  padding: 0;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix span {
  display: flex;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix,
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix {
  padding: 0;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix .mat-icon {
  margin-left: 10px;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-datepicker-toggle {
  --mat-datepicker-toggle-active-state-icon-color: var(--text-grey-secondary);
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-datepicker-toggle .mdc-icon-button {
  --mdc-icon-button-state-layer-size: 32px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-datepicker-toggle .mdc-icon-button .mat-mdc-button-touch-target {
  width: 20px;
  height: 20px;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-icon {
  margin-right: 10px;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix + .mat-mdc-form-field-infix {
  padding-left: 0;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix + .mat-mdc-form-field-infix .mat-mdc-form-field-label {
  padding-left: 10px;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex input::placeholder, mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex textarea::placeholder {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--text-light);
  letter-spacing: 0;
}
mat-form-field.mat-mdc-form-field:hover .mat-mdc-text-field-wrapper .mat-mdc-form-field-focus-overlay {
  background-color: var(--white-primary) !important;
}
mat-form-field.mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-hint-wrapper, mat-form-field.mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-error-wrapper {
  padding: 0;
}
mat-form-field.mat-mdc-form-field.medium .mat-mdc-text-field-wrapper.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  min-height: unset !important;
  padding: 0;
}
mat-form-field.mat-mdc-form-field.medium .mat-mdc-text-field-wrapper.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix .mat-mdc-input-element {
  padding: 7px 10px;
}
mat-form-field.mat-mdc-form-field .mat-form-field-label {
  font-size: 14px;
  line-height: 38px;
  font-weight: 500;
  color: var(--text-light);
}
mat-form-field.mat-mdc-form-field.mat-focused .mat-form-field-label {
  color: var(--text-light);
}
mat-form-field.mat-mdc-form-field.no-border-input {
  margin: 0;
}
mat-form-field.mat-mdc-form-field.no-border-input .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
  border: none;
  padding: 0 !important;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--text-tertiary);
}
mat-form-field.mat-mdc-form-field.no-border-input.options {
  margin-bottom: 10px;
}
mat-form-field.mat-mdc-form-field.no-border-input .mat-mdc-text-field-wrapper {
  border: none;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--text-tertiary);
}
mat-form-field.mat-mdc-form-field.single-border .mat-mdc-input-element {
  padding: 10px 8px 10px 0;
}
mat-form-field.mat-mdc-form-field.single-border.input-medium .mat-mdc-input-element {
  padding: 8px 8px 8px 0;
}
mat-form-field.mat-mdc-form-field.single-border.mat-mdc-form-field .mat-mdc-form-field-label {
  padding-left: 0;
}
mat-form-field.mat-mdc-form-field.single-border.dynamic-input.mat-mdc-form-field .mat-mdc-form-field-label {
  padding-left: 14px;
}
mat-form-field.mat-mdc-form-field.single-border:focus .mat-mdc-form-field-flex, mat-form-field.mat-mdc-form-field.single-border.mat-focused .mat-mdc-form-field-flex {
  border-bottom: 1px solid var(--primary-color) !important;
  box-shadow: none !important;
}
mat-form-field.mat-mdc-form-field.single-border:focus.mdc-text-field--invalid .mat-form-field-flex, mat-form-field.mat-mdc-form-field.single-border.mat-focused.mdc-text-field--invalid .mat-form-field-flex {
  background-color: transparent;
  box-shadow: none;
}
mat-form-field.mat-mdc-form-field.no-border .mat-mdc-text-field-wrapper {
  border: none !important;
  box-shadow: none !important;
}
mat-form-field.mat-mdc-form-field.no-border .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix {
  padding-left: 0;
}
mat-form-field.mat-mdc-form-field.textarea .mat-mdc-form-field-label {
  padding-left: 8px;
}

.send-message-container .mat-mdc-text-field-wrapper {
  border-radius: 50px !important;
  height: 52px;
  padding: 6px 16px !important;
}
.send-message-container .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix textarea::placeholder {
  position: relative;
  top: 10px;
}

label.form-label {
  display: table;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--text-tertiary);
  letter-spacing: normal;
  margin: 0 0 6px 0;
}

mat-error.mat-mdc-form-field-error {
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: var(--error-primary);
  margin-top: 4px;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.mat-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.mat-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Mat form field Input text box style overwrite here */
mat-form-field.mat-mdc-form-field-type-mat-input input.mat-mdc-input-element {
  margin-top: 0;
  vertical-align: middle;
}
mat-form-field.mat-mdc-form-field-type-mat-input .mat-mdc-form-field-subscript-wrapper {
  margin-top: 0;
}
mat-form-field.mat-mdc-form-field-type-mat-input .mat-mdc-form-field-subscript-wrapper .mat-error {
  color: var(--error-primary) !important;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  margin-top: 6px;
  overflow: visible;
}
mat-form-field.mat-mdc-form-field-type-mat-input .mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-hint-wrapper {
  bottom: 0;
  padding-right: 2px;
}
mat-form-field.mat-mdc-form-field-type-mat-input.mdc-text-field--invalid .mat-form-field-subscript-wrapper {
  position: relative;
}

/* Search and clear section style here */
.search-clear .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex {
  border-bottom: 1px solid var(--grey-lighten-2) !important;
  padding: 8px 0 !important;
  border: none;
}
.search-clear .mat-mdc-form-field-wrapper .mat-mdc-form-field-icon-prefix .search-icon i {
  font-size: 18px;
  line-height: 1;
  font-weight: normal;
  color: var(--text-grey-content);
}
.search-clear .mat-mdc-form-field-wrapper .mat-mdc-form-field-icon-suffix .close-icon {
  font-size: 11px;
  line-height: 1;
  font-weight: 400;
  color: var(--text-grey-content);
  display: flex;
  align-items: center;
}
.search-clear .mat-mdc-form-field-wrapper .mat-mdc-form-field-icon-suffix .close-icon i {
  font-size: 13px;
  background: var(--text-grey-content);
  border-radius: 50%;
  color: var(--white-primary);
  margin-left: 16px;
  cursor: pointer;
}

.capabilities-input mat-form-field {
  margin-bottom: 0;
}
.capabilities-input mat-form-field.mat-mdc-form-field-type-mat-input .mat-mdc-form-field-wrapper {
  border: none;
}
.capabilities-input mat-form-field.mat-mdc-form-field-type-mat-input.mdc-text-field--invalid .mat-mdc-form-field-wrapper {
  background-color: transparent;
}

.company-info-input.mat-mdc-form-field-type-mat-input .mat-mdc-form-field-flex {
  border: none;
  flex-direction: row-reverse;
}
.company-info-input.mdc-text-field--invalid .mat-mdc-form-field-flex {
  background-color: transparent !important;
}

.drawer-input-primary.mat-mdc-form-field .mat-mdc-form-field-wrapper {
  margin: 0;
  width: 200px;
}
.drawer-input-primary.mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-outline {
  top: 0;
}
.drawer-input-primary.mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-outline .mat-mdc-form-field-outline-start,
.drawer-input-primary.mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-outline .mat-mdc-form-field-outline-gap,
.drawer-input-primary.mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-outline .mat-mdc-form-field-outline-end {
  border-width: 1px;
}
.drawer-input-primary.mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  width: unset;
}
.drawer-input-primary.mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-input-element {
  padding: 0;
  line-height: 40px;
}
.drawer-input-primary.mat-mdc-form-field:hover .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-outline, .drawer-input-primary.mat-mdc-form-field.mat-focused .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-outline {
  color: var(--primary-color);
}

.impact-tracking-input mat-form-field.mat-mdc-form-field-type-mat-input input.mat-mdc-input-element {
  background: var(--white-primary) !important;
  border: none;
}
.impact-tracking-input mat-form-field.mat-mdc-form-field-type-mat-input.mat-mdc-form-field-disabled .mat-mdc-form-field-flex {
  border-color: var(--white-primary);
}
.impact-tracking-input mat-form-field.mat-mdc-form-field {
  margin: 0 !important;
}
.impact-tracking-input mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-select:hover {
  box-shadow: none;
}

.dynamic-input .mat-mdc-form-field-type-mat-input input.mat-mdc-input-element {
  font-size: 16px;
  line-height: normal;
  height: 40px;
  padding: 5px 8px 4px 0;
}

.selection-table .mat-mdc-table .text-right .mat-mdc-form-field .mat-mdc-form-field-infix,
.rfp-selection-table .mat-mdc-table .text-right .mat-mdc-form-field .mat-mdc-form-field-infix {
  max-width: 104px;
  width: 100%;
  height: 36px;
}
.selection-table .mat-mdc-table .text-left .mat-mdc-form-field,
.rfp-selection-table .mat-mdc-table .text-left .mat-mdc-form-field {
  margin-bottom: 0 !important;
}
.selection-table .mat-mdc-table .text-left .mat-mdc-form-field .mat-mdc-form-field-infix,
.rfp-selection-table .mat-mdc-table .text-left .mat-mdc-form-field .mat-mdc-form-field-infix {
  height: 36px;
}

.template-editor-name mat-form-field {
  margin-bottom: 0 !important;
}
.template-editor-name mat-form-field.mat-mdc-form-field-type-mat-input .mat-mdc-form-field-flex {
  border-radius: 6px;
}
.template-editor-name mat-form-field.mat-mdc-form-field-type-mat-input input.mat-mdc-input-element {
  height: 40px;
}
.template-editor-name mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-select {
  border-radius: 6px;
}

.organization-input .dynamic-input .mat-mdc-form-field {
  margin-bottom: 0 !important;
}
.organization-input .dynamic-input .mat-mdc-form-field .mat-mdc-text-field-wrapper {
  border: none !important;
  border-bottom: 1px solid var(--stock-tertiary);
  border-radius: 0 !important;
}
.organization-input .dynamic-input form {
  height: fit-content !important;
}
.organization-input .dynamic-input .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
  display: none;
}

.selection-weight .mat-mdc-input-element {
  text-align: right;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.mat-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.mat-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Material Menu style overwrite here */
.mat-mdc-menu-panel {
  border-radius: 6px !important;
  border: 1px solid var(--bg-light-white);
  background: var(--white-primary);
  box-shadow: 10px 0px 30px 0px var(--black-color-05), 1px 1px 5px 0px var(--black-color-05) !important;
  min-width: 140px !important;
  min-height: unset !important;
}
.mat-mdc-menu-panel .mat-mdc-menu-content {
  padding: 4px !important;
}
.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item {
  --mat-menu-item-spacing: 8px;
  border-radius: 4px;
  padding: 8px 6px;
  height: 40px;
  display: flex;
  align-items: center;
  min-height: 40px;
}
.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--text-tertiary);
  display: flex;
  align-items: center;
  font-family: "Open Sans", sans-serif;
}
.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text .material-icons {
  margin-right: 8px;
}
.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-icon {
  color: var(--text-hint);
  font-size: 20px;
  width: 20px;
  height: 20px;
}
.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.delete-action, .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.stop-action, .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.menu-delete-icon-btn {
  color: var(--error-primary);
}
.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.delete-action .mat-icon, .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.delete-action .mat-mdc-menu-item-text, .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.stop-action .mat-icon, .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.stop-action .mat-mdc-menu-item-text, .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.menu-delete-icon-btn .mat-icon, .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.menu-delete-icon-btn .mat-mdc-menu-item-text {
  color: inherit;
}
.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.delete-action .mat-icon .material-icons, .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.delete-action .mat-mdc-menu-item-text .material-icons, .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.stop-action .mat-icon .material-icons, .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.stop-action .mat-mdc-menu-item-text .material-icons, .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.menu-delete-icon-btn .mat-icon .material-icons, .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.menu-delete-icon-btn .mat-mdc-menu-item-text .material-icons {
  color: inherit;
}
.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item:hover {
  background-color: var(--bg-light-white);
}
.mat-mdc-menu-panel .mat-mdc-menu-content:focus {
  outline: none !important;
}
.mat-mdc-menu-panel.popover {
  min-width: unset !important;
  margin-top: 0.5rem;
  overflow: visible;
}
.mat-mdc-menu-panel.popover::after {
  width: 0;
  height: 0;
  content: "";
  top: -0.5rem;
  left: 0.75rem;
  position: absolute;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.5rem solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.12);
}
.mat-mdc-menu-panel.popover .mat-mdc-menu-content {
  padding: 0 !important;
  border: 0;
}
.mat-mdc-menu-panel.popover .mat-mdc-menu-content .mat-mdc-card {
  padding: 1rem;
}
.mat-mdc-menu-panel.popover .mat-mdc-menu-content .mat-mdc-card .mat-mdc-card-content {
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--text-primary);
}

.notification-conatiner {
  width: 480px !important;
  max-width: 480px !important;
  padding: 0 !important;
}
.notification-conatiner .mat-mdc-menu-content .content-section {
  width: 100% !important;
  height: 366px !important;
}
.notification-conatiner .mat-mdc-menu-content .viewall-section {
  height: 50px;
}
.notification-conatiner .mat-mdc-menu-content .viewall-section .footer {
  font-size: 13px;
  line-height: normal;
  font-weight: 700;
  color: var(--secondary-color);
}
.notification-conatiner .request {
  width: 100% !important;
}

.emoji-menu {
  max-width: fit-content !important;
  padding: 0;
}

.mat-mdc-menu-panel.chip-more-vert {
  padding: 6px;
  border-radius: 4px;
  box-shadow: 0px 4px 12px 0px var(--black-opacity-10);
}
.mat-mdc-menu-panel.chip-more-vert .mat-mdc-menu-item {
  border-radius: 4px;
}
.mat-mdc-menu-panel.chip-more-vert .mat-mdc-menu-item:hover {
  background-color: var(--grey-lighten-5);
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.mat-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.mat-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/** Mat pagination styles here */
.mat-mdc-paginator {
  --mat-paginator-container-background-color: transparent;
}
.mat-mdc-paginator-container {
  justify-content: flex-start !important;
  padding: 0 !important;
  min-height: unset !important;
}
.mat-mdc-paginator .mat-mdc-paginator-page-size {
  margin-right: 0;
}
.mat-mdc-paginator .mat-mdc-paginator-page-size-label {
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: var(--text-tertiary);
  margin: 0 6px;
}
.mat-mdc-paginator .mat-mdc-paginator-page-size-select {
  margin: 0;
}
.mat-mdc-paginator .mat-mdc-paginator-page-size-select {
  width: 56px;
}
.mat-mdc-paginator .mat-mdc-paginator-range-label {
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: var(--text-tertiary);
  margin: 0 12px;
}
.mat-mdc-paginator button:hover {
  background: var(--background-primary);
}
.mat-mdc-paginator .mat-mdc-paginator-range-actions .mat-mdc-icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  line-height: 36px;
  color: var(--text-primary);
  padding: 0;
}
.mat-mdc-paginator .mat-mdc-paginator-range-actions .mat-mdc-icon-button .mat-mdc-paginator-icon {
  width: 20px;
}
.mat-mdc-paginator .mat-mdc-paginator-range-actions .mat-mdc-icon-button .mat-mdc-button-touch-target {
  width: 36px;
  height: 36px;
}
.mat-mdc-paginator .mat-mdc-paginator-range-actions .mat-mdc-icon-button:disabled {
  color: var(--text-light);
  background: none;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 0;
}
.mat-mdc-paginator .mdc-text-field--outlined .mdc-notched-outline {
  display: none;
}
.mat-mdc-paginator mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-select {
  padding: 6px;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
mat-progress-bar.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--green);
  --mdc-linear-progress-track-height: 8px;
  --mdc-linear-progress-active-indicator-height: 8px;
  border-radius: 3.5px;
  height: 8px;
}
mat-progress-bar.mat-mdc-progress-bar.progress-no-radius {
  border-radius: 0;
}
mat-progress-bar.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: var(--grey-lighten-2);
  border-radius: 10px;
}
mat-progress-bar.mat-mdc-progress-bar.mat-primary {
  --mdc-linear-progress-active-indicator-color: var(--green);
}
mat-progress-bar.mat-mdc-progress-bar.mat-primary .mdc-linear-progress__bar-inner {
  border-color: var(--primary-color);
  border-radius: 10px;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.mat-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.mat-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Material Radio Button style overwrite here */
.mat-mdc-radio-button {
  --mdc-radio-state-layer-size: 20px;
  --mdc-radio-selected-icon-color: var(--secondary-color);
  --mdc-radio-selected-hover-icon-color: var(--secondary-color);
  --mdc-radio-selected-focus-icon-color: var(--secondary-color);
  --mdc-radio-selected-pressed-icon-color: var(--secondary-color);
  --mdc-radio-unselected-hover-icon-color: var(--text-light);
  --mdc-radio-unselected-icon-color: var(--text-light);
}
.mat-mdc-radio-button .mdc-form-field label {
  margin: 0;
  padding-left: 8px;
  cursor: pointer;
}
.mat-mdc-radio-button .mdc-form-field .mat-radio-label-content {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--text-secondary);
}
.mat-mdc-radio-button .mdc-form-field + .mat-mdc-radio-button {
  margin-left: 0;
}
.mat-mdc-radio-button .mdc-form-field .mat-ripple-element {
  background-color: var(--secondary-color) !important;
}
.mat-mdc-radio-button .mdc-form-field.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--secondary-color);
}
.mat-mdc-radio-button .mdc-form-field.mat-radio-checked .mat-radio-inner-circle {
  background-color: var(--secondary-color);
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.mat-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.mat-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Mat form field Select box style overwrite here */
.mat-mdc-select-panel {
  border-radius: 6px !important;
  border: 1px solid var(--stock-hint);
  background: var(--white-primary);
  box-shadow: 10px 0px 30px 0px var(--black-color-05), 1px 1px 10px 0px var(--black-color-12) !important;
  padding: 8px !important;
  left: 8px;
}
.mat-mdc-select-panel .mat-mdc-option {
  --mat-option-hover-state-layer-color: var(--bg-light-white);
  color: var(--text-secondary);
  font-size: 14px !important;
  padding: 8px;
  height: 36px !important;
  line-height: 36px !important;
  min-height: 36px !important;
  border-radius: 4px;
}
.mat-mdc-select-panel .mat-mdc-option:hover {
  background: var(--bg-light-white);
}
.mat-mdc-select-panel .mat-mdc-option .mat-pseudo-checkbox {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.mat-mdc-select-panel .mat-mdc-option .mdc-list-item__primary-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.mat-mdc-select-panel .mat-mdc-option.mdc-list-item--selected {
  background: var(--background-primary) !important;
}
.mat-mdc-select-panel .mat-mdc-option.mdc-list-item--selected .mat-option-text, .mat-mdc-select-panel .mat-mdc-option.mdc-list-item--selected .mdc-list-item__primary-text {
  font-weight: 600;
  color: var(--secondary-color) !important;
}
.mat-mdc-select-panel .mat-mdc-option:not(.mat-mdc-option-multiple) mat-pseudo-checkbox {
  display: none;
}
.mat-mdc-select-panel.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mat-mdc-menu-item-text, .mat-mdc-select-panel.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--secondary-color) !important;
}

mat-form-field.mat-mdc-form-field-type-mat-select {
  position: relative;
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-text-field-wrapper {
  border: none;
  overflow: unset;
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
  min-height: unset;
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-text-field-wrapper.mdc-text-field--invalid {
  background: transparent !important;
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-select {
  background: var(--white-primary);
  border: 1px solid var(--stock-tertiary);
  line-height: 20px;
  border-radius: 6px;
  padding: 9px 10px;
  width: 100%;
  font-size: 14px;
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper {
  height: 20px;
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  border: none;
  margin: 0;
  width: 20px;
  height: 20px;
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow::after {
  font-family: "Material Icons";
  content: "\e5cf";
  font-size: 24px;
  color: var(--text-hint);
  position: relative;
  top: 0;
  right: 0;
  background: none;
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow svg {
  display: none;
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-select.mat-mdc-select-invalid {
  background-color: var(--error-hint) !important;
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-trigger .mat-mdc-select-value .mat-mdc-select-placeholder {
  color: var(--error-primary) !important;
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-select.mat-select-invalid .mat-select-arrow::after {
  color: var(--error-primary);
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-select.action-status {
  box-shadow: 0 1px 2px 0 var(--black-color-24), 0 0 2px 0 var(--black-color-12);
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-select:not(.mat-mdc-select-invalid):hover {
  box-shadow: 0 1px 2px 0 var(--black-color-24), 0 0 2px 0 var(--black-color-12);
}
mat-form-field.mat-mdc-form-field-type-mat-select :not(.mat-mdc-select-invalid) .mdc-text-field--focused .mat-mdc-select {
  background-color: var(--white-primary) !important;
  border-color: var(--secondary-color) !important;
}
mat-form-field.mat-mdc-form-field-type-mat-select.medium .mat-select {
  padding: 7px 10px;
}
mat-form-field.mat-mdc-form-field-type-mat-select.mat-form-field-invalid .mat-form-field-subscript-wrapper {
  position: relative;
}
mat-form-field.mat-mdc-form-field-type-mat-select.mat-form-field-invalid .mat-form-field-label {
  color: var(--red) !important;
}
mat-form-field.mat-mdc-form-field-type-mat-select:focus .mat-select, mat-form-field.mat-mdc-form-field-type-mat-select.mat-focused .mat-select {
  border: 1px solid var(--secondary-color);
}
mat-form-field.mat-mdc-form-field-type-mat-select:focus .mat-select.mat-select-invalid, mat-form-field.mat-mdc-form-field-type-mat-select.mat-focused .mat-select.mat-select-invalid {
  background: var(--white-primary);
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-select-arrow {
  color: var(--text-primary);
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-form-field-label {
  color: var(--text-grey-content) !important;
}
mat-form-field.mat-mdc-form-field-type-mat-select.mat-focused.mat-primary .mat-select-arrow,
mat-form-field.mat-mdc-form-field-type-mat-select.mat-focused.mat-primary .mat-form-field-label {
  color: var(--text-primary);
}
mat-form-field.mat-mdc-form-field-type-mat-select.mat-form-field-disabled .mat-mdc-select {
  border-color: var(--background-primary);
  background-color: var(--background-primary);
}
mat-form-field.mat-mdc-form-field-type-mat-select.mat-form-field-disabled .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value {
  cursor: not-allowed;
}
mat-form-field.mat-mdc-form-field-type-mat-select.mat-form-field-disabled .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow::after {
  content: "";
}
mat-form-field.mat-mdc-form-field-type-mat-select.mat-form-field-disabled:hover {
  cursor: not-allowed;
}
mat-form-field.mat-mdc-form-field-type-mat-select.single-border .mat-select {
  border: none;
  border-bottom: 1px solid var(--grey-lighten-2);
  border-radius: 0;
}
mat-form-field.mat-mdc-form-field-type-mat-select.single-border .mat-select.mat-select-invalid {
  background: var(--red-lighten2);
  border: none;
  border-bottom: 1px solid var(--red);
}
mat-form-field.mat-mdc-form-field-type-mat-select.single-border:focus .mat-select.mat-select-invalid, mat-form-field.mat-mdc-form-field-type-mat-select.single-border.mat-focused .mat-select.mat-select-invalid {
  background: var(--white-primary);
  border: none;
  border-bottom: 2px solid var(--red);
}
mat-form-field.mat-mdc-form-field-type-mat-select.transparent-form .mat-select {
  border: none;
  border-radius: 0;
}
mat-form-field.mat-mdc-form-field-type-mat-select.transparent-form .mat-select.mat-select-invalid {
  background: var(--red-lighten2);
  border: none;
}
mat-form-field.mat-mdc-form-field-type-mat-select.transparent-form:focus .mat-select.mat-select-invalid, mat-form-field.mat-mdc-form-field-type-mat-select.transparent-form.mat-focused .mat-select.mat-select-invalid {
  background: var(--white-primary);
  border: none;
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-form-field-subscript-wrapper {
  margin-top: 0;
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-form-field-subscript-wrapper .mat-error {
  color: var(--error-primary) !important;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  margin-top: 6px;
  overflow: visible;
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-form-field-subscript-wrapper .mat-error.error-left {
  top: 3px;
  left: calc(-100% - 12px);
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-form-field-subscript-wrapper .mat-error.error-left::after {
  bottom: unset;
  right: -12px;
  transform: rotateZ(-90deg);
  top: 12px;
}
mat-form-field.mat-mdc-form-field-type-mat-select .mat-form-field-subscript-wrapper .mat-form-field-hint-wrapper {
  bottom: 0;
  padding-right: 2px;
}
mat-form-field.mat-mdc-form-field-type-mat-select:hover .mat-form-field-subscript-wrapper .mat-error:nth-of-type(1), mat-form-field.mat-mdc-form-field-type-mat-select:focus .mat-form-field-subscript-wrapper .mat-error:nth-of-type(1), mat-form-field.mat-mdc-form-field-type-mat-select.mat-focused .mat-form-field-subscript-wrapper .mat-error:nth-of-type(1) {
  display: block;
}
mat-form-field.mat-mdc-form-field-type-mat-select:focus .mat-form-field-flex, mat-form-field.mat-mdc-form-field-type-mat-select.mat-focused .mat-form-field-flex {
  border-color: var(--primary-color);
}
mat-form-field.mat-mdc-form-field-type-mat-select.mat-form-field-invalid .mat-form-field-flex {
  background-color: var(--red-lighten2);
  border-color: var(--red);
  border-width: 1px;
  border-style: solid;
  align-items: center;
  border-radius: 6px;
}
mat-form-field.mat-mdc-form-field-type-mat-select.mat-form-field-invalid .mat-form-field-flex:hover {
  box-shadow: 0 1px 2px 0 var(--black-color-24), 0 0 2px 0 var(--black-color-12);
}
mat-form-field.mat-mdc-form-field-type-mat-select.mat-form-field-invalid .mat-form-field-flex .mat-select {
  background-color: var(--error-hint) !important;
  border: none;
}
mat-form-field.mat-mdc-form-field-type-mat-select.mat-form-field-invalid .mat-form-field-flex .mat-select:hover {
  box-shadow: none;
}

.single-level-select-container .mat-mdc-form-field .mat-mdc-form-field-flex {
  padding-top: 6px !important;
  padding-bottom: 4px !important;
}
.single-level-select-container .mat-mdc-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-form-field-outline {
  display: none;
}
.single-level-select-container .mat-mdc-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-select-arrow:after {
  top: 24px !important;
}

.selection-dropdown {
  position: absolute;
  top: 44px;
  right: -54px;
}

.selection-dropdown-single {
  position: absolute;
  top: 44px;
  right: -30px;
}

.proposal-select {
  position: absolute !important;
  top: 42px !important;
  width: 200px !important;
}

.impact-tracking-input mat-form-field {
  max-width: 120px;
  width: 100%;
}
.impact-tracking-input mat-form-field.mat-mdc-form-field-type-mat-select.mat-mdc-form-field-disabled .mat-mdc-select {
  background-color: var(--white-primary);
}
.impact-tracking-input mat-form-field.mat-mdc-form-field-type-mat-select .mat-select {
  height: 42px;
}
.impact-tracking-input .mat-mdc-select-value-text {
  color: var(--black-color);
  font-weight: 600;
}

.template-font-family .mat-mdc-form-field {
  border-radius: 6px;
  background: var(--grey-lighten-5);
}
.template-font-family .mat-mdc-form-field .mat-mdc-form-field-flex {
  min-width: 104px;
}
.template-font-family .mat-mdc-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-select {
  border-radius: 6px;
}

.template-font-size .mat-mdc-form-field {
  border-radius: 6px;
  background: var(--grey-lighten-5);
}
.template-font-size .mat-mdc-form-field .mat-mdc-form-field-flex {
  min-width: 74px;
}
.template-font-size .mat-mdc-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-select {
  border-radius: 6px;
}

.template-font-style .mat-mdc-form-field {
  border-radius: 6px;
  background: var(--grey-lighten-5);
}
.template-font-style .mat-mdc-form-field .mat-mdc-form-field-flex {
  min-width: 74px;
}
.template-font-style .mat-mdc-form-field.mat-mdc-form-field-type-mat-select .mat-select {
  border-radius: 6px;
}

.demand-select {
  position: absolute;
  top: 42px;
}

.provider-le-status .tag-completed {
  background-color: var(--success-tertiary) !important;
}
.provider-le-status .tag-completed .mat-mdc-select-value-text {
  color: var(--success-primary) !important;
}
.provider-le-status .tag-start {
  background-color: var(--secondary-hint-3) !important;
}
.provider-le-status .tag-start .mat-mdc-select-value-text {
  color: var(--secondary-color) !important;
}
.provider-le-status .tsg-off-boarding {
  background-color: var(--error-hint) !important;
}
.provider-le-status .tsg-off-boarding .mat-mdc-select-value-text {
  color: var(--error-primary) !important;
}

.demand-input mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-form-field-wrapper, .demand-input mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-select {
  background-color: var(--white-primary) !important;
}

.disabled-input mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-form-field-wrapper, .disabled-input mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-select {
  background-color: var(--white-primary) !important;
  color: var(--text-primary) !important;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.mat-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.mat-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Material Slide Toggle style overwrite here */
.mat-mdc-slide-toggle {
  --mdc-switch-unselected-track-color: var(--bg-light-white);
  --mdc-switch-unselected-hover-track-color: var(--bg-light-white);
  --mdc-switch-track-height: 14px;
  --mdc-switch-state-layer-size: 40px;
  --mdc-switch-handle-surface-color: var(--secondary-color);
  --mdc-switch-unselected-handle-color: var(--text-light);
  --mdc-switch-unselected-hover-handle-color: var(--text-light);
  --mdc-switch-unselected-focus-track-color: transparent;
  --mdc-switch-unselected-focus-handle-color: var(--text-light);
  --mdc-switch-selected-handle-color: var(--secondary-color);
  --mdc-switch-selected-hover-handle-color: var(--secondary-color);
  --mdc-switch-selected-focus-handle-color: var(--secondary-color);
  --mdc-switch-selected-track-color: var(--secondary-hint-3);
  --mdc-switch-selected-hover-track-color: var(--secondary-hint-3);
  --mdc-switch-selected-focus-track-color: var(--secondary-hint-3);
  --mdc-switch-unselected-pressed-track-color: var(--secondary-hint-3);
  --mdc-switch-selected-pressed-track-color: var(--secondary-hint-3);
  --mdc-switch-unselected-pressed-handle-color: var(--text-light);
  --mdc-switch-selected-pressed-handle-color: var(--secondary-color);
  --mdc-switch-disabled-unselected-track-color: var(--bg-light-white);
  --mdc-switch-disabled-selected-handle-color: var(--bg-light-white);
  --mdc-switch-disabled-unselected-handle-color: var(--bg-light-white);
  --mdc-switch-disabled-selected-track-color: var(--bg-light-white);
  --mdc-switch-unselected-hover-state-layer-color: transparent;
}
.mat-mdc-slide-toggle .mdc-form-field .mdc-switch {
  --mat-switch-with-icon-handle-size: 12px;
  --mat-switch-unselected-handle-size: 12px;
  --mat-switch-selected-handle-size: 12px;
  --mat-switch-pressed-handle-size: 12px;
  border-radius: 50px;
  border: 1px solid var(--stock-hint);
  background: var(--bg-light-white);
  width: 36px;
  height: 20px;
  margin-right: 4px;
}
.mat-mdc-slide-toggle .mdc-form-field .mdc-switch .mdc-switch__ripple {
  visibility: hidden;
}
.mat-mdc-slide-toggle .mdc-form-field .mdc-switch.mdc-switch--selected {
  border: 1px solid var(--secondary-hint-2);
  background: var(--secondary-hint-3);
}
.mat-mdc-slide-toggle .mdc-form-field .mdc-switch.mdc-switch--selected .mdc-switch__handle-track {
  transform: translateX(125%);
}
.mat-mdc-slide-toggle .mdc-form-field .mdc-switch.mdc-switch--unselected .mdc-switch__handle-track {
  transform: translateX(25%);
}
.mat-mdc-slide-toggle .mdc-form-field .mdc-switch.mdc-switch--disabled {
  opacity: 0.38;
}
.mat-mdc-slide-toggle .mdc-form-field .mdc-switch .mdc-switch__icons {
  display: none;
}
.mat-mdc-slide-toggle .mdc-form-field label {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--text-secondary);
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.mat-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.mat-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

.mat-mdc-slider {
  --mdc-slider-disabled-handle-color: var(--dark-green);
  --mdc-slider-disabled-active-track-color: var(--dark-green);
  --mdc-slider-handle-width: 15px;
  --mdc-slider-handle-height: 15px;
  --mdc-slider-disabled-inactive-track-color: #00000042;
  --mdc-slider-inactive-track-height: 6px;
  height: 40px;
  cursor: unset;
}
.mat-mdc-slider.mdc-slider--disabled {
  opacity: 1 !important;
}
.mat-mdc-slider.mdc-slider--disabled .mdc-slider__track--inactive {
  opacity: 1 !important;
}
.mat-mdc-slider .mdc-slider__thumb {
  cursor: grab;
}
.mat-mdc-slider .mdc-slider__input {
  cursor: unset;
}
.mat-mdc-slider .mat-ripple {
  display: none;
}

/* score card NPS slider style here */
.score-card-nps .mat-mdc-slider {
  width: 96%;
}

.question-options .mat-mdc-slider {
  --mdc-slider-disabled-handle-color: var(--white-primary);
  --mdc-slider-inactive-track-color: var(--black-color-10);
  --mdc-slider-disabled-active-track-color: var(--black-color-10);
  --mdc-slider-active-track-color: var(--dark-green);
  --mdc-slider-disabled-inactive-track-color: var(--black-color-10);
  --mdc-slider-inactive-track-height: 8px;
  --mdc-slider-active-track-height: 8px;
  width: 50%;
}
.question-options .mat-mdc-slider .mdc-slider__track .mdc-slider__track--inactive {
  opacity: 1;
  border: 1px solid var(--black-color-05);
}
.question-options .mat-mdc-slider .mdc-slider__thumb .mdc-slider__thumb-knob {
  transform: rotate(45deg) !important;
  top: 8px !important;
  left: 10px !important;
  width: 30px;
  height: 30px;
  border-radius: 20% !important;
  background: var(--white-primary) !important;
  border: 1px solid var(--dark-green) !important;
  box-shadow: unset;
}
.question-options .mat-mdc-slider .mdc-slider__thumb .mdc-slider__value-indicator-container {
  bottom: 9px;
  left: 25px;
  z-index: 1000;
  pointer-events: unset;
}
.question-options .mat-mdc-slider .mdc-slider__thumb .mdc-slider__value-indicator-container .mdc-slider__value-indicator {
  transform: scale(1);
  background-color: transparent;
  pointer-events: unset;
}
.question-options .mat-mdc-slider .mdc-slider__thumb .mdc-slider__value-indicator-container .mdc-slider__value-indicator::before, .question-options .mat-mdc-slider .mdc-slider__thumb .mdc-slider__value-indicator-container .mdc-slider__value-indicator::after {
  visibility: hidden;
}
.question-options .mat-mdc-slider .mdc-slider__thumb .mdc-slider__value-indicator-container .mdc-slider__value-indicator .mdc-slider__value-indicator-text {
  color: var(--dark-green) !important;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.mat-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.mat-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Mat Snackbar style overwrite here */
.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: transparent;
  box-shadow: 0px 13px 16px 0px var(--black-color-10);
  border-left: 5px solid;
  min-width: 405px;
  background: var(--white-primary);
  border-radius: 8px;
  margin: 24px !important;
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  box-shadow: unset !important;
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface .mat-mdc-snack-bar-label {
  padding-left: 0;
  padding-right: 0;
}
.mat-mdc-snack-bar-container .info-icon {
  display: inline-table;
  padding: 6px;
}
.mat-mdc-snack-bar-container .close-icon {
  color: var(--text-hint);
}
.mat-mdc-snack-bar-container p {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: var(--text-primary);
  letter-spacing: -0.408px;
}
.mat-mdc-snack-bar-container.success-snack-bar {
  border-color: var(--success-primary);
}
.mat-mdc-snack-bar-container.success-snack-bar .info-icon {
  color: var(--success-primary);
  background-color: var(--success-tertiary);
}
.mat-mdc-snack-bar-container.error-snack-bar {
  border-color: var(--error-primary);
}
.mat-mdc-snack-bar-container.error-snack-bar .info-icon {
  color: var(--error-primary);
  background-color: var(--error-hint);
}
.mat-mdc-snack-bar-container.warning-snack-bar {
  border-color: var(--warning-color-lighten);
}
.mat-mdc-snack-bar-container.warning-snack-bar .info-icon {
  color: var(--warning-color-lighten);
  background-color: var(--warning-color-lighten-1);
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* Material Loader style overwrite here */
.mat-mdc-progress-spinner.mat-primary {
  --mdc-circular-progress-active-indicator-color: var(--primary-color);
}

.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: var(--green);
}

.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: var(--warn-color);
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* Mat form field Textarea style overwrite here */
mat-form-field.mat-mdc-form-field-type-mat-input.textarea .mat-mdc-form-field-infix {
  padding: 0;
}
mat-form-field.mat-mdc-form-field-type-mat-input.textarea .mat-mdc-form-field-infix textarea {
  box-shadow: none;
  min-height: 60px;
  line-height: 1.4;
  padding: 0 8px;
  margin: 8px 0;
  resize: none;
}
mat-form-field.mat-mdc-form-field-type-mat-input.textarea .mat-mdc-form-field-infix textarea:focus {
  border: none;
}

.performance-textarea .mat-mdc-input-element {
  resize: none !important;
  height: 80px;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.mat-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.mat-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Material Tooltip style overwrite here */
.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-size: 12px;
}
.mat-mdc-tooltip .mdc-tooltip__surface {
  background: var(--text-grey-secondary) !important;
  padding: 6px 8px;
  opacity: 1;
}
.mat-mdc-tooltip::after {
  width: 0;
  height: 0;
  content: "";
  position: absolute;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.5rem solid var(--text-grey-secondary);
}
.mat-mdc-tooltip.below {
  overflow: initial;
}
.mat-mdc-tooltip.below:after {
  top: -0.5rem;
  right: calc(50% - 0.5rem);
  transform: rotate(0);
}
.mat-mdc-tooltip.above {
  overflow: initial;
}
.mat-mdc-tooltip.above:after {
  bottom: -0.5rem;
  right: calc(50% - 0.5rem);
  transform: rotate(180deg);
}
.mat-mdc-tooltip.right {
  overflow: initial;
  margin-left: 0.5rem;
}
.mat-mdc-tooltip.right:after {
  left: -0.75rem;
  top: calc(50% - 0.25rem);
  transform: rotate(270deg);
}
.mat-mdc-tooltip.left {
  overflow: initial;
}
.mat-mdc-tooltip.left:after {
  right: -0.75rem;
  top: calc(50% - 0.25rem);
  transform: rotate(90deg);
}
.mat-mdc-tooltip.no-overflow {
  text-overflow: unset !important;
  word-break: break-all;
}
.mat-mdc-tooltip.no-overflow:after {
  right: -0.75rem;
  top: calc(50% - 0.25rem);
  transform: rotate(90deg);
}

.example-tooltip-black {
  background: var(--text-primary);
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* Material Button-toggle style overwrite here */
.dashboard-button-toggle .mat-button-toggle-group {
  border-radius: 50px;
}
.dashboard-button-toggle .mat-button-toggle-group .mat-button-toggle .mat-button-toggle-label-content {
  line-height: 32px !important;
  font-size: 14px;
  padding: 0 20px;
}
.dashboard-button-toggle .mat-button-toggle-group .mat-button-toggle-checked {
  background-color: var(--primary-color);
  color: var(--white-primary);
}

.project-changes-custom-dialog .mat-mdc-dialog-container {
  max-height: 600px;
  overflow: hidden !important;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.mat-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.mat-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

.mat-mdc-tab-group {
  --mdc-tab-indicator-active-indicator-color: var(--primary-color);
  --mat-tab-header-active-label-text-color: var(--primary-color);
  --mat-tab-header-active-ripple-color: var(--primary-color);
  --mat-tab-header-inactive-ripple-color: var(--primary-color);
  --mat-tab-header-active-focus-label-text-color: var(--primary-color);
  --mat-tab-header-active-hover-label-text-color: var(--primary-color);
  --mat-tab-header-active-focus-indicator-color: var(--primary-color);
  --mat-tab-header-active-hover-indicator-color: var(--primary-color);
  --mat-tab-header-inactive-ripple-color: var(--text-light);
  --mat-tab-header-active-ripple-color: black;
}
.mat-mdc-tab-group.mat-primary .mat-ink-bar {
  background-color: var(--primary-color);
}
.mat-mdc-tab-group .mat-mdc-tab-header {
  border-bottom: 1px solid var(--stock-tertiary);
}
.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-list {
  margin: 0 10px;
}
.mat-mdc-tab-group .mat-mdc-tab {
  height: 40px !important;
  padding: 0 8px;
  opacity: 1;
  min-width: unset;
  flex-grow: unset !important;
}
.mat-mdc-tab-group .mat-mdc-tab .mdc-tab__text-label {
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: var(--text-hint);
  letter-spacing: 0;
}
.mat-mdc-tab-group .mat-mdc-tab.mat-mdc-tab-disabled {
  opacity: 1;
}
.mat-mdc-tab-group .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  color: var(--primary-color);
  letter-spacing: 0;
}
.mat-mdc-tab-group .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-group .mat-mdc-tab.mdc-tab--active:hover .mdc-tab-indicator__content--underline {
  border-color: var(--primary-color);
}
.mat-mdc-tab-group .mat-mdc-tab:hover .mdc-tab__ripple::before {
  opacity: 0;
}

.mat-mdc-tab-nav-bar.mat-mdc-tab-header {
  border-bottom: 1px solid var(--stock-tertiary);
}
.mat-mdc-tab-nav-bar.mat-mdc-tab-header .mat-mdc-tab-list {
  margin: 0 10px;
}
.mat-mdc-tab-nav-bar .mdc-tab {
  height: 40px !important;
  padding: 0 8px;
  opacity: 1;
  min-width: unset;
  flex-grow: unset !important;
}
.mat-mdc-tab-nav-bar .mdc-tab .mdc-tab__text-label {
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: var(--text-hint);
  letter-spacing: 0;
}
.mat-mdc-tab-nav-bar .mdc-tab.mat-mdc-tab-disabled {
  opacity: 1;
}
.mat-mdc-tab-nav-bar .mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  color: var(--primary-color);
  letter-spacing: 0;
}
.mat-mdc-tab-nav-bar .mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--primary-color) !important;
}
.mat-mdc-tab-nav-bar .mdc-tab:hover .mdc-tab__ripple::before {
  opacity: 0;
}

.project-overflow .mat-mdc-tab-group {
  height: calc(100vh - 310px);
}
.project-overflow .mat-mdc-tab-group .mat-mdc-tab-body-content {
  overflow: inherit;
}
.project-overflow.inside-provider .mat-mdc-tab-group {
  height: 100% !important;
}

.dashboard-box .view-sourcing-invitation-section-content .mat-mdc-tab-body {
  padding: 20px 20px 12px;
  width: calc(100vw - 370px);
  height: calc(100vh - 258px);
}
.dashboard-box .view-sourcing-invitation-section-content .mat-mdc-tab-body:first-child {
  padding: 0;
}
.dashboard-box .view-sourcing-invitation-section-content .mat-mdc-tab-body:first-child .mat-mdc-tab-body-content {
  height: calc(100vh - 370px);
  overflow: auto;
  padding: 20px 20px 12px !important;
}

.dashboard-box .view-projects-tab .mat-mdc-tab-body .mat-mdc-tab-body-content {
  height: calc(100vh - 306px);
  overflow: auto;
  padding: 20px !important;
}

.demand-other-tab .dashboard-box .view-projects-tab .mat-mdc-tab-body,
.sourcing-invitation-container .dashboard-box .view-projects-tab .mat-mdc-tab-body {
  padding: 0 !important;
  overflow-x: hidden;
}

.demand-other-tab .dashboard-box .view-projects-tab .mat-mdc-tab-body,
.sourcing-invitation-container .dashboard-box .view-projects-tab .mat-mdc-tab-body {
  padding: 0 !important;
}

.dashboard-box .view-project-tab.project-mangement-invoicing .mat-mdc-tab-body {
  height: 100% !important;
}

.view-sourcing-invitation-section-content.sow-layout-step .mat-mdc-tab-body-content {
  height: calc(100vh - 340px);
}

.dashboard-box.sow-layout-step .sow-tab-group .mat-mdc-tab-body {
  padding: 0;
}
.dashboard-box.sow-layout-step .sow-tab-group .mat-mdc-tab-body-content {
  height: calc(100vh - 233px);
}

.terms-condition-group .sow-container {
  height: calc(100vh - 366px);
  overflow: auto;
}

.dashboard-box.view-rfp-section-content {
  height: calc(100vh - 192px) !important;
}
.dashboard-box.view-rfp-section-content .mat-mdc-tab-body:nth-child(10) {
  padding: 0 !important;
}

.performance-tab-group .mat-mdc-tab-body {
  padding: 0;
}
.performance-tab-group .mat-mdc-tab-body-content {
  height: calc(100vh - 233px) !important;
}

.performance-questions-group .mat-mdc-tab-body {
  padding: 0;
}
.performance-questions-group .mat-mdc-tab-body-content {
  height: unset !important;
}

.view-projects-tab .mat-mdc-tab-body {
  overflow: auto;
  height: calc(100vh - 306px);
}

.view-nda-tab .mat-mdc-tab-body {
  overflow-y: auto !important;
  height: calc(100vh - 230px);
}
.view-nda-tab .mat-mdc-tab-body:first-child .mat-mdc-tab-body-content {
  height: calc(100vh - 418px);
  overflow-x: inherit;
}

.nda-container .view-sourcing-invitation-section-content .mat-mdc-tab-body-content {
  height: calc(100vh - 304px);
}

.view-msa-tab .mat-mdc-tab-body {
  padding: 0;
  overflow-y: auto !important;
  height: calc(100vh - 258px);
}
.view-msa-tab .mat-mdc-tab-body:first-child .mat-mdc-tab-body-content {
  height: calc(100vh - 418px);
  overflow-x: inherit;
}

.sow-projects-tab .mat-mdc-tab-body {
  padding: 20px 0;
  overflow-y: auto !important;
  height: calc(100vh - 434px);
}
.sow-projects-tab .mat-mdc-tab-body:first-child .mat-mdc-tab-body-content {
  height: 100%;
  width: 100vw;
  overflow-x: hidden !important;
}

.sow-settings-tab .mat-mdc-tab-body {
  padding: 20px 0;
  overflow-y: auto !important;
  height: calc(100vh - 300px);
}
.sow-settings-tab .mat-mdc-tab-body:first-child .mat-mdc-tab-body-content {
  height: 100%;
}

.conpulse-tab-group .mat-mdc-tab-group .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab-label {
  max-width: fit-content !important;
  min-width: fit-content;
  height: 40px;
  padding: 11px 8px !important;
  margin: 0 !important;
}

.rfp-tab-group .mat-mdc-tab-group .mat-mdc-tab-body {
  overflow-x: hidden;
  height: calc(100vh - 306px) !important;
}
.rfp-tab-group .mat-mdc-tab-group .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab-label {
  max-width: 100% !important;
  min-width: fit-content !important;
  padding: 11px 8px !important;
  margin: 0 !important;
}
.rfp-tab-group .mat-mdc-tab-group .mat-ink-bar {
  max-width: 100% !important;
}

.rfp-chat-group .mat-mdc-tab-group .mat-mdc-tab-body {
  overflow-x: hidden;
  height: calc(100vh - 206px) !important;
}
.rfp-chat-group .mat-mdc-tab-group .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab-label {
  max-width: 100% !important;
  min-width: fit-content !important;
  padding: 11px 8px !important;
  margin: 0 !important;
}
.rfp-chat-group .mat-mdc-tab-group .mat-ink-bar {
  max-width: 100% !important;
}

.impact-tab-group .mat-mdc-tab-group .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab-label {
  max-width: fit-content !important;
  min-width: fit-content;
  padding: 11px 8px;
  height: 40px;
}
.impact-tab-group .mat-mdc-tab-group .mat-ink-bar {
  max-width: 100% !important;
}

.tab-group.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label {
  min-width: unset;
  height: 40px !important;
}
.tab-group.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label.mat-mdc-tab-label-active {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: var(--primary-color) !important;
  opacity: 1 !important;
}
.tab-group.mat-mdc-tab-group .mat-mdc-tab-header .mat-ink-bar {
  background-color: var(--primary-color) !important;
}
.tab-group.mat-mdc-tab-group .mat-mdc-tab-body {
  padding: 16px 0;
}

.nav-tab-group .mat-mdc-tab-link {
  min-width: unset;
  height: 40px !important;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: var(--text-hint);
  opacity: 1;
}
.nav-tab-group .mat-mdc-tab-link:hover {
  color: var(--text-hint);
  text-decoration: none;
}
.nav-tab-group .mat-mdc-tab-link.mat-mdc-tab-label-active {
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  color: var(--primary-color);
  opacity: 1 !important;
}
.nav-tab-group .mat-ink-bar {
  background-color: var(--primary-color) !important;
}

.view-sourcing-invitation-section-content .mat-mdc-tab-body-content {
  height: calc(100vh - 314px);
}

.msa-tab-container {
  padding: 0;
}
.msa-tab-container .mat-mdc-tab-body-content {
  height: 100% !important;
}

.view-legal-entity-tab .mat-mdc-tab-body-content {
  padding: 0;
  overflow: auto;
  height: calc(100vh - 181px);
}

.view-client-profile-tab .mat-mdc-tab-group .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab-label {
  max-width: 160px !important;
  min-width: 160px !important;
  height: 40px;
}
.view-client-profile-tab .mat-mdc-tab-body {
  padding: 20px 0;
  overflow-y: auto !important;
  height: calc(100vh - 360px);
}
.view-client-profile-tab .mat-mdc-tab-body:first-child .mat-mdc-tab-body-content {
  height: 100%;
}

.entityscreen {
  padding: 0 !important;
}
.entityscreen .mat-drawer-container .mat-drawer-content {
  padding: 0 !important;
}
.entityscreen .view-legal-entity-tab .mat-mdc-tab-body-content {
  height: calc(100vh - 291px) !important;
}

.library-management-tab .mat-mdc-tab-body {
  padding: 0 !important;
}

.selection-criteria-group .mat-mdc-tab-body {
  padding: 20px;
  height: calc(100vh - 306px);
}

.selection-email-preview .mat-mdc-tab-body {
  height: calc(100vh - 234px);
}

.question-answer-container .view-question-answer-section-content {
  height: calc(100vh - 194px) !important;
  overflow: auto !important;
}

.sourcing-invitation-container .view-sourcing-invitation-section-content .associated-group .reference-document .sow-document {
  height: calc(100vh - 378px) !important;
}
.sourcing-invitation-container .view-sourcing-invitation-section-content .associated-group .mat-mdc-tab-body .mat-mdc-tab-body-content {
  height: calc(100vh - 233px) !important;
}

.project-management-details-tab .mat-mdc-tab-body {
  padding: 0;
}
.project-management-details-tab .mat-mdc-tab-body-content {
  height: calc(100vh - 244px) !important;
}

.risk-tab .mat-mdc-tab-group {
  height: calc(100vh - 310px);
}
.risk-tab .mat-mdc-tab-group .mat-mdc-tab-body-content {
  height: calc(100vh - 364px);
}

.gird-container .gird-holder {
  width: 320px;
  height: 320px;
  position: relative;
}
.gird-container .gird-holder .y-axis {
  position: absolute;
  right: 92%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  align-items: flex-start;
}
.gird-container .gird-holder .y-axis .p-90 {
  height: 65px;
  transform: rotate(-90deg);
}
.gird-container .gird-holder .x-axis {
  justify-content: space-around;
}
.gird-container .mat-grid-list {
  width: 100%;
  height: 100%;
}
.gird-container .mat-grid-list mat-grid-tile {
  background: var(--grey-lighten-4);
  border-radius: 8px;
  font-weight: 600;
}
.gird-container .mat-grid-list .tile-1,
.gird-container .mat-grid-list .tile-5,
.gird-container .mat-grid-list .tile-9 {
  background-color: var(--matrix-chart-orange);
}
.gird-container .mat-grid-list .tile-2,
.gird-container .mat-grid-list .tile-6 {
  background-color: var(--matrix-chart-red-shad);
}
.gird-container .mat-grid-list .tile-3 {
  background-color: var(--matrix-chart-red);
}
.gird-container .mat-grid-list .tile-4,
.gird-container .mat-grid-list .tile-8 {
  background-color: var(--matrix-chart-green-shad);
}
.gird-container .mat-grid-list .tile-7 {
  background-color: var(--matrix-chart-green);
}

.selection-specific-question .mat-mdc-tab-body {
  padding: 20px;
  height: calc(100vh - 315px);
}
.selection-specific-question .mat-mdc-tab-body .mat-mdc-tab-body-content {
  overflow-y: hidden;
}

.selection-specific-question-proposal .mat-mdc-tab-body {
  padding: 20px;
  height: calc(100vh - 235px);
}
.selection-specific-question-proposal .mat-mdc-tab-body .mat-mdc-tab-body-content {
  overflow-y: hidden;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.mat-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.mat-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* ==============================
   CHECKOUT SPECIFIC COLORS
---------------------------------*/
:root {
  /* ==============================
     OLD COLORS
     TODO:- Check the usage and remove
  ---------------------------------*/
  --secondary-color-04: rgba(103, 0, 51, 0.04);
  --background-color: #f9f2f5;
  --background-light-blue: #f2f6f9;
  --bg-light-white: #F7FAFC;
  --bg-reddish-orange: #fff1e6;
  --text-grey-primary-1: #03011e;
  --text-grey-primary-2: #434850;
  --text-grey-secondary: rgb(66, 66, 66);
  --text-grey-secondary-50: rgba(66, 66, 66, 0.5);
  --text-grey-secondary-1: rgb(74, 74, 74);
  --text-grey-secondary-1-08: rgba(74, 74, 74, 0.08);
  --text-grey-hint: rgb(155, 155, 155);
  --text-grey-hint-20: rgba(155, 155, 155, 0.2);
  --text-grey-content: rgb(127, 127, 127);
  --text-grey-content-10: rgba(127, 127, 127, 0.1);
  --text-grey-line: rgb(187, 186, 186);
  --text-grey-line-65: rgba(187, 186, 186, 0.65);
  --text-grey-progress: #838383;
  --grey-darken-1: #333333;
  --grey-darken-2: #666666;
  --grey-darken-4: #18162d;
  --grey-darken-5: #22293d;
  --grey-darken-7: #e1e1e163;
  --grey-darken-8: #06172c;
  --grey-lighten-2: #e2e2e2;
  --grey-lighten-4: #e9ebee;
  --grey-lighten-8: #e5e5e5;
  --grey-lighten-11: #d9d9d9;
  --grey-lighten-12: #b9babd;
  --grey-lighten-13: #5b5f66;
  --grey-lighten-2-60: rgba(226, 226, 226, 0.6);
  --grey-lighten-2-50: rgba(226, 226, 226, 0.5);
  --grey-lighten-2-16: rgba(226, 226, 226, 0.16);
  --grey-lighten-2-05: rgba(226, 226, 226, 0.05);
  --grey-lighten-6-01: rgba(215, 215, 215, 0.01);
  --skeleton-loader: #f6f7f9;
  --dividerGrey: rgba(0, 0, 0, 0.3);
  --black-opacity-10: rgba(0, 0, 0, 0.1);
  --dividerPurple: rgba(100, 28, 255, 0.19);
  --red: rgb(224, 0, 52);
  --red-40: rgba(224, 0, 52, 0.4);
  --red-20: rgba(224, 0, 52, 0.2);
  --red-16: rgba(224, 0, 52, 0.16);
  --red-15: rgba(224, 0, 52, 0.15);
  --red-14: rgba(224, 0, 52, 0.14);
  --red-10: rgba(224, 0, 52, 0.1);
  --red-08: rgba(224, 0, 52, 0.08);
  --red-05: rgba(224, 0, 52, 0.05);
  --red-lighten1: rgb(255, 166, 160);
  --red-lighten2: rgb(255, 240, 240);
  --red-lighten3: rgba(224, 0, 52, 0.08);
  --red-lighten5: rgba(224, 0, 52, 0.2);
  --red-lighten6: rgba(255,119,0,0.2);
  --green-lighten-bg: rgba(224, 245, 236, 1);
  --green: rgb(0, 173, 98);
  --green-50: rgba(0, 173, 98, 0.5);
  --green-40: rgba(0, 173, 98, 0.4);
  --green-20: rgba(0, 175, 98, 0.2);
  --green-16: rgba(0, 173, 98, 0.16);
  --green-15: rgba(0, 173, 98, 0.15);
  --green-10: rgba(0, 173, 98, 0.1);
  --green-05: rgba(0, 173, 98, 0.05);
  --green-04: rgba(0, 175, 98, 0.04);
  --green-03: rgba(0, 175, 98, 1);
  --green-lighten1: #7ed321;
  --green-lighten4:rgba(0, 175, 99, 0.2);
  --green-lighten2: rgb(188, 238, 185);
  --green-lighten2-40: rgba(188, 238, 185, 0.4);
  --green-lighten3: rgba(0, 175, 99, 0.15);
  --green-dark: rgba(0, 175, 99, 100);
  --blue: rgb(10, 161, 251);
  --blue-40: rgba(10, 161, 251, 0.4);
  --blue-20: rgba(10, 161, 251, 0.2);
  --blue-16: rgba(10, 161, 251, 0.16);
  --blue-15: rgba(10, 161, 251, 0.15);
  --blue-05: rgba(10, 161, 251, 0.05);
  --blue-12: rgba(10, 161, 251, 0.12);
  --blue-01: rgba(10, 161, 251, 1);
  --blue-lighten1: #9cddfa;
  --blue-lighten2: #e3f2fd;
  --blue-lighten3: rgba(255, 119, 0, 0.15);
  --blue-bg-light: #F0FAFB;
  --warn-color: rgb(252, 87, 94);
  --warn-color-50: rgba(252, 87, 94, 0.5);
  --warning-color-dark: #fc854a;
  --pink-color: #fd73a1;
  --yellow: rgb(255, 194, 51);
  --yellow-20: rgba(255, 194, 51, 0.2);
  --pink-legend-color: rgba(224, 0, 52, 0.2);
  --dark-green: rgb(0, 171, 187);
  --dark-green-80: rgb(0, 171, 187, 0.8);
  --dark-green-20: rgb(0, 171, 187, 0.2);
  --dark-green-16: rgb(0, 171, 187, 0.16);
  --dark-green-14: rgb(0, 171, 187, 0.14);
  --dark-green-12: rgb(0, 171, 187, 0.12);
  --dark-green-10: rgb(0, 171, 187, 0.1);
  --dark-green-lighten: rgba(0, 171, 187, 0.06);
  --orange-color: rgb(255, 119, 0);
  --orange-color-40: rgba(255, 119, 0, 0.4);
  --orange-color-20: rgba(255, 119, 0, 0.2);
  --orange-color-16: rgba(255, 119, 0, 0.16);
  --orange-color-15: rgba(255, 119, 0, 0.15);
  --orange-color-14: rgba(255, 119, 0, 0.14);
  --orange-color-10: rgba(255, 119, 0, 0.1);
  --orange-color-05: rgba(255, 119, 0, 0.05);
  --light-green: #4cd964;
  --light-green-12: rgba(0, 175, 99, 0.12);
  --peak-green: rgb(0, 127, 139);
  --peak-green-15: rgba(0, 127, 139, 0.15);
  --peak-green-10: rgba(0, 127, 139, 0.1);
  --peak-green-08: rgba(0, 127, 139, 0.08);
  --peak-green-05: rgba(0, 127, 139, 0.05);
  --dark-pink: rgb(189, 16, 224);
  --dark-pink-15: rgba(189, 16, 224, 0.15);
  --dark-pink-05: rgba(189, 16, 224, 0.05);
  --dark-yellow: rgb(255, 188, 0);
  --dark-yellow-15: rgba(255, 188, 0, 0.15);
  --dark-yellow-05: rgba(255, 188, 0, 0.05);
  --dark-red: rgb(208, 2, 27);
  --dark-red-05: rgba(208, 2, 27, 0.05);
  --royal-blue: rgb(0, 19, 255);
  --royal-blue-05: rgba(0, 19, 255, 0.05);
  --dark-blue: rgb(0, 82, 130);
  --dark-blue-05: rgba(0, 82, 130, 0.05);
  --darken-blue: #0066a1;
  --border-grey: #e3e3e3;
  --light-grey-colors: #aaa;
  /*===============================
    Social Colors
  ----------------------------------*/
  --fb-color: #3b5998;
  --tw-color: #55acee;
  --gplus-color: #dd4b39;
  --yt-color: #cd201f;
  --li-color: #0082ca;
  --pin-color: #c61118;
  --ins-color: #3f729b;
  --git-color: #4183c4;
  --comm-color: #30cfc0;
  --vk-color: #4c75a3;
  --drib-color: #c32361;
  --error-red: rgba(224, 0, 52, 0.12);
  --note-bg-yellow: #fffae5;
  --matrix-chart-orange: #ff9233;
  --matrix-chart-red: #fa003a;
  --matrix-chart-red-shad: #ff551ff0;
  --matrix-chart-green: #007242;
  --matrix-chart-green-shad: #838e17d6;
  /* ==============================
    NEW DESIGN v2 COLORS
    Primary
  ---------------------------------*/
  --primary-color: #FF7700;
  --primary-color-03: #FF770008;
  --primary-color-05: #FF77000D;
  --primary-color-06: #FF77000F;
  --primary-color-10: #FF77001A;
  --primary-color-12: #FF77001F;
  --primary-color-15: #FF770026;
  --primary-color-16: #FF770029;
  --primary-color-20: #FF770033;
  --primary-color-40: #FF770066;
  --primary-color-70: #FF7700B3;
  --primary-light: #FF9233;
  --primary-hint-3: #FFF1E6;
  --primary-hint-4: #fff7f0;
  /* ==============================
    NEW DESIGN v2 COLORS
    Secondary
  ---------------------------------*/
  --secondary-color: #00ABBB;
  --secondary-color-1: #00abbb1a;
  --secondary-dark-color: #008996;
  --secondary-darker-color: #006770;
  --secondary-light-color: #2EBBC9;
  --secondary-hint-2: #CDF5F8;
  --secondary-hint-3: #ECF9FA;
  --secondary-hint-4: #F8FCFD;
  --secondary-hint-5: #f0fafb;
  /* ==============================
    NEW DESIGN v2 COLORS
    Text colors
  ---------------------------------*/
  --text-primary: #0C121D;
  --text-secondary: #3D414A;
  --text-tertiary: #61656C;
  --text-warn: #E88B00;
  --text-light: #AAACB0;
  --text-hint: #85888E;
  --text-success: #00AF62;
  --text-error: #E00034;
  --text-grey-primary-3: #11161f;
  --text-grey-primary-4: #424242;
  /* ==============================
    NEW DESIGN v2 COLORS
    Background colors
  ---------------------------------*/
  --background-darker: #D5DDE2;
  --background-dark: #E6EBEE;
  --background-primary: #F2F7FA;
  /* ==============================
    NEW DESIGN v2 COLORS
    Stock colors
  ---------------------------------*/
  --stock-primary: #C2C4C6;
  --stock-secondary: #CED0D2;
  --stock-tertiary: #DBDBDD;
  --stock-hint: #E7E7E8;
  /* ==============================
    NEW DESIGN v2 COLORS
    Error colors
  ---------------------------------*/
  --error-primary: #E00034;
  --error-secondary: #FF003B;
  --error-tertiary: #FBE0E7;
  --error-dark: #C7002E;
  --error-hint: #FDF0F3;
  --error-primary-01: #E000341F;
  --error-light: #FA003A;
  --error-secondary-01: #ff1a0030;
  /* ==============================
    NEW DESIGN v2 COLORS
    Success colors
  ---------------------------------*/
  --success-primary: #00AF62;
  --success-secondary: #E0F5EC;
  --success-tertiary: #F0FAF6;
  --success-dark: #008F52;
  --success-primary-1: #00c851;
  --success-dark-1: #00af621f;
  --success-dark-2: #00af621a;
  --success-lighten: #8bf18ba6;
  /* ==============================
    NEW DESIGN v2 COLORS
    Warn colors
  ---------------------------------*/
  --warn-primary: #E88B00;
  --warn-secondary: #FFFAE5;
  --warn-tertiary: #BD7100;
  --warning-color-lighten: #ffcc00;
  --warning-color-lighten-1: #ffcc0033;
  --warn-secondary-2: #fff2d9;
  /* ==============================
    NEW DESIGN v2 COLORS
    Other colors
  ---------------------------------*/
  --white-primary: #FFFFFF;
  --white-color-76: rgba(255, 255, 255, 0.76);
  --white-color-73: rgba(255, 255, 255, 0.73);
  --white-color-60: rgba(255, 255, 255, 0.6);
  --white-color-56: rgba(255, 255, 255, 0.56);
  --white-color-21: rgba(255, 255, 255, 0.21);
  --white-color-20: rgba(255, 255, 255, 0.2);
  --white-color-17: rgba(255, 255, 255, 0.17);
  --white-lilac: #f6f6f6;
  --white-lavender: #e8e8e9;
  --white-hint-1: #D3D6DB;
  --black-primary: #000000;
  --black-color-90: rgba(0, 0, 0, 0.9);
  --black-color-87: rgba(0, 0, 0, 0.87);
  --black-color-85: rgba(0, 0, 0, 0.85);
  --black-color-80: rgba(0, 0, 0, 0.8);
  --black-color-45: rgba(0, 0, 0, 0.45);
  --black-color-40: rgba(0, 0, 0, 0.4);
  --black-color-36: rgba(0, 0, 0, 0.36);
  --black-color-30: rgba(0, 0, 0, 0.3);
  --black-color-24: rgba(0, 0, 0, 0.24);
  --black-color-21: rgba(0, 0, 0, 0.21);
  --black-color-20: rgba(0, 0, 0, 0.2);
  --black-color-16: rgba(0, 0, 0, 0.16);
  --black-color-14: rgba(0, 0, 0, 0.14);
  --black-color-15: rgba(0, 0, 0, 0.15);
  --black-color-12: rgba(0, 0, 0, 0.12);
  --black-color-10: rgba(0, 0, 0, 0.1);
  --black-color-60: rgba(0, 0, 0, 0.6);
  --black-color-65: rgba(0, 0, 0, 0.65);
  --black-color-07: rgba(0, 0, 0, 0.07);
  --black-color-06: rgba(0, 0, 0, 0.06);
  --black-color-05: rgba(0, 0, 0, 0.05);
  --black-color-03: rgba(0, 0, 0, 0.03);
  --black-color-54: rgba(0, 0, 0, 0.54);
  --grey-color-51: rgba(225, 225, 225, 0.51);
  --grey-lighten-1: #cccccc;
  --grey-lighten-3: #f7f7f7;
  --grey-lighten-5: #f0f0f0;
  --grey-lighten-7: #7f7f7f;
  --grey-lighten-6: #d7d7d7;
  --grey-lighten-9: #8a8d92;
  --grey-lighten-14: #f9f9f9;
  --grey-lighten-15: #fafafa;
  --grey-lighten-16: #cdd1d5;
  --grey-lighten-17: #f8f8f8;
  --grey-lighten-18: #d8d8d8;
  --grey-lighten-19: #eeeff0;
  --grey-darken-3: #999999;
  --grey-darken-6: #e1e1e1;
  --grey-darken-9: #a1a3a7;
  --grey-darken-10: #595959;
  --grey-darken-11: #72767c;
  --grey-darken-12: #cac9c9bf;
  --grey-darken-13: #cccccc4d;
  --grey-hint-20: #9b9b9b4d;
  --grey-hint-10: #9b9b9b1a;
  --blue-lighten5: #007883;
  --blue-lighten-10: #00abbb0f;
  --blue-color-fb: #0aa1fb;
  --blue-darken-1: #00457f1a;
  --blue-darken-2: #0990e0;
  --blue-darken-3: #11161fb3;
  --blue-darken-4: #11161fb3;
  --blue-lighten-2: #e0f5f7;
  --dark-green-63: #00af63;
  --dark-green-631f: #00af631f;
  --green-darken-1: #25b09b;
  --green-lighten-bg: #eaf8f3;
  --red-lighten-bg: #fbf0f3;
  --yellow-lighten-1: #ebd997;
  --orange-lighten-01: #cf2c170d;
  --bg-note-color: #FEFBE7;
  --notice-bg-color: #ffcc001a;
  --notice-color: #f6be2c33;
  --note-border-color: #fceec1;
  --note-bg-color-1: #fefae6;
  --lavender-tag: #8D73FF;
  --lavender-tag-bg: #A894FF1F;
}

.client-app {
  --primary-color: #FF7700;
  --primary-color-03: #FF770008;
  --primary-color-05: #FF77000D;
  --primary-color-06: #FF77000F;
  --primary-color-10: #FF77001A;
  --primary-color-12: #FF77001F;
  --primary-color-15: #FF770026;
  --primary-color-16: #FF770029;
  --primary-color-20: #FF770033;
  --primary-color-40: #FF770066;
  --primary-color-70: #FF7700B3;
  --primary-light: #FF9233;
}

.table-with-white-bg .mat-mdc-table,
.table-with-grey-bg .mat-mdc-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid var(--stock-hint);
  border-radius: 8px;
  overflow: hidden;
}
.table-with-white-bg .mat-mdc-table tr th,
.table-with-grey-bg .mat-mdc-table tr th {
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  color: var(--text-primary);
  text-transform: uppercase;
  background-color: var(--white-primary);
}
.table-with-white-bg .mat-mdc-table tr td,
.table-with-grey-bg .mat-mdc-table tr td {
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  color: var(--text-tertiary);
}
.table-with-white-bg .mat-mdc-table tr.mat-mdc-header-row,
.table-with-grey-bg .mat-mdc-table tr.mat-mdc-header-row {
  height: 40px;
}
.table-with-white-bg .mat-mdc-table tr.mat-mdc-header-row .mat-mdc-header-cell,
.table-with-grey-bg .mat-mdc-table tr.mat-mdc-header-row .mat-mdc-header-cell {
  border-bottom: 1px solid var(--stock-hint);
}
.table-with-white-bg .mat-mdc-table tr.mat-mdc-row,
.table-with-grey-bg .mat-mdc-table tr.mat-mdc-row {
  height: 48px !important;
  background-color: var(--white-primary);
}
.table-with-white-bg .mat-mdc-table tr.mat-mdc-row.example-detail-row,
.table-with-grey-bg .mat-mdc-table tr.mat-mdc-row.example-detail-row {
  height: 0 !important;
}
.table-with-white-bg .mat-mdc-table tr.mat-mdc-row.example-detail-row td,
.table-with-grey-bg .mat-mdc-table tr.mat-mdc-row.example-detail-row td {
  height: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}
.table-with-white-bg .mat-mdc-table tr.mat-mdc-row.example-element-row td,
.table-with-grey-bg .mat-mdc-table tr.mat-mdc-row.example-element-row td {
  border-bottom-width: 0;
}
.table-with-white-bg .mat-mdc-table tr.mat-mdc-row .mat-mdc-cell,
.table-with-grey-bg .mat-mdc-table tr.mat-mdc-row .mat-mdc-cell {
  border-bottom-color: --border-light-grey;
  background-color: unset;
}
.table-with-white-bg .mat-mdc-table tr.mat-mdc-row:hover, .table-with-white-bg .mat-mdc-table tr.mat-mdc-row.active,
.table-with-grey-bg .mat-mdc-table tr.mat-mdc-row:hover,
.table-with-grey-bg .mat-mdc-table tr.mat-mdc-row.active {
  background-color: var(--bg-light-white);
}
.table-with-white-bg .mat-mdc-table th.mat-mdc-header-cell:first-of-type,
.table-with-white-bg .mat-mdc-table td.mat-mdc-cell:first-of-type,
.table-with-grey-bg .mat-mdc-table th.mat-mdc-header-cell:first-of-type,
.table-with-grey-bg .mat-mdc-table td.mat-mdc-cell:first-of-type {
  padding-left: 12px;
}
.table-with-white-bg .mat-mdc-table th.mat-mdc-header-cell:last-of-type,
.table-with-white-bg .mat-mdc-table td.mat-mdc-cell:last-of-type,
.table-with-grey-bg .mat-mdc-table th.mat-mdc-header-cell:last-of-type,
.table-with-grey-bg .mat-mdc-table td.mat-mdc-cell:last-of-type {
  padding: 8px 12px;
}

.table-with-grey-bg .mat-mdc-table tr th {
  background-color: var(--background-primary);
}

.table-data {
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  color: var(--text-primary);
}

/* Table selection box styles here */
.table-action {
  padding: 6px 12px;
  background-color: var(--white-primary);
  border-radius: 5px;
}
.table-action .count {
  width: 30px;
  height: 30px;
  font-size: 13px;
  line-height: 30px;
  font-weight: 700;
  color: var(--text-primary);
  border-radius: 6px;
  background: var(--white-lilac);
}
.table-action .page-title1 {
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
  color: var(--black-primary);
}
.table-action .info {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--text-primary);
}
.table-action-grey {
  background-color: var(--background-light-blue);
}
.table-action-grey .count {
  background: var(--white-primary);
}

/* Strategic priorities table styles here */
.strategic-priorities-table-responsive {
  height: calc(100vh - 202px) !important;
}
.strategic-priorities-table-responsive tr th:nth-child(1),
.strategic-priorities-table-responsive tr td:nth-child(1) {
  width: 60px;
}
.strategic-priorities-table-responsive tr th:nth-child(2),
.strategic-priorities-table-responsive tr td:nth-child(2) {
  min-width: 100px;
  max-width: 100px;
}
.strategic-priorities-table-responsive tr th:nth-child(3), .strategic-priorities-table-responsive tr th:nth-child(4), .strategic-priorities-table-responsive tr th:nth-child(5), .strategic-priorities-table-responsive tr th:nth-child(6), .strategic-priorities-table-responsive tr th:nth-child(8),
.strategic-priorities-table-responsive tr td:nth-child(3),
.strategic-priorities-table-responsive tr td:nth-child(4),
.strategic-priorities-table-responsive tr td:nth-child(5),
.strategic-priorities-table-responsive tr td:nth-child(6),
.strategic-priorities-table-responsive tr td:nth-child(8) {
  min-width: 150px;
  max-width: 150px;
}
.strategic-priorities-table-responsive tr th:last-child,
.strategic-priorities-table-responsive tr td:last-child {
  width: 100px;
  text-align: center;
  padding: 9px 12px;
}
.strategic-priorities-table-responsive tr th .more-actions,
.strategic-priorities-table-responsive tr th .view-action,
.strategic-priorities-table-responsive tr td .more-actions,
.strategic-priorities-table-responsive tr td .view-action {
  color: var(--grey-lighten-7);
  display: none;
}
.strategic-priorities-table-responsive tr th .more-actions:hover,
.strategic-priorities-table-responsive tr th .view-action:hover,
.strategic-priorities-table-responsive tr td .more-actions:hover,
.strategic-priorities-table-responsive tr td .view-action:hover {
  background-color: var(--black-color-05);
}
.strategic-priorities-table-responsive tr:hover .more-actions,
.strategic-priorities-table-responsive tr:hover .view-action {
  display: inline-table;
}
.strategic-priorities-table-responsive tr .more-actions[aria-expanded=true] {
  display: inline-table;
  background-color: var(--black-color-05);
}
.strategic-priorities-table-responsive.table-select-item {
  height: calc(100vh - 256px) !important;
}

.strategic-priorities-table-responsive-no-select table tr td:nth-child(1),
.strategic-priorities-table-responsive-no-select table tr th:nth-child(1) {
  min-width: 100px;
  max-width: 100px;
}
.strategic-priorities-table-responsive-no-select table tr td:nth-child(2), .strategic-priorities-table-responsive-no-select table tr td:nth-child(3), .strategic-priorities-table-responsive-no-select table tr td:nth-child(4), .strategic-priorities-table-responsive-no-select table tr td:nth-child(5), .strategic-priorities-table-responsive-no-select table tr td:nth-child(6), .strategic-priorities-table-responsive-no-select table tr td:nth-child(7),
.strategic-priorities-table-responsive-no-select table tr th:nth-child(2),
.strategic-priorities-table-responsive-no-select table tr th:nth-child(3),
.strategic-priorities-table-responsive-no-select table tr th:nth-child(4),
.strategic-priorities-table-responsive-no-select table tr th:nth-child(5),
.strategic-priorities-table-responsive-no-select table tr th:nth-child(6),
.strategic-priorities-table-responsive-no-select table tr th:nth-child(7) {
  min-width: 150px;
  max-width: 150px;
}
.strategic-priorities-table-responsive-no-select table tr td:last-child,
.strategic-priorities-table-responsive-no-select table tr th:last-child {
  width: 100px;
  text-align: center;
  padding: 9px 12px;
}
.strategic-priorities-table-responsive-no-select table tr td .more-actions,
.strategic-priorities-table-responsive-no-select table tr td .view-action,
.strategic-priorities-table-responsive-no-select table tr th .more-actions,
.strategic-priorities-table-responsive-no-select table tr th .view-action {
  color: var(--grey-lighten-7);
  display: none;
}
.strategic-priorities-table-responsive-no-select table tr td .more-actions:hover,
.strategic-priorities-table-responsive-no-select table tr td .view-action:hover,
.strategic-priorities-table-responsive-no-select table tr th .more-actions:hover,
.strategic-priorities-table-responsive-no-select table tr th .view-action:hover {
  background-color: var(--black-color-05);
}
.strategic-priorities-table-responsive-no-select table tr:hover .more-actions,
.strategic-priorities-table-responsive-no-select table tr:hover .view-action {
  display: inline-table;
}
.strategic-priorities-table-responsive-no-select table tr .more-actions[aria-expanded=true] {
  display: inline-table;
  background-color: var(--black-color-05);
}

/* Legal entities table styles here */
.legal-entity-table {
  height: calc(100vh - 330px) !important;
}
.legal-entity-table tr th:nth-child(1), .legal-entity-table tr th:nth-child(2), .legal-entity-table tr th:nth-child(3),
.legal-entity-table tr td:nth-child(1),
.legal-entity-table tr td:nth-child(2),
.legal-entity-table tr td:nth-child(3) {
  min-width: 150px;
  max-width: 150px;
}
.legal-entity-table tr th:nth-child(4),
.legal-entity-table tr td:nth-child(4) {
  min-width: 130px;
  max-width: 130px;
}
.legal-entity-table tr th:nth-child(7), .legal-entity-table tr th:nth-child(9),
.legal-entity-table tr td:nth-child(7),
.legal-entity-table tr td:nth-child(9) {
  min-width: 120px;
  max-width: 120px;
}
.legal-entity-table tr th:nth-child(5), .legal-entity-table tr th:nth-child(6), .legal-entity-table tr th:nth-child(8),
.legal-entity-table tr td:nth-child(5),
.legal-entity-table tr td:nth-child(6),
.legal-entity-table tr td:nth-child(8) {
  min-width: 100px;
  max-width: 100px;
}
.legal-entity-table tr th:nth-child(10),
.legal-entity-table tr td:nth-child(10) {
  width: 100px;
}
.legal-entity-table tr th:last-child,
.legal-entity-table tr td:last-child {
  text-align: center;
}
.legal-entity-table tr th .more-actions,
.legal-entity-table tr td .more-actions {
  color: var(--grey-lighten-7);
  display: none;
}
.legal-entity-table tr th .more-actions:hover,
.legal-entity-table tr td .more-actions:hover {
  background-color: var(--black-color-05);
}
.legal-entity-table tr:hover .more-actions {
  display: inline-table;
}
.legal-entity-table tr .more-actions[aria-expanded=true] {
  display: inline-table;
  background-color: var(--black-color-05);
}

.demand-other-tab .dashboard-box .view-projects-tab .mat-mdc-tab-body .table-section {
  height: auto;
}

.entityscreen .admin-entity-billing-table-responsive {
  height: calc(100vh - 444px) !important;
}

.entityscreen .admin-entity-billing-table-responsive {
  height: calc(100vh - 444px) !important;
}

.reference-document .table .sow-document {
  height: calc(100vh - 500px) !important;
}

.project-manage-invocing-tab .table-section {
  height: calc(100vh - 338px) !important;
}

.selection-group .result-table .mat-mdc-table tr th:first-child,
.selection-group .result-table .mat-mdc-table tr td:first-child {
  width: 80px;
  padding: 9px 12px;
}
.selection-group .result-table .mat-mdc-table tr th:nth-child(2),
.selection-group .result-table .mat-mdc-table tr td:nth-child(2) {
  min-width: 50px;
  max-width: 50px;
}
.selection-group .result-table .mat-mdc-table tr th:nth-child(3), .selection-group .result-table .mat-mdc-table tr th:nth-child(6), .selection-group .result-table .mat-mdc-table tr th:nth-child(7),
.selection-group .result-table .mat-mdc-table tr td:nth-child(3),
.selection-group .result-table .mat-mdc-table tr td:nth-child(6),
.selection-group .result-table .mat-mdc-table tr td:nth-child(7) {
  min-width: 150px;
  max-width: 150px;
}
.selection-group .result-table .mat-mdc-table tr th:nth-child(4), .selection-group .result-table .mat-mdc-table tr th:nth-child(5),
.selection-group .result-table .mat-mdc-table tr td:nth-child(4),
.selection-group .result-table .mat-mdc-table tr td:nth-child(5) {
  min-width: 100px;
  max-width: 100px;
}
.selection-group .result-table .mat-mdc-table tr th:nth-child(8),
.selection-group .result-table .mat-mdc-table tr td:nth-child(8) {
  min-width: 150px;
  max-width: 150px;
}
.selection-group .result-table .mat-mdc-table tr th:last-child,
.selection-group .result-table .mat-mdc-table tr td:last-child {
  width: 100px;
  padding: 9px 12px;
  text-align: center;
}
.selection-group .result-table .mat-mdc-table tr:hover .selection-drag {
  display: inline-table !important;
}
.selection-group .result-table .mat-mdc-table tr:hover .more-actions {
  display: inline-table !important;
}
.selection-group .result-table .mat-mdc-table .selection-drag {
  color: var(--grey-lighten-7);
  display: none;
}
.selection-group .result-table .mat-mdc-table .more-actions {
  color: var(--grey-lighten-7);
  display: none;
}
.selection-group .result-table .mat-mdc-table .more-actions:hover {
  background-color: var(--black-color-05);
}
.selection-group .result-table .more-actions[aria-expanded=true] {
  display: inline-table !important;
  background-color: var(--black-color-05);
}
.selection-group .result-table-without-score .mat-mdc-table tr th:first-child,
.selection-group .result-table-without-score .mat-mdc-table tr td:first-child {
  width: 80px;
  padding: 9px 12px;
}
.selection-group .result-table-without-score .mat-mdc-table tr th:nth-child(2),
.selection-group .result-table-without-score .mat-mdc-table tr td:nth-child(2) {
  min-width: 50px;
  max-width: 50px;
}
.selection-group .result-table-without-score .mat-mdc-table tr th:nth-child(3), .selection-group .result-table-without-score .mat-mdc-table tr th:nth-child(7), .selection-group .result-table-without-score .mat-mdc-table tr th:nth-child(8),
.selection-group .result-table-without-score .mat-mdc-table tr td:nth-child(3),
.selection-group .result-table-without-score .mat-mdc-table tr td:nth-child(7),
.selection-group .result-table-without-score .mat-mdc-table tr td:nth-child(8) {
  min-width: 150px;
  max-width: 150px;
}
.selection-group .result-table-without-score .mat-mdc-table tr th:nth-child(5), .selection-group .result-table-without-score .mat-mdc-table tr th:nth-child(6),
.selection-group .result-table-without-score .mat-mdc-table tr td:nth-child(5),
.selection-group .result-table-without-score .mat-mdc-table tr td:nth-child(6) {
  min-width: 100px;
  max-width: 100px;
}
.selection-group .result-table-without-score .mat-mdc-table tr th:nth-child(9),
.selection-group .result-table-without-score .mat-mdc-table tr td:nth-child(9) {
  min-width: 150px;
  max-width: 150px;
}
.selection-group .result-table-without-score .mat-mdc-table tr th:last-child,
.selection-group .result-table-without-score .mat-mdc-table tr td:last-child {
  width: 100px;
  padding: 9px 12px;
  text-align: center;
}
.selection-group .result-table-without-score .mat-mdc-table tr:hover .selection-drag {
  display: inline-table !important;
}
.selection-group .result-table-without-score .mat-mdc-table tr:hover .more-actions {
  display: inline-table !important;
}
.selection-group .result-table-without-score .mat-mdc-table .selection-drag {
  color: var(--grey-lighten-7);
  display: none;
}
.selection-group .result-table-without-score .mat-mdc-table .more-actions {
  color: var(--grey-lighten-7);
  display: none;
}
.selection-group .result-table-without-score .mat-mdc-table .more-actions:hover {
  background-color: var(--black-color-05);
}
.selection-group .result-table-without-score .more-actions[aria-expanded=true] {
  display: inline-table !important;
  background-color: var(--black-color-05);
}

.currency-header-sticky {
  height: calc(100vh - 356px) !important;
}
.currency-header-sticky .currency-conversion {
  height: auto !important;
  max-height: calc(100vh - 406px);
  border: 1px solid var(--stock-hint);
  border-radius: 8px;
}
.currency-header-sticky .mat-mdc-table {
  border: none;
  overflow: auto !important;
}
.currency-header-sticky .mat-mdc-table tr:last-of-type {
  border-bottom: none;
}
.currency-header-sticky .mat-mdc-table tr:last-of-type td {
  border-bottom: none;
}

.impact-tracing-table table th {
  background-color: var(--background-primary) !important;
}

.impact-tracing-table {
  overflow-x: auto;
}
.impact-tracing-table table {
  width: auto !important;
}
.impact-tracing-table table .mat-mdc-header-row {
  height: 40px;
  background-color: var(--background-primary) !important;
}
.impact-tracing-table table .mat-mdc-header-row .mat-mdc-header-cell {
  padding: 12px;
  color: var(--text-grey-primary-4) !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  min-width: 100px;
  max-width: 100px;
  border-bottom-style: none;
}
.impact-tracing-table table .mat-mdc-header-row .mat-mdc-header-cell:first-of-type {
  padding-left: 16px !important;
  min-width: 150px;
  max-width: 150px;
}
.impact-tracing-table table .mat-mdc-row {
  align-items: flex-start;
}
.impact-tracing-table table .mat-mdc-row:last-of-type td:last-of-type .more-actions {
  opacity: 0;
  pointer-events: none;
}
.impact-tracing-table table .mat-mdc-row td .more-actions,
.impact-tracing-table table .mat-mdc-row td .view-action {
  color: var(--grey-lighten-7);
  border-radius: 50%;
  display: none;
}
.impact-tracing-table table .mat-mdc-row td .more-actions:hover,
.impact-tracing-table table .mat-mdc-row td .view-action:hover {
  background-color: var(--black-color-05);
}
.impact-tracing-table table .mat-mdc-row:hover td {
  background-color: var(--background-primary) !important;
}
.impact-tracing-table table .mat-mdc-row:hover .more-actions,
.impact-tracing-table table .mat-mdc-row:hover .view-action {
  display: inline-table;
}
.impact-tracing-table table .mat-mdc-row .more-actions[aria-expanded=true] {
  display: inline-table;
  background-color: var(--black-color-05);
}
.impact-tracing-table table .mat-mdc-cell {
  padding: 12px;
  min-width: 100px;
  max-width: 100px;
  word-break: break-all;
  border-bottom-style: none;
}
.impact-tracing-table table .mat-mdc-cell:first-of-type {
  padding-left: 16px !important;
  min-width: 150px;
  max-width: 150px;
}
.impact-tracing-table table tr .mat-mdc-cell {
  padding: 12px;
}

.contract-container table {
  border: 1px solid var(--stock-hint);
  border-radius: 5px;
}
.contract-container table thead {
  position: sticky;
  top: 0;
}
.contract-container table thead th {
  background-color: var(--background-primary) !important;
  border-right: 1px solid #eef3f7;
  color: var(--text-primary) !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}
.contract-container table thead th.mat-mdc-header-cell:first-of-type {
  padding-left: 16px;
}
.contract-container table tr th:nth-child(1),
.contract-container table tr td:nth-child(1) {
  min-width: 130px;
  max-width: 130px;
}
.contract-container table tr th:nth-child(2),
.contract-container table tr td:nth-child(2) {
  min-width: 75px;
  max-width: 75px;
}
.contract-container table tr th:nth-child(3),
.contract-container table tr td:nth-child(3) {
  min-width: 80px;
  max-width: 80px;
}
.contract-container table tr th:nth-child(4),
.contract-container table tr td:nth-child(4) {
  min-width: 100px;
  max-width: 100px;
}
.contract-container table tr th:last-child,
.contract-container table tr td:last-child {
  text-align: center;
}
.contract-container table tr th .more-actions,
.contract-container table tr td .more-actions {
  color: var(--grey-lighten-7);
  display: none;
}
.contract-container table tr th .more-actions:hover,
.contract-container table tr td .more-actions:hover {
  background-color: var(--black-color-05);
}
.contract-container table tr:hover .more-actions {
  display: inline-table;
}
.contract-container table tr .more-actions[aria-expanded=true] {
  display: inline-table;
  background-color: var(--black-color-05);
}

.color-table {
  height: calc(100vh - 381px) !important;
  overflow: auto;
}
.color-table table .color .color-badge {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 1px solid;
  display: block;
  margin-right: 2px;
}
.color-table table .mat-mdc-row {
  align-items: flex-start;
}
.color-table table .mat-mdc-row td .more-actions,
.color-table table .mat-mdc-row td .view-action {
  color: var(--grey-lighten-7);
  border-radius: 50%;
  display: none;
}
.color-table table .mat-mdc-row td .more-actions:hover,
.color-table table .mat-mdc-row td .view-action:hover {
  background-color: var(--black-color-05);
}
.color-table table .mat-mdc-row:hover td {
  background-color: var(--background-primary) !important;
}
.color-table table .mat-mdc-row:hover .more-actions,
.color-table table .mat-mdc-row:hover .view-action {
  display: inline-table;
}
.color-table table .mat-mdc-row .more-actions[aria-expanded=true] {
  display: inline-table;
  background-color: var(--black-color-05);
}
.color-table table tr .mat-mdc-cell {
  padding: 12px;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.mat-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.mat-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

.market-intelligence-card .mat-figure {
  align-items: stretch;
}
.market-intelligence-card .mat-figure .market-card:hover {
  border: 1px solid var(--secondary-color);
  border-radius: 10px;
}

.scss-academy-card .mat-figure .market-card:hover {
  border: 1px solid var(--secondary-color);
  border-radius: 10px;
}

/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.mat-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.mat-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

.mat-drawer.medium {
  width: 500px;
}
.mat-drawer.extra-large {
  width: 600px;
}

.drawer-header .drawer-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  color: var(--text-primary);
  font-family: "Plus Jakarta Sans", sans-serif !important;
}
.drawer-header .drawer-close-icon {
  color: var(--text-tertiary);
}

.drawer-details {
  height: calc(100vh - 164px);
  overflow: auto;
}

.clear-filter-btn {
  position: absolute !important;
  top: 20px;
  right: 64px;
  z-index: 1;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
.workspace-side-nav {
  width: 200px;
}
.workspace-side-nav .mat-expansion-panel .mat-expansion-panel-header {
  padding: 8px 12px !important;
  height: fit-content !important;
}
.workspace-side-nav .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title {
  margin-right: 0 !important;
}
.workspace-side-nav .mat-expansion-panel .mat-expansion-indicator::after {
  padding: 4px !important;
  position: relative;
  top: -4px;
}
.workspace-side-nav .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0 4px 0 0 !important;
}
.workspace-side-nav .mat-expansion-panel-header {
  height: 40px;
}
.workspace-side-nav .mat-expansion-panel-header:hover {
  background-color: transparent !important;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
/* ==============================
 Secondary colors
---------------------------------*/
/* ==============================
 Text colors
---------------------------------*/
/* ==============================
 Background colors
---------------------------------*/
/* ==============================
  Stock colors
---------------------------------*/
/* ==============================
  Error colors
---------------------------------*/
/* ==============================
  Success colors
---------------------------------*/
/* ==============================
  Warn colors
---------------------------------*/
/* ==============================
  Other colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.mat-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.mat-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}