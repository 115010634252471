@import '../tools/custom';
@import '../settings/variables';
@import '../tools/mixins';

.mat-mdc-slider {
  --mdc-slider-disabled-handle-color: #{$dark-green};
  --mdc-slider-disabled-active-track-color: #{$dark-green};
  --mdc-slider-handle-width: 15px;
  --mdc-slider-handle-height: 15px;
  --mdc-slider-disabled-inactive-track-color: #00000042;
  --mdc-slider-inactive-track-height: 6px;
  height: 40px;
  cursor: unset;

  &.mdc-slider--disabled {
    opacity: 1 !important;

    .mdc-slider__track--inactive {
      opacity: 1 !important;
    }
  }

  .mdc-slider__thumb {
    // height: 40px;
    cursor: grab;
  }

  .mdc-slider__input {
    cursor: unset;
  }

  .mat-ripple {
    display: none;
  }
}
// }

/* score card NPS slider style here */
.score-card-nps {
  .mat-mdc-slider {
    width: 96%;

    // .mdc-slider__thumb {
    //   .mdc-slider__thumb-knob {
    //     transform: rotate(45deg) !important;
    //     border-radius: $border-radius-circle !important;
    //     @include size(15px);
    //     bottom: -10px;
    //     right: -8px;
    //     background-color: $dark-green;
    //     border-color: $dark-green;
    //     box-shadow: 0 2px 4px $black-color-20;
    //   }

    //   .mdc-slider__value-indicator-container {
    //     bottom: 9px;
    //     left: 25px;
    //     .mdc-slider__value-indicator .mdc-slider__value-indicator-text {
    //       display: none;
    //     }
    //   }
    // }
  }
}

.question-options {
  .mat-mdc-slider {
    --mdc-slider-disabled-handle-color: #{$white-primary};
    --mdc-slider-inactive-track-color: #{$black-color-10};
    --mdc-slider-disabled-active-track-color: #{$black-color-10};
    --mdc-slider-active-track-color: #{$dark-green};
    --mdc-slider-disabled-inactive-track-color: #{$black-color-10};
    --mdc-slider-inactive-track-height: 8px;
    --mdc-slider-active-track-height: 8px;

    width: 50%;

    .mdc-slider__track {
      .mdc-slider__track--inactive {
        opacity: 1;
        border: 1px solid $black-color-05;
      }
    }

    .mdc-slider__thumb {
      .mdc-slider__thumb-knob {
        transform: rotate(45deg) !important;
        top: 8px !important;
        left: 10px !important;
        width: 30px;
        height: 30px;
        border-radius: 20% !important;
        background: $white-primary !important;
        border: 1px solid $dark-green !important;
        box-shadow: unset;
      }

      .mdc-slider__value-indicator-container {
        bottom: 9px;
        left: 25px;
        z-index: 1000;
        pointer-events: unset;
        .mdc-slider__value-indicator {
          transform: scale(1);
          background-color: transparent;
          pointer-events: unset;

          &::before, &::after {
            visibility: hidden;
          }

          .mdc-slider__value-indicator-text{
            color: $dark-green !important;
          }
        }
      }
    }
  }
}
