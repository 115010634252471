@import '../includes';

/* Material Checkbox style overwrite here */

.mat-mdc-checkbox {
  --mdc-checkbox-unselected-icon-color: #{$text-light};
  --mdc-checkbox-selected-focus-icon-color: #{$secondary-color};
  --mdc-checkbox-selected-hover-icon-color: #{$secondary-color};
  --mdc-checkbox-selected-icon-color: #{$secondary-color};
  --mdc-checkbox-selected-pressed-icon-color: #{$secondary-color};
  --mdc-checkbox-selected-focus-state-layer-color: #{$secondary-color};
  --mdc-checkbox-selected-hover-state-layer-color: #{black};
  --mdc-checkbox-selected-pressed-state-layer-color: #{black};
  --mdc-checkbox-disabled-selected-icon-color: #{$secondary-color};
  --mat-checkbox-disabled-label-color: #{$text-secondary};
  --mat-checkbox-label-text-color: #{$text-secondary};
  --mdc-checkbox-unselected-focus-icon-color: #{$text-light};
  --mdc-checkbox-unselected-hover-icon-color: #{$text-light};
  --mdc-checkbox-unselected-pressed-state-layer-color: #{black};

  &.mdc-checkbox--disabled {
    pointer-events: unset;

    .mdc-checkbox__ripple {
      visibility: hidden;
    }
  }

  .mdc-form-field {
    .mdc-checkbox {
      padding: 0;
      margin: auto;
      margin-right: .5rem;
      width: 1rem;
      height: 1rem;
      flex: 0 0 1rem;

      .mdc-checkbox__native-control {
        top: -0.75rem;
        left: -0.75rem;

        &:focus~.mdc-checkbox__ripple {
          opacity: 0;
        }
      }

      .mdc-checkbox__ripple,
      .mat-mdc-checkbox-ripple {
        height: 2.5rem;
        width: 2.5rem;
        top: -0.75rem;
        left: -0.75rem;
      }

      .mdc-checkbox__background {
        width: 1rem;
        height: 1rem;
        top: 0px;
        left: 0px;
        border-width: 2px;
      }

      &.select-all {

        .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
        .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
          background-color: $white-primary;
          border-color: $white-primary;
        }

        .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
          width: 100%;
        }
      }

      .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
      .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
        border-width: 2px;

        .mdc-checkbox__mixedmark {
          width: calc(100% - 2px);
        }
      }
    }

    label {
      @include font(14px, 20px, 500, $text-secondary);
      margin: 0;
      padding-left: 0;
      white-space: normal;
    }
  }

  .mat-checkbox-layout {
    margin: 0;
  }

  &.mat-secondary.mat-checkbox-checked,
  &.mat-secondary.mat-checkbox-indeterminate {
    .mat-checkbox-background {
      background-color: $secondary-color;
    }
  }
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: $secondary-color !important;
}

.question-options {
  .mat-mdc-checkbox-checked .mdc-form-field label {
    color: $primary-color;
  }
}

//Todo todoCss: Need to move utilities file after an table scss removed from utilities
/* for tags*/
.tag {
  @include font(13px, 20px, 500, $text-secondary);
  padding: 4px 8px;
  @include border-radius(6px);
  min-width: 93px;
  display: inline-block;
  text-align: center;
  letter-spacing: normal;
  white-space: nowrap;
  &.tag-void,
  &.tag-pipeline-project,
  &.tag-on-going,
  &.tag-inprogress,
  &.tag-pending,
  &.tag-survey-sent {
    background-color: $warn-secondary;
    color: $warn-primary;
  }

  &.tag-won,
  &.tag-accepted,
  &.tag-closed,
  &.tag-paid,
  &.tag-pipeline-closed,
  &.tag-executed,
  &.tag-active,
  &.tag-approved,
  &.tag-completed,
  &.tag-pitch-invited,
  &.tag-pipeline-completed,
  &.tag-survey-completed {
    background-color: $success-tertiary;
    color: $success-primary;
  }

  &.tag-boarding-on-going,
  &.tag-open,
  &.tag-start,
  &.tag-pipeline-demand,
  &.tag-started,
  &.tag-sow-invited,
  &.tag-invited,
  &.tag-not-yet-sent,
  &.tag-draft {
    background-color: $secondary-hint-3;
    color: $secondary-color;
  }

  &.tag-uncollectible,
  &.tag-change-request,
  &.tag-inactive,
  &.tag-due,
  &.tag-requested,
  &.tag-rejected,
  &.tag-pipeline-rejected,
  &.tag-update-required,
  &.tag-changes-submitted,
  &.tag-stopped,
  &.tag-off-boarding {
    background-color: $error-hint;
    color: $error-primary;
  }

  &.tag-pipeline-idea {
    color: $lavender-tag;
    background: $lavender-tag-bg;
  }
}

//Todo todocss: need to move utilities file after an table scss removed from utilities
/* for breadcrumbs*/
.breadcrumbs {
  li {
    @include font(13px, 18px, 500, $text-hint);

    &:not(:last-child)::after {
      content: '/';
      padding: 0 6px;
    }

    .route-link:hover {
      @include font(13px, 18px, 500, $blue-color-fb);
      text-decoration: underline;
    }

    &.more-actions {
      display: flex;
      align-items: center;
      padding: 4px 6px;

      &:hover,
      &[aria-expanded='true'] {
        border-radius: 3px;
        background-color: $black-color-05;
      }
    }

    &:last-child {
      @include font(13px, 18px, 600, $text-primary);
    }
  }
}
