@import '../includes';

/* Material Chip Lists style overwrite here */
.mat-mdc-chip-listbox {
  .mdc-evolution-chip-set__chips {
    .mat-mdc-chip {
      background-color: $white-lilac;
      border-radius: 6px;
      border: none;
      height: auto;
      padding: 7px 0;
      margin: 4px;
      line-height: 18px;

      .mdc-evolution-chip__cell .mdc-evolution-chip__action {
        width: 100%;
        .mdc-evolution-chip__text-label {
          width: inherit;
          @include font(13px, 18px, 600, $text-secondary);
          display: flex;
          justify-content: space-between;
          align-items: center;

          mat-icon {
            width: 18px;
            height: 18px;
            font-size: 18px;
          }
        }
      }

      .mat-mdc-chip-remove {
        @include size(18px !important);
        color: $text-hint;
        margin-left: 6px;
        opacity: 1;
        &:hover {
          opacity: 1 !important;
        }
      }

      .chip-children {
        font-weight: 600;
        display: flex;
        min-width: 24px;
        width: 24px;
        height: 24px;
        margin-left: 4px;
        color: $text-tertiary;
        background: $border-grey;
        border-radius: 30px;
        justify-content: center;
        align-items: center;
      }

      &.statusBeyond {
        color: $red;
        border-color: $red;

        .mdc-evolution-chip__cell .mdc-evolution-chip__action .mdc-evolution-chip__text-label {
          color: inherit;
        }
      }

      &.mat-mdc-chip-with-trailing-icon {
        padding-left: 12px;
        padding-right: 12px;

        .mdc-evolution-chip__cell--primary .mdc-evolution-chip__action {
          padding-left: 0;
          padding-right: 0;
        }

        .mdc-evolution-chip__cell--trailing .mat-mdc-chip-trailing-icon {
          padding-right: 0;
        }
      }

      &::after {
        background: $background-dark;
        border: none;
        transition: none;
      }

      &:hover::after {
        opacity: 1;
        z-index: -1;
      }
    }
  }
}

.mdc-evolution-chip-set__chips input.mat-input-element {
  background: none;
  border: 1px solid $primary-color-40 !important;
  color: $primary-color;
  white-space: normal;
  word-break: break-word;
  line-height: 1.3;
  outline: none !important;
  height: unset !important;
}

.table-chip-list {
  .mdc-evolution-chip-set__chips {
    flex-wrap: wrap !important;
    .mat-mdc-chip {
      white-space: nowrap !important;
      word-break: keep-all !important;

      // &.mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
      //   padding-left: 0;
      //   padding-right: 0;
      // }
    }
  }
}

// single level select styles here
.single-select-chip {
  .mdc-evolution-chip-set__chips {
    height: fit-content;
    overflow: auto;
  }
}

.mat-chip-organize {
  .mdc-evolution-chip-set__chips .mat-standard-chip:hover {
    background: none;
  }

  .mat-mdc-chip.mat-mdc-standard-chip .mat-mdc-chip-remove {
    @include size(20px);
    @include flexCenter(flex, center, center);
    border-radius: 50%;
    &:hover {
      background: $primary-color-12;
    }
  }
}

.select-filter-chip {
  align-items: center;
  .mdc-evolution-chip-set__chips {
    margin: 0 !important;
  }
}

.view-details-card {
  .mdc-evolution-chip-set__chips {
    margin: 0 !important;
  }
}
