@import '../includes';

/* Mat form field style overwrite here */
mat-form-field.mat-mdc-form-field {
  --mdc-typography-caption-font-size: 13px;
  --mdc-typography-caption-line-height: 18px;
  --mdc-typography-caption-font-weight: 500;
  --mdc-filled-text-field-disabled-input-text-color: #{$text-primary};

  font-size: 14px;
  margin-bottom: $spacer16;
  position: relative;
  font-family: 'Open Sans', sans-serif;

  &.full-width {
    width: 100%;
  }

  .mat-mdc-text-field-wrapper {
    padding: 0;
    border-radius: 6px;
    border: 1px solid $stock-tertiary;
    background-color: $white-primary;

    &.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
      width: 100%;
      min-height: unset;
      padding: 0;
      .mat-mdc-input-element {
        padding: 9px 10px;
      }
      @include font(14px, 20px, 600, $text-primary);
    }

    &.mdc-text-field .mdc-text-field__input {
      caret-color: black;
    }

    &.mat-mdc-form-field-focus-overlay {
      background-color: $white-primary !important;
    }

    &.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
    &.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
    &.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
    &.mdc-text-field--disabled .mdc-line-ripple::before,
    &.mdc-text-field--disabled .mdc-line-ripple::after,
    &.mdc-text-field--filled .mdc-line-ripple::after {
      border: none;
    }

    &.mdc-text-field--disabled {
      background-color: $background-primary !important;
      // border: 1px solid $background-primary !important;
      border-color: $background-primary !important;
      pointer-events: unset;

      .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-input-element {
        cursor: not-allowed;
      }

    }

    &.mdc-text-field--focused {
      background-color: $white-primary !important;
      // border: 1px solid $secondary-color !important;
      border-color: $secondary-color !important;
    }

    &.mdc-text-field--invalid {
      background-color: $error-hint !important;
      border: 1px solid $error-primary !important;
      .mdc-text-field__input {
        caret-color: $error-primary;
      }
    }

    .mat-mdc-form-field-focus-overlay {
      background-color: $white-primary !important;
    }

    .mat-mdc-form-field-flex {
      textarea {
        box-shadow: none;
        min-height: 100px;
        max-height: 200px;
        resize: none;
        box-sizing: border-box;
        margin: 0;
      }

      .mat-mdc-form-field-icon-prefix .mat-icon,
      .mat-mdc-form-field-icon-suffix .mat-icon {
        display: flex;
        align-items: center;
        color: $text-hint;
        font-size: 20px;
        width: 20px;
        height: 20px;
        padding: 0;
      }

      .mat-mdc-form-field-icon-suffix span {
        display: flex;
      }

      .mat-mdc-form-field-icon-prefix,
      .mat-mdc-form-field-icon-suffix {
        padding: 0;
      }

      .mat-mdc-form-field-icon-prefix .mat-icon {
        margin-left: 10px;
      }

      .mat-mdc-form-field-icon-suffix {
        .mat-datepicker-toggle {
          --mat-datepicker-toggle-active-state-icon-color:  #{$text-grey-secondary};
          .mdc-icon-button {
            --mdc-icon-button-state-layer-size: 32px;
            width: 20px;
            height: 20px;
            margin-right: 8px;

            .mat-mdc-button-touch-target {
              width: 20px;
              height: 20px;
            }
          }
        }
        .mat-icon {
          margin-right: 10px;
        }
      }

      .mat-mdc-form-field-icon-prefix + .mat-mdc-form-field-infix {
        padding-left: 0;
        .mat-mdc-form-field-label {
          padding-left: 10px;
        }
      }
    }

    .mat-mdc-form-field-flex {
      input::placeholder, textarea::placeholder {
        @include font(14px, 20px, 500, $text-light);
        letter-spacing: 0;
      }
    }
  }

  &:hover {
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-focus-overlay {
      background-color: $white-primary !important;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
      padding: 0;
    }
  }

  &.medium{
    .mat-mdc-text-field-wrapper {
      &.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
        min-height: unset !important;
        padding: 0;
        .mat-mdc-input-element {
          padding: 7px 10px;
        }
      }
    }
  }

  .mat-form-field-label {
    @include font(14px, 38px, 500, $text-light);
  }

  &.mat-focused .mat-form-field-label {
    color: $text-light;
  }

  &.no-border-input {
    margin: 0;

    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
      border: none;
      padding: 0 !important;
      @include font(14px, 20px, 400, $text-tertiary);
    }

      &.options {
        margin-bottom: 10px;
      }

      .mat-mdc-text-field-wrapper {
        border: none;
        padding: 0;
        @include font(14px, 20px, 400, $text-tertiary);
      }
  }

  &.single-border {
    .mat-mdc-input-element {
      padding: $spacer10 $spacer8 $spacer10 0;
    }

    &.input-medium {
      .mat-mdc-input-element {
        padding: $spacer8 $spacer8 $spacer8 0;
      }
    }

    &.mat-mdc-form-field .mat-mdc-form-field-label {
      padding-left: 0;
    }

    &.dynamic-input.mat-mdc-form-field .mat-mdc-form-field-label {
      padding-left: 14px;
    }

    &:focus,
    &.mat-focused {
      .mat-mdc-form-field-flex {
        border-bottom: 1px solid $primary-color!important;
        box-shadow: none !important;
      }

      &.mdc-text-field--invalid .mat-form-field-flex {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }

  &.no-border {

    .mat-mdc-text-field-wrapper {
      border: none !important;
      box-shadow: none !important;

      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-icon-prefix {
          padding-left: 0;
        }
      }
    }
  }

  &.textarea {
    .mat-mdc-form-field-label {
      padding-left: $spacer8;
    }
  }
}

//chat send message container styles
.send-message-container {
  .mat-mdc-text-field-wrapper {
    border-radius: 50px !important;
    height: 52px;
    padding: 6px 16px !important;

    .mat-mdc-form-field-flex .mat-mdc-form-field-infix textarea::placeholder {
      position: relative;
      top: 10px;
    }
  }
}

label.form-label {
  display: table;
  @include font(14px, 20px, 500, $text-tertiary);
  letter-spacing: normal;
  margin: 0 0 6px 0;
}

mat-error.mat-mdc-form-field-error{
  @include font(13px, 20px, 500, $error-primary);
  margin-top: 4px;
}
