@import "../tools/custom";
@import "../settings/variables";

mat-progress-bar.mat-mdc-progress-bar {
    --mdc-linear-progress-active-indicator-color: #{$green};
    --mdc-linear-progress-track-height: 8px;
    --mdc-linear-progress-active-indicator-height: 8px;
    border-radius: 3.5px;
    height: $spacer8;

    &.progress-no-radius {
        border-radius: 0;
    }

    .mdc-linear-progress__buffer-bar{
      background-color: $grey-lighten-2;
      border-radius: 10px;
    }

    &.mat-primary {
      --mdc-linear-progress-active-indicator-color: #{$green};
      .mdc-linear-progress__bar-inner {
        border-color: $primary-color;
        border-radius: 10px;
      }
    }
}
