@import '../includes';

/* Material Dialog Modal style overwrite here */
.mat-mdc-dialog-container {

  .mat-mdc-dialog-surface {
    --mdc-dialog-container-shape: 10px;
    --mdc-dialog-supporting-text-color: #000000de;
    padding: 24px;

    .mat-mdc-dialog-title {
      @include font(18px, 28px, 700, $text-primary);
      margin: 0;
      padding: 0;
      font-family: 'Plus Jakarta Sans', sans-serif !important;

      &.mdc-dialog__title::before {
        height: unset;
        display: none;
      }
    }

    .mat-mdc-dialog-title,
    .mat-mdc-dialog-content,
    .mat-mdc-dialog-actions {
      letter-spacing: 0;
    }

    .mat-mdc-dialog-content {
      margin: 0 -24px;
      padding: 0 24px;
    }

    .close-icon {
      color: $text-primary;
      cursor: pointer;
    }

    .dialog-info {
      @include font(14px, 22px, 500, $text-tertiary);
    }

    .mat-mdc-dialog-actions {
      min-height: unset;
      margin-bottom: 0;
      padding: 24px 0 0 0;

      .mat-mdc-button-base+.mat-mdc-button-base {
        margin-left: 12px;
      }
    }
  }
}

.support-contract-dialog .mat-mdc-dialog-container {
  .mat-dialog-content {
    max-height: calc(100vh - 100px);
  }
}

.view-action-dialog {
  .mat-mdc-dialog-content {
    overflow: auto;
  }
}

.change-admin-role {
  .mat-mdc-dialog-content {
    overflow: unset;
  }
}

.calendar-model {
  .icon-container {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: $primary-hint-4;
    display: flex;
    justify-content: center;
    align-items: center;

    .warn-icon {
      color: $primary-color !important;
      font-size: 26px;
    }
  }
}
