@import '../includes';
/* Material Button style overwrite here */

button.mdc-button,
a.mdc-button {
  color: $secondary-color;
  transition: all 0.3s;
  border-radius: 6px;
  font-weight: 600;
  letter-spacing: 0.28px;
  outline: none;
  border: none;
  line-height: 20px;
  padding: $spacer10;
  font-family: 'Open Sans', sans-serif;

  &.full-width {
    width: 100%;
  }

  &.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 40px;
    height: 40px;
  }

  &.medium {
    height: 36px;
    padding: 8px $spacer10;
  }

  &.small {
    height: 32px;
    padding: 6px $spacer10;
    font-size: .875em;
  }

  &.mat-primary {
    color: $primary-color;

    &:hover {
      background-color: $primary-hint-4;
    }
  }

  &.mat-secondary {
    color: $secondary-color;

    &:hover {
      background-color: $secondary-hint-3;
    }
  }

  &.mat-calendar-period-button {
    color: $text-primary;
  }

  &[disabled] {
    color: $stock-primary !important;
    background-color: transparent !important;
    cursor: not-allowed;
    pointer-events: unset;
  }

  &>.mat-icon {
    margin-left: 0;
  }

  .mdc-button__label {
    white-space: nowrap;
  }
}

button.mat-mdc-unelevated-button,
a.mat-mdc-unelevated-button {
  background-color: $white-primary;
  border: none !important;
  color: $white-primary;
  transition: all 0.3s;
  border-radius: 6px;
  padding: $spacer10;
  font-weight: 600;
  letter-spacing: 0.28px;
  outline: none;
  border: none;
  line-height: 20px;
  font-family: 'Open Sans', sans-serif;
  height: 40px;

  &.full-width {
    width: 100%;
  }

  &.mat-mdc-button-base {
    height: 40px;
  }

  &.medium {
    height: 36px;
    padding: 8px $spacer10;
  }

  &.small {
    height: 32px;
    padding: 6px $spacer10;
    font-size: .875em;
    font-weight: 400;
  }

  &.extra-small {
    height: 28px;
    padding: 4px $spacer10;
  }

  &.mat-primary {
    color: $white-primary;
    background: $primary-color;
    &:hover {
      background-color: $primary-light;
    }
  }

  &.mat-secondary {
    color: $white-primary;
    background-color: $secondary-color;
    &:hover {
      background-color: $secondary-light-color;
    }
  }

  &.mat-accent {
    background-color: $text-primary;
    &:hover {
      background-color: $text-tertiary;
    }
  }

  &.mat-warn {
    background-color: $error-primary;
    &:hover {
      background-color: $error-light;
    }
  }

  &[disabled] {
    background-color: $stock-primary !important;
    color: $white-primary !important;
    cursor: not-allowed;
    pointer-events: unset;
  }

  &>.mat-icon {
    margin-left: 0;
  }

  .mat-mdc-button-touch-target {
    height: 40px;
  }
}

button.mat-mdc-outlined-button,
a.mat-mdc-outlined-button {
  border: 1px solid $text-primary;
  color: $text-primary;
  transition: all 0.3s;
  border-radius: 6px;
  padding: 9px $spacer10;
  font-weight: 600;
  letter-spacing: 0.28px;
  outline: none;
  line-height: 20px;
  font-family: 'Open Sans', sans-serif;

  &.full-width {
    width: 100%;
  }

  &.mat-mdc-button-base {
    height: 40px;
  }

  &.medium {
    height: 36px;
    padding: 7px $spacer10;
  }

  &.small {
    height: 32px;
    padding: 5px $spacer10;
    font-size: .875em;
    font-weight: 400;
  }

  &.mat-primary {
    border: 1px solid $primary-color;
    color: $primary-color;

    &:hover {
      background-color: $primary-hint-4;
    }
  }

  &.mat-secondary {
    border: 1px solid $secondary-color;
    color: $secondary-color;

    &:hover {
      background-color: $secondary-hint-5;
    }
  }

  &.mat-accent {
    --mat-mdc-button-persistent-ripple-color: transparent;
    --mdc-outlined-button-label-text-color: #{$secondary-color};
    --mat-outlined-button-state-layer-color: #{$secondary-color};
    border: 1px solid $text-primary;
    color: $text-primary;

    &:hover {
      background-color: $stock-hint;
    }
  }

  &.mat-warn {
    border:1px solid $error-primary;
    color: $error-primary;

    &:hover {
      background-color: $error-hint;
    }
  }

  &[disabled] {
    background-color: $stock-primary !important;
    border-color: $stock-primary !important;
    color: $white-primary !important;
    cursor: not-allowed;
    pointer-events: unset;
  }

  &>.mat-icon {
    margin-left: 0;
  }
}

button.icon-button .mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

button.mat-mdc-icon-button{
  --mat-icon-button-hover-state-layer-opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  --mat-paginator-enabled-icon-color: #{black};
  --mat-paginator-disabled-icon-color: #{$text-light};

  &.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 40px;
    width: 40px;
    height: 40px;
    padding: 0px !important;
  }

  &.mat-suffix-button {
    --mdc-icon-button-state-layer-size: 1.5rem;
    height: 1.5rem;
    width: 1.5rem;

    .mat-mdc-button-touch-target {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    pointer-events: unset;
  }
}

button.mat-mdc-outlined-button.mat-accent .mat-button-focus-overlay {
  background-color: $text-secondary;
}

// Todo:: Added for button issue
.button-section {
  .text-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.delete-modal {
  .text-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}


.pdf-to-container,
.pdf-container{
      .mat-mdc-icon-button {
        @include border-radius(50%);
        @include size(32px);
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
          background-color: $background-primary;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .mat-button-wrapper{
          @include size(20px);
          display: flex;
        }
        .mat-icon {
          color: $text-light;
        }
      }
}

.action-icon {
  &.mat-mdc-icon-button {
    display: flex;
    align-items: center;

    .mat-button-wrapper{
      display: block;
      margin: auto;
    }
  }
}
