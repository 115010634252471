@import '../tools/custom';

/* Material Loader style overwrite here */
.mat-mdc-progress-spinner.mat-primary {
  --mdc-circular-progress-active-indicator-color: #{$primary-color};
}

.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #{$green};
}

.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #{$warn-color};
}

// .mat-mdc-progress-spinner.mat-primary circle,
// .mat-spinner.mat-primary circle {
//   stroke: $primary-color;
// }

// .mat-progress-spinner.mat-accent circle,
// .mat-spinner.mat-accent circle {
//   stroke: $green;
// }

// .mat-progress-spinner.mat-warn circle,
// .mat-spinner.mat-warn circle {
//   stroke: $warn-color;
// }
