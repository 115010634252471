@import "../tools/custom";

mat-datepicker-content {
  --mat-datepicker-calendar-date-hover-state-background-color: #{#0000000a};
  --mat-datepicker-calendar-date-selected-state-background-color: #{$text-grey-hint};
  --mat-datepicker-toggle-active-state-icon-color: #{$text-grey-hint};
}

/* Material Date Picker style overwrite here */
.mat-form-field-suffix .mat-datepicker-toggle .mat-button-focus-overlay, .mat-form-field-suffix .mat-datepicker-toggle .mat-button-ripple {
  margin-left: 0;
}

.mat-datepicker-toggle-active {
  color: $text-grey-secondary;
}

.mat-calendar-body-selected {
  background-color: $text-grey-hint;
  color: $white-primary;
}
