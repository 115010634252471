@import '../includes';

/* Material Slide Toggle style overwrite here */
.mat-mdc-slide-toggle {
  --mdc-switch-unselected-track-color: #{$bg-light-white};
  --mdc-switch-unselected-hover-track-color: #{$bg-light-white};
  --mdc-switch-track-height: 14px;
  --mdc-switch-state-layer-size: 40px;
  --mdc-switch-handle-surface-color: #{$secondary-color};
  --mdc-switch-unselected-handle-color: #{$text-light};
  --mdc-switch-unselected-hover-handle-color: #{$text-light};
  --mdc-switch-unselected-focus-track-color: transparent;
  --mdc-switch-unselected-focus-handle-color: #{$text-light};
  --mdc-switch-selected-handle-color: #{$secondary-color};
  --mdc-switch-selected-hover-handle-color: #{$secondary-color};
  --mdc-switch-selected-focus-handle-color: #{$secondary-color};
  --mdc-switch-selected-track-color: #{$secondary-hint-3};
  --mdc-switch-selected-hover-track-color: #{$secondary-hint-3};
  --mdc-switch-selected-focus-track-color: #{$secondary-hint-3};
  --mdc-switch-unselected-pressed-track-color: #{$secondary-hint-3};
  --mdc-switch-selected-pressed-track-color: #{$secondary-hint-3};
  --mdc-switch-unselected-pressed-handle-color: #{$text-light};
  --mdc-switch-selected-pressed-handle-color: #{$secondary-color};
  --mdc-switch-disabled-unselected-track-color: #{$bg-light-white};
  --mdc-switch-disabled-selected-handle-color: #{$bg-light-white};
  --mdc-switch-disabled-unselected-handle-color: #{$bg-light-white};
  --mdc-switch-disabled-selected-track-color: #{$bg-light-white};
  --mdc-switch-unselected-hover-state-layer-color: transparent;

  .mdc-form-field {
    .mdc-switch {
      --mat-switch-with-icon-handle-size: 12px;
      --mat-switch-unselected-handle-size: 12px;
      --mat-switch-selected-handle-size: 12px;
      --mat-switch-pressed-handle-size: 12px;
      border-radius: 50px;
      border: 1px solid $stock-hint;
      background: $bg-light-white;
      width: 36px;
      height: 20px;
      margin-right: 4px;

      .mdc-switch__ripple {
        visibility: hidden;
      }

      &.mdc-switch--selected {
        border: 1px solid $secondary-hint-2;
        background: $secondary-hint-3;
        .mdc-switch__handle-track {
          transform: translateX(125%);
        }
      }

      &.mdc-switch--unselected .mdc-switch__handle-track {
        transform: translateX(25%);
      }

      &.mdc-switch--disabled {
        opacity: 0.38;
      }

      .mdc-switch__icons {
        display: none;
      }
    }

    label {
      margin: 0;
      @include font(14px, 20px, 500, $text-secondary);
    }
  }
}
