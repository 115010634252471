@import '../includes';

/* Mat form field Select box style overwrite here */
.mat-mdc-select-panel {
  border-radius: 6px !important;
  border: 1px solid $stock-hint;
  background: $white-primary;
  box-shadow: 10px 0px 30px 0px $black-color-05, 1px 1px 10px 0px $black-color-12 !important;
  padding: 8px !important;
  // position: absolute;
  left: 8px;

  .mat-mdc-option {
    --mat-option-hover-state-layer-color: #{$bg-light-white};
    color: $text-secondary;
    font-size: 14px !important;
    padding: 8px;
    height: 36px !important;
    line-height: 36px !important;
    min-height: 36px !important;
    border-radius: 4px;

    &:hover {
      background: $bg-light-white;
    }

    .mat-pseudo-checkbox {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    .mdc-list-item__primary-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &.mdc-list-item--selected {
      background: $background-primary !important;

      .mat-option-text, .mdc-list-item__primary-text {
        font-weight: 600;
        color: $secondary-color !important;
      }
    }

    &:not(.mat-mdc-option-multiple) {
      mat-pseudo-checkbox {
        display: none;
      }
    }
  }
  &.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mat-mdc-menu-item-text,
  &.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: $secondary-color !important;
  }
}

mat-form-field.mat-mdc-form-field-type-mat-select {
  position: relative;

  .mat-mdc-text-field-wrapper {
    border: none;
    overflow: unset;

    .mat-mdc-form-field-infix {
      min-height: unset;
    }

    &.mdc-text-field--invalid {
      background: transparent !important;
    }
  }

  .mat-mdc-select {
    background: $white-primary;
    border: 1px solid $stock-tertiary;
    line-height: 20px;
    border-radius: 6px;
    padding: 9px 10px;
    width: 100%;
    font-size: 14px;

    .mat-mdc-select-trigger {
      .mat-mdc-select-arrow-wrapper {
        height: 20px;
        .mat-mdc-select-arrow {
          border: none;
          margin: 0;
          @include size(20px);

          &::after {
            font-family: 'Material Icons';
            content: '\e5cf';
            font-size: 24px;
            color: $text-hint;
            position: relative;
            top: 0;
            right: 0;
            background: none;
          }

          svg {
            display: none;
          }
        }
      }
    }

    &.mat-mdc-select-invalid {
      background-color: $error-hint !important;
      .mat-mdc-select-trigger .mat-mdc-select-value .mat-mdc-select-placeholder {
        color: $error-primary !important;
      }
    }

    // .mat-select-arrow {
    //   border: none;
    //   margin: 0;
    //   @include size(20px);

    //   &::after {
    //     font-family: 'Material Icons';
    //     content: '\e5cf';
    //     font-size: 24px;
    //     color: $text-hint;
    //     position: relative;
    //     top: 0;
    //     right: 0;
    //     background: none;
    //   }
    // }

    &.mat-select-invalid {
      .mat-select-arrow::after {
        color: $error-primary;
      }
    }

    &.action-status {
      box-shadow: 0 1px 2px 0 $black-color-24, 0 0 2px 0 $black-color-12;
    }

    &:not(.mat-mdc-select-invalid):hover {
      box-shadow: 0 1px 2px 0 $black-color-24, 0 0 2px 0 $black-color-12;
    }
  }

  :not(.mat-mdc-select-invalid) .mdc-text-field--focused {
    .mat-mdc-select {
      background-color: $white-primary !important;
      border-color: $secondary-color !important;
    }
  }

  &.medium {
    .mat-select {
      padding: 7px 10px;
    }
  }

  &.mat-form-field-invalid {
    .mat-form-field-subscript-wrapper {
      position: relative;
    }

    .mat-form-field-label {
      color: $red !important;
    }
  }

  // ERROR state when focused
  &:focus,
  &.mat-focused {
    .mat-select {
      border: 1px solid $secondary-color;

      &.mat-select-invalid {
        background: $white-primary;
        // border: 2px solid $red;

        // .mat-select-arrow {
        //   &:after {
        //     content: '';
        //     position: absolute;
        //     height: 14px;
        //     width: 14px;
        //     background-size: contain;
        //     top: 16px;
        //     right: -4px;
        //   }
        // }
      }
    }
  }

  .mat-select-arrow {
    color: $text-primary;
  }

  .mat-form-field-label {
    color: $text-grey-content !important;
  }

  &.mat-focused.mat-primary {

    .mat-select-arrow,
    .mat-form-field-label {
      color: $text-primary;
    }
  }

  // If mat form field is disabled
  &.mat-form-field-disabled {
    .mat-mdc-select {
      border-color: $background-primary;
      background-color: $background-primary;

      .mat-mdc-select-trigger {
        .mat-mdc-select-value {
          cursor: not-allowed;
        }
        .mat-mdc-select-arrow-wrapper {
          .mat-mdc-select-arrow {
            &::after {
              content: '';
            }
          }
        }
      }
      }
    &:hover {
      cursor: not-allowed;
    }
  }

  &.single-border {
    .mat-select {
      border: none;
      border-bottom: 1px solid $grey-lighten-2;
      border-radius: 0;

      &.mat-select-invalid {
        background: $red-lighten2;
        border: none;
        border-bottom: 1px solid $red;
      }
    }

    // ERROR state when focused
    &:focus,
    &.mat-focused {
      .mat-select {
        &.mat-select-invalid {
          background: $white-primary;
          border: none;
          border-bottom: 2px solid $red;
        }
      }
    }
  }

  &.transparent-form {
    .mat-select {
      border: none;
      border-radius: 0;

      &.mat-select-invalid {
        background: $red-lighten2;
        border: none;
      }
    }

    // ERROR state when focused
    &:focus,
    &.mat-focused {
      .mat-select {
        &.mat-select-invalid {
          background: $white-primary;
          border: none;
        }
      }
    }
  }

  // ERROR message and placement
  .mat-form-field-subscript-wrapper {
    margin-top: 0;

    .mat-error {
      color: $error-primary !important;
      font-size: 13px;
      line-height: 18px;
      font-weight: 500;
      margin-top: 6px;
      overflow: visible;

      &::after {
        // The triangle!
        // bottom: -8px;
        // border-left: 8px solid transparent;
        // border-right: 8px solid transparent;
        // border-top: 8px solid $red;
        // content: ' ';
        // height: 0;
        // position: absolute;
        // right: 12px;
        // width: 0;
      }

      &.error-left {
        top: 3px;
        left: calc(-100% - 12px);

        &::after {
          bottom: unset;
          right: -12px;
          transform: rotateZ(-90deg);
          top: 12px;
        }
      }
    }

    .mat-form-field-hint-wrapper {
      bottom: 0;
      padding-right: 2px;
    }
  }

  // Show error(s) on hover and focus
  &:hover,
  &:focus,
  &.mat-focused {

    // Only show the first error
    .mat-form-field-subscript-wrapper .mat-error:nth-of-type(1) {
      display: block;
    }
  }

  // Change error background and border on focus
  &:focus,
  &.mat-focused {
    .mat-form-field-flex {
      border-color: $primary-color;
    }
  }

  // ERROR state styling when not hovered or focused
  &.mat-form-field-invalid .mat-form-field-flex {
    background-color: $red-lighten2;
    border-color: $red;
    border-width: 1px;
    border-style: solid;
    align-items: center;
    border-radius: 6px;

    &:hover {
      box-shadow: 0 1px 2px 0 $black-color-24, 0 0 2px 0 $black-color-12;
    }

    .mat-select {
      background-color: $error-hint !important;
      border: none;

      &:hover {
        box-shadow: none;
      }
    }

    // &::after {
    //   color: $error-primary !important;
    //   content: 'info' !important;
    //   font-family: 'Material Icons';
    //   font-feature-settings: 'liga';
    //   font-size: 150%;
    //   padding-right: 8px;
    // }
  }
}

// single level select
.single-level-select-container {
  .mat-mdc-form-field {
    .mat-mdc-form-field-flex {
      padding-top: 6px !important;
      padding-bottom: 4px !important;
    }

    &.mat-mdc-form-field-type-mat-select {
      .mat-mdc-form-field-outline {
        display: none;
      }

      .mat-mdc-select-arrow {
        &:after {
          top: 24px !important;
        }
      }
    }
  }
}

// Sourcing selection dropdown
.selection-dropdown {
  position: absolute;
  top: 44px;
  right: -54px;
}

.selection-dropdown-single {
  position: absolute;
  top: 44px;
  right: -30px;
}

.proposal-select {
  position: absolute !important;
  top: 42px !important;
  width: 200px !important;
}

// impact tracking styles here
.impact-tracking-input {
  mat-form-field {
    max-width: 120px;
    width: 100%;

    &.mat-mdc-form-field-type-mat-select {
      &.mat-mdc-form-field-disabled {
        .mat-mdc-select {
          background-color: $white-primary;
        }
      }

      .mat-select {
        height: 42px;
      }
    }
  }

  .mat-mdc-select-value-text {
    color: var(--black-color);
    font-weight: 600;
  }
}

// Template Font Editor
// Todo Needs To Change After The New Component
.template-font-family {
  .mat-mdc-form-field {
    border-radius: 6px;
    background: $grey-lighten-5;

    .mat-mdc-form-field-flex {
      min-width: 104px;
    }

    &.mat-mdc-form-field-type-mat-select {
      .mat-mdc-select {
        border-radius: 6px;
      }
    }
  }
}

.template-font-size {
  .mat-mdc-form-field {
    border-radius: 6px;
    background: $grey-lighten-5;

    .mat-mdc-form-field-flex {
      min-width: 74px;
    }

    &.mat-mdc-form-field-type-mat-select {
      .mat-mdc-select {
        border-radius: 6px;
      }
    }
  }
}

.template-font-style {
  .mat-mdc-form-field {
    border-radius: 6px;
    background: $grey-lighten-5;

    .mat-mdc-form-field-flex {
      min-width: 74px;
    }

    &.mat-mdc-form-field-type-mat-select {
      .mat-select {
        border-radius: 6px;
      }
    }
  }
}

.demand-select {
  position: absolute;
  top: 42px;
}

.provider-le-status {

  .tag-completed {
    background-color: $success-tertiary !important;

    .mat-mdc-select-value-text {
      color: $success-primary !important;
    }
  }

  .tag-start {
    background-color: $secondary-hint-3 !important;

    .mat-mdc-select-value-text {
      color: $secondary-color !important;
    }
  }

  .tsg-off-boarding {
    background-color: $error-hint !important;

    .mat-mdc-select-value-text {
      color: $error-primary !important;
    }
  }

}

.demand-input {
  mat-form-field.mat-mdc-form-field-type-mat-select {
    .mat-mdc-form-field-wrapper, .mat-mdc-select {
      background-color: $white-primary !important;
    }
  }
}

.disabled-input {
  mat-form-field.mat-mdc-form-field-type-mat-select {
    .mat-mdc-form-field-wrapper, .mat-mdc-select {
      background-color: $white-primary !important;
      color: $text-primary !important;
    }
  }
}
