@import '../includes';

/** Mat pagination styles here */
.mat-mdc-paginator {
  --mat-paginator-container-background-color: transparent;

  &-container {
    justify-content: flex-start !important;
    padding: 0 !important;
    min-height: unset !important;
  }

  .mat-mdc-paginator-page-size {
    margin-right: 0;

    &-label {
      @include font(13px, 20px, 500, $text-tertiary);
      margin: 0 6px;
    }
    &-select {
      margin: 0;
    }
  }

  .mat-mdc-paginator-page-size-select {
    width: 56px;
  }

  .mat-mdc-paginator-range-label {
    @include font(13px, 20px, 500, $text-tertiary);
    margin: 0 12px;
  }

  button:hover {
    background: $background-primary;
  }

  .mat-mdc-paginator-range-actions {
    .mat-mdc-icon-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      line-height: 36px;
      color: $text-primary;
      padding: 0;

      .mat-mdc-paginator-icon {
        width: 20px;
      }

      .mat-mdc-button-touch-target {
        width: 36px;
        height: 36px;
      }

      &:disabled {
        color: $text-light;
        background: none;
      }
    }
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding: 0;
  }

  .mdc-text-field--outlined .mdc-notched-outline {
    display: none;
  }

  mat-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-select {
    padding: 6px;
  }
}
