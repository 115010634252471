@import '../includes';

/* Material Radio Button style overwrite here */
.mat-mdc-radio-button{
  --mdc-radio-state-layer-size: 20px;
  --mdc-radio-selected-icon-color: #{$secondary-color};
  --mdc-radio-selected-hover-icon-color: #{$secondary-color};
  --mdc-radio-selected-focus-icon-color: #{$secondary-color};
  --mdc-radio-selected-pressed-icon-color: #{$secondary-color};
  --mdc-radio-unselected-hover-icon-color: #{$text-light};
  --mdc-radio-unselected-icon-color: #{$text-light};

  .mdc-form-field {
    label {
      margin: 0;
      padding-left: 8px;
      cursor: pointer;
    }

    .mat-radio-label-content {
      @include font(14px, 20px, 500, $text-secondary);
    }

    & + .mat-mdc-radio-button {
      margin-left: 0;
    }

    .mat-ripple-element {
      background-color: $secondary-color !important;
    }

    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border-color: $secondary-color;
      }
      .mat-radio-inner-circle {
        background-color: $secondary-color;
      }
    }
  }
}
